import {
  Box, Button, Container, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Select, MenuItem,
  Autocomplete, TextField
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import "./announcement.scss";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { patchRequestWithToken, postRequest, getRequest } from "../../utils/ApiRequests";
import { CreateJWPValidationSchema } from '../../utils/Validations';
import { useContext, useRef, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import FocusError from '../../components/FocusError/FocusError';
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";

const CreateJWP = () => {

  const { handleSnackBar } = useContext(GlobalContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [editJobSahayak, setEditJobSahayak] = useState(false);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [userId, setUserId] = useState("");
  const location = useLocation();

  const [initialValues, setInitialValues] = useState(
    {
      first_name: "",
      middle_name: "",
      last_name: "",
      date_of_birth: null,
      email: "",
      registered_mobile_number: "",
      communication_perference: [],
      add_qr_code: "",
      upload_store_front_image: "",
      upload_store_front_image_url: "",
      business_category: " ",
      business_name: "",
      business_address: "",
      business_postal_code: "",
      business_city: "",
      business_state: "",
      gst_details: "",
      business_phone_number: "",
      house_number: "",
      street: "",
      village: "",
      landmark: "",
      city: "",
      pincode: "",
      police_station: "",
      tehsil: "",
      district: "",
      state: "",
      id_verification: "",
      government_id: " ",
      government_id_data: [],
      government_id_detail: "",
      bank_details_option: "",
      account_number: "",
      confirm_account_number: "",
      ifsc_code: "",
      account_holder_name: "",
      upi_id: "",
    }
  )

  const fetchPincodeDetails = async (pincode, setFieldValue, fieldName) => {
    try {
      let response = await getRequest(`pincode/${pincode}`);
      const data = response.data.data[0]; // Access the first element of the array
      if (fieldName === "business_postal_code") {
        setFieldValue("business_city", data.city);
        setFieldValue("business_state", data.state);
      } else {
        setFieldValue('village', data.village);
        setFieldValue('panchayat', data.panchayat);
        setFieldValue('district', data.district);
        setFieldValue('state', data.state);
        setFieldValue('city', data.city);
      }

    } catch (err) {
      console.log(err)
    }

  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Job Sahayak";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Job Sahayak"))
  }

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
    if (location?.state) {
      const {
        firstName = '',
        middleName = '',
        lastName = '',
        dob = "",
        email = "",
        phone = "",
        businessCategory = "",
        businessCity = "",
        businessName = "",
        businessAddress = "",
        businessPostal = "",
        businessState = "",
        gstDetails = "",
        contactDetails = {},
        preferredCommunication = [],
        idVerification = {},
        bankDetails = {},
        storeFrontImage = "",
      } = location.state;
      setEditJobSahayak(true);
      setInitialValues({
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        date_of_birth: new Date(dob),
        email: email,
        registered_mobile_number: phone,
        communication_perference: preferredCommunication.map(item => ({ label: item, id: item.toLowerCase() })),
        add_qr_code: "",
        upload_store_front_image: "",
        upload_store_front_image_url: storeFrontImage,
        business_category: businessCategory,
        business_name: businessName,
        business_address: businessAddress,
        business_postal_code: businessPostal,
        business_city: businessCity,
        business_state: businessState,
        business_phone_number: phone,
        gst_details: gstDetails,
        house_number: contactDetails?.address,
        street: contactDetails?.street,
        village: contactDetails?.village,
        landmark: contactDetails?.landmark,
        city: contactDetails?.city,
        pincode: contactDetails?.postalCode,
        police_station: contactDetails?.policeStation,
        tehsil: contactDetails?.tehsil,
        district: contactDetails?.district,
        state: contactDetails?.state,
        id_verification: idVerification?.businessType,
        government_id: idVerification?.idType ? idVerification?.idType : " ",
        government_id_data: parseInt(idVerification?.businessType) === 1 ?
          individualGovernmentId : companyGovernmentId,
        government_id_detail: idVerification?.idNumber,
        bank_details_option: bankDetails?.accountNumber ? "bank_details" : "upi_details",
        account_number: bankDetails?.accountNumber,
        confirm_account_number: bankDetails?.accountNumber,
        ifsc_code: bankDetails?.ifsc,
        account_holder_name: bankDetails?.accountHolderName,
        upi_id: bankDetails?.upiId
      })
      setUserId(location?.state?._id);
    } else {
      setEditJobSahayak(false);
      setInitialValues(
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          date_of_birth: null,
          email: "",
          registered_mobile_number: "",
          communication_perference: [],
          add_qr_code: "",
          upload_store_front_image: "",
          upload_store_front_image_url: "",
          business_category: " ",
          business_name: "",
          business_address: "",
          business_postal_code: "",
          business_city: "",
          business_state: "",
          business_phone_number: "",
          gst_details: "",
          house_number: "",
          street: "",
          village: "",
          landmark: "",
          city: "",
          pincode: "",
          police_station: "",
          tehsil: "",
          district: "",
          state: "",
          id_verification: "",
          government_id: " ",
          government_id_data: [],
          government_id_detail: "",
          bank_details_option: "",
          account_number: "",
          confirm_account_number: "",
          ifsc_code: "",
          account_holder_name: "",
          upi_id: "",
        })
    }
  }, [location.state, isIncludesValue])

  // scroll after success
  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true);
    let formData = new FormData();
    formData = {
      usertype: "jobwahanpartner",
      profileActive: 1,
      isOtpVerified: false,
      otpGeneratedAt: "",
      otp: "",
      phone: values.registered_mobile_number,
      lastName: values.last_name,
      middleName: values.middle_name,
      firstName: values.first_name,
      jobSahayak: "",
      email: values.email,
      dob: values.date_of_birth,
      storeFrontImage: values?.upload_store_front_image_url,
      avatar: "",
      contactDetails: {
        location: {
          coordinates: [
            0,
            0
          ],
          "type": ""
        },
        address: values.house_number,
        city: values.city,
        state: values.state,
        postalCode: values.pincode,
        street: values.street,
        policeStation: values.police_station,
        tehsil: values.tehsil,
        village: values.village,
        landmark: values.landmark,
        district: values.district
      },
      bankDetails: {
        accountNumber: values.bank_details_option === "bank_details" ? (values?.account_number) : "",
        ifsc: values.bank_details_option === "bank_details" ? values.ifsc_code : "",
        accountHolderName: values.bank_details_option === "bank_details" ? values.account_holder_name : "",
        upiId: values.bank_details_option === "upi_details" ? values.upi_id : ""
      },
      idVerification: {
        businessType: values.id_verification,
        idType: values.government_id,
        idNumber: values.government_id_detail,
        dob: "",
        isIdVerified: false
      },
      businessDetails: {
        businessCategory: values.business_category,
        businessType: values.id_verification,
        businessName: values.business_name,
        businessAddress: values.business_address,
        businessCity: values.business_city,
        businessState: values.business_state,
        businessPostal: values.business_postal_code,
        businessPhone: values.business_phone_number,
        gstDetails: values.gst_details
      },
      isOnBoarded: false,
      preferredCommunication: values.communication_perference.map(item => item.label),
      qrCodes: [
        ""
      ]
    }
    try {
      let res = editJobSahayak ? await patchRequestWithToken("/admin", { userid: userId, ...formData }) : await postRequest('admin', formData);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!editJobSahayak) {
          resetForm();
          console.log("scrollTargetRef.current : " + scrollTargetRef.current)
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/jwp');
          }, 1000);
        }
      }
      else {
        handleSnackBar(true, res.data.msg, "error");
        setFormSubmitted(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg.toUpperCase(), "error");
      setFormSubmitted(false);
    }
  }

  const individualGovernmentId = [{
    id: 1,
    name: "Aadhar Card",
    value: "aadhar_card"
  }, {
    id: 2,
    name: "PAN Card",
    value: "PAN-DETAILED"
  },
  {
    id: 3,
    name: "Driving License",
    value: "driving_license"
  },
  {
    id: 4,
    name: "Voter ID",
    value: "voter_id"
  }]

  const companyGovernmentId = [{
    id: 5,
    name: "Udyam",
    value: "udyam"
  }, {
    id: 6,
    name: "GSTIN",
    value: "gstin"
  },
  {
    id: 7,
    name: "CIN",
    value: "cin"
  },
  {
    id: 8,
    name: "Pan Card",
    value: "pan_card"
  }]

  const communicationPerferences = [
    {
      label: 'Whatsapp',
      id: 'whatsapp'
    },
    {
      label: 'Telegram',
      id: 'telegram'
    },
    {
      label: 'Email',
      id: 'email'
    },
    {
      label: 'SMS',
      id: 'sms'
    }
  ]

  const BusinessCategory = [
    { id: 1, name: "Retail Establishments", sub_text: "(like stores,shops etc. any place which sells products to consumers)" },
    { id: 2, name: "Service Establishments", sub_text: "(like salons, repair shops, etc.)" },
    { id: 3, name: "Entertainment Establishments", sub_text: "(like movie multiplexes ,amusement parks, gaming arenas etc.)" },
    { id: 4, name: "Non Govt. Offices & institutions", sub_text: "" },
    { id: 5, name: "Govt. Establishments", sub_text: "(like panchayat offices,tehsil, CScs etc.)" }
  ]
  const getGovernmentIdName = (data, id) => {
    return data.find(item => item.id === id)?.name
  }

  const uploadImage = async (setFieldValue, file, values) => {
    if (!file.type.startsWith('image/')) {
      handleSnackBar(true, "Wrong File Format", "error");
      setFieldValue("upload_store_front_image", "");
      setFieldValue("upload_store_front_image_url", "");
      return;
    }
    let data = new FormData();
    data.append('location', 'jwp');
    data.append('mobile', values.registered_mobile_number);
    data.append('image', file);

    try {
      let response = await postRequest("upload", data);
      response = response.data;
      if (response.status === "Success") {
        setFieldValue("upload_store_front_image_url", response.data);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg.toUpperCase(), "error");
    }
  }

  return (
    (isIncludesValue) ? <Box className="create-jwp-section" >

      <Container>
        <Box className="create-jwp">
          <Box className="headingPlusBackBtn">
            <BackBtn />
            <h1 className='formHeadings'>{editJobSahayak ? "Update JWP" : "Create JWP"}</h1>
          </Box>
          <Box className="create-jwp-form-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={CreateJWPValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ errors, values, setFieldValue, resetForm }) => (

                <Form className="create-jwp-form">

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Personal Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="first_name"> First Name <span className='must-fill-fields'>*</span></label>
                          <Field id="first_name"
                            type="text" placeholder="Enter First Name"
                            name="first_name"
                            className="form-input"
                            value={values.first_name}
                          />
                          {errors.first_name && <Box className="form-error">{errors.first_name}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="middle_name"> Middle Name</label>
                          <Field id="middle_name"
                            type="text" placeholder="Enter Middle Name"
                            name="middle_name"
                            className="form-input"
                            value={values.middle_name}
                          />
                          {errors.middle_name && <Box className="form-error">{errors.middle_name}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="last_name">Last Name <span className='must-fill-fields'>*</span></label>
                          <Field id="last_name"
                            type="text" placeholder="Enter Last Name"
                            name="last_name"
                            className="form-input" />
                          {errors.last_name && <Box className="form-error">{errors.last_name}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="date_of_birth">Date of Birth <span className='must-fill-fields'>*</span></label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker className="datePickerGetInput"
                              value={values.date_of_birth}
                              onChange={(newValue) => {
                                setFieldValue("date_of_birth", newValue);
                              }}
                              slotProps={{ textField: { variant: "outlined", error: false, size: "medium" } }}
                            />
                          </LocalizationProvider>
                          {errors.date_of_birth && <Box className="form-error">{errors.date_of_birth}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="email">Email<span className='must-fill-fields'>*</span></label>
                          <Field id="email"
                            type="email" placeholder="Enter email"
                            name="email"
                            className="form-input" />
                          {errors.email && <Box className="form-error">{errors.email}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="registered_mobile_number">Registered Mobile Number<span className='must-fill-fields'>*</span></label>
                          <Field id="registered_mobile_number"
                            type="text" placeholder="Enter registered mobile number"
                            name="registered_mobile_number"
                            className="form-input"
                            maxLength={10}
                            readOnly={editJobSahayak} />
                          {errors.registered_mobile_number && <Box className="form-error">{errors.registered_mobile_number}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="communication_perference">Communication Perference<span className='must-fill-fields'>*</span></label>

                          <FormControl name="communication_perference" fullWidth size="small">

                            <Autocomplete

                              multiple
                              id="tags-outlined"
                              options={communicationPerferences || []}
                              getOptionLabel={(option) => option.label}
                              filterSelectedOptions
                              value={values.communication_perference}
                              onChange={(event, newValue) => {
                                setFieldValue('communication_perference', newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select communication perference"
                                />
                              )}
                            />
                          </FormControl>

                          {errors.communication_perference && <Box className="form-error">{errors.communication_perference}</Box>}
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="add_qr_code">Add QR code</label>
                            <Field id="add_qr_code"
                              type="file" 
                              name="add_qr_code"
                              className="form-input" />
                            {errors.add_qr_code && <Box className="form-error">{errors.add_qr_code}</Box>}
                          </Box>
                        </Grid> */}

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="upload_store_front_image">Upload Store Front Image</label>
                          <Field id="upload_store_front_image"
                            type="file"
                            name="upload_store_front_image"
                            className="form-input"
                            accept=".png, .jpg, .jpeg"
                            onChange={(event) => {
                              const file = event.currentTarget.files;
                              setFieldValue("upload_store_front_image", file);
                              uploadImage(setFieldValue, file[0], values);
                            }}
                            value={values.upload_store_front_image ? values.upload_store_front_image.name : ""}

                          />
                          {errors.upload_store_front_image && <Box className="form-error">{errors.upload_store_front_image}</Box>}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Business Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="business_category">Business Category <span className='must-fill-fields'>*</span></label>
                          <FormControl name="government_id" fullWidth size="small">
                            <Select
                              name="business_category"
                              id="business_category"
                              value={values.business_category}
                              onChange={(event) => {
                                setFieldValue("business_category", event.target.value)
                              }}
                              renderValue={(selected) => {
                                const selectedItem = BusinessCategory.find((item) => item.id === selected);
                                return selectedItem ? selectedItem.name : 'Select business category';
                              }}
                            >
                              <MenuItem value=" ">Select business category</MenuItem>
                              {BusinessCategory.map((item) => {
                                return <MenuItem style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start"
                                }} key={item?.id} value={item?.id}
                                  data-subtext={item.sub_text}
                                >
                                  <Grid xs={12} sm={6} md={2}>
                                    <div>{item?.name}</div>
                                    <div style={{ fontSize: "12px" }}>{item?.sub_text}</div>
                                  </Grid>
                                </MenuItem>
                              })}
                            </Select>
                          </FormControl>
                          {errors.business_category && <Box className="form-error">{errors.business_category}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="business_name">Business Name </label>
                          <Field id="business_name"
                            type="text" placeholder="Enter Business Name"
                            name="business_name"
                            value={values.business_name}
                            className="form-input" />
                          {errors.business_name && <Box className="form-error">{errors.business_name}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="business_address">Business Address <span className='must-fill-fields'>*</span></label>
                          <Field id="business_address"
                            type="text" placeholder="Enter Business Address"
                            name="business_address"
                            value={values.business_address}
                            className="form-input" />
                          {errors.business_address && <Box className="form-error">{errors.business_address}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="business_postal_code">Business Postal Code <span className='must-fill-fields'>*</span></label>
                          <Field id="business_postal_code"
                            type="text" placeholder="Enter Business Postal Code"
                            name="business_postal_code"
                            value={values.business_postal_code}
                            className="form-input"
                            onBlur={(e) => fetchPincodeDetails(e.target.value, setFieldValue, "business_postal_code")}

                          />
                          {errors.business_postal_code && <Box className="form-error">{errors.business_postal_code}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="business_city">Business City </label>
                          <Field id="business_city"
                            type="text" placeholder="Enter Business City"
                            name="business_city"
                            value={values.business_city}
                            className="form-input" />
                          {errors.business_city && <Box className="form-error">{errors.business_city}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="business_state">Business State</label>
                          <Field id="business_state"
                            type="text" placeholder="Enter Business State"
                            name="business_state"
                            value={values.business_state}
                            className="form-input" />
                          {errors.business_state && <Box className="form-error">{errors.business_state}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="gst_details">GST Details</label>
                          <Field id="gst_details"
                            type="text" placeholder="Enter GST Details"
                            name="gst_details"
                            value={values.gst_details}
                            className="form-input" />
                          {errors.gst_details && <Box className="form-error">{errors.gst_details}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="business_phone_number">Business Phone Number<span className='must-fill-fields'>*</span></label>
                          <Field id="business_phone_number"
                            type="text" placeholder="Enter Business Phone Number"
                            name="business_phone_number"
                            value={values.business_phone_number}
                            className="form-input"
                            maxLength="10" />
                          {errors.business_phone_number && <Box className="form-error">{errors.business_phone_number}</Box>}
                        </Box>
                      </Grid>

                    </Grid>
                  </Box>

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Contact Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="house_number"> House Number <span className='must-fill-fields'>*</span></label>
                          <Field id="house_number"
                            type="text" placeholder="Enter house number"
                            name="house_number"
                            value={values.house_number}
                            className="form-input" />
                          {errors.house_number && <Box className="form-error">{errors.house_number}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="street">Street/Sector/Mohalla</label>
                          <Field id="street"
                            type="text" placeholder="Enter street/sector/mohalla"
                            name="street"
                            value={values.street}
                            className="form-input"
                          />
                          {errors.street && <Box className="form-error">{errors.street}</Box>}
                        </Box>
                      </Grid>


                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="pincode">Pincode<span className='must-fill-fields'>*</span></label>
                          <Field id="pincode"
                            type="text" placeholder="Enter pincode"
                            name="pincode"
                            value={values.pincode}
                            onBlur={(e) => fetchPincodeDetails(e.target.value, setFieldValue, "pincode")}
                            className="form-input" />
                          {errors.pincode && <Box className="form-error">{errors.pincode}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="state">State<span className='must-fill-fields'>*</span></label>
                          <Field id="state"
                            type="text" placeholder="Enter state"
                            name="state"
                            value={values.state}
                            className="form-input" />
                          {errors.state && <Box className="form-error">{errors.state}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="city">City</label>
                          <Field id="city"
                            type="text" placeholder="Enter city"
                            name="city"
                            value={values.city}
                            className="form-input" />
                          {errors.city && <Box className="form-error">{errors.city}</Box>}
                        </Box>
                      </Grid>


                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="district">District</label>
                          <Field id="district"
                            type="text" placeholder="Enter district"
                            name="district"
                            value={values.district}
                            className="form-input" />
                          {errors.district && <Box className="form-error">{errors.district}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="village">Village</label>
                          <Field id="village"
                            type="text" placeholder="Enter village"
                            name="village"
                            value={values.village}
                            className="form-input" />
                          {errors.village && <Box className="form-error">{errors.village}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="landmark">Landmark</label>
                          <Field id="landmark"
                            type="text" placeholder="Enter landmark"
                            name="landmark"
                            value={values.landmark}
                            className="form-input" />
                          {errors.landmark && <Box className="form-error">{errors.landmark}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="police_station">Police Station</label>
                          <Field id="police_station"
                            type="text" placeholder="Enter police station"
                            name="police_station"
                            value={values.police_station}
                            className="form-input" />
                          {errors.police_station && <Box className="form-error">{errors.police_station}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="tehsil">Tehsil/Taluka</label>
                          <Field id="tehsil"
                            type="text" placeholder="Enter tehsil/taluka"
                            name="tehsil"
                            value={values.tehsil}
                            className="form-input" />
                          {errors.tehsil && <Box className="form-error">{errors.tehsil}</Box>}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Bank/UPI Details  <span className='must-fill-fields'>*</span></p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="bank_details_option">Bank / UPI Details</label>
                          <FormControl>
                            <RadioGroup className='radioBtnDiv'
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="bank_details_option"
                              value={values.bank_details_option}
                              onChange={(e) => {
                                setFieldValue('bank_details_option', e.target.value)
                              }}
                            >
                              <FormControlLabel value="bank_details" control={<Radio />} label="Bank Details" />
                              <FormControlLabel value="upi_details" control={<Radio />} label="UPI Details" />
                            </RadioGroup>
                          </FormControl>

                          {errors.bank_details_option && <Box className="form-error">{errors.bank_details_option}</Box>}
                        </Box>
                      </Grid>
                      {
                        values?.bank_details_option === "bank_details" && <>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="account_number">Account Number <span className='must-fill-fields'>*</span></label>
                              <Field id="account_number"
                                type="text" placeholder="Enter account number"
                                name="account_number"
                                value={values.account_number}
                                onChange={(e) => {
                                  setFieldValue("account_number", e.target.value)
                                  setFieldValue("confirm_account_number", e.target.value)
                                }}
                                className="form-input" />
                              {errors.account_number && <Box className="form-error">{errors.account_number}</Box>}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="confirm_account_number">Confirm account number <span className='must-fill-fields'>*</span></label>
                              <Field id="confirm_account_number"
                                type="text" placeholder="Enter confirm account number"
                                name="confirm_account_number"
                                value={values.confirm_account_number}
                                className="form-input" />
                              {errors.confirm_account_number && <Box className="form-error">{errors.confirm_account_number}</Box>}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="ifsc_code">IFSC Code<span className='must-fill-fields'>*</span></label>
                              <Field id="ifsc_code"
                                type="text" placeholder="Enter Ifsc code"
                                name="ifsc_code"
                                value={values.ifsc_code}
                                className="form-input" />
                              {errors.ifsc_code && <Box className="form-error">{errors.ifsc_code}</Box>}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="account_holder_name">Account holder name <span className='must-fill-fields'>*</span></label>
                              <Field id="account_holder_name"
                                type="text" placeholder="Enter account holder number"
                                name="account_holder_name"
                                value={values.account_holder_name}
                                className="form-input" />
                              {errors.account_holder_name && <Box className="form-error">{errors.account_holder_name}</Box>}
                            </Box>
                          </Grid></>}

                      {values.bank_details_option === "upi_details" && <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="upi_id">UPI ID<span className='must-fill-fields'>*</span></label>
                          <Field id="upi_id"
                            type="text" placeholder="Enter upi id"
                            name="upi_id"
                            value={values.upi_id}
                            className="form-input" />
                          {errors.upi_id && <Box className="form-error">{errors.upi_id}</Box>}
                        </Box>
                      </Grid>}
                    </Grid>
                  </Box>

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>ID Verifications</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="id_verification">ID Verification</label>
                          <FormControl>
                            <RadioGroup className='radioBtnDiv'
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="id_verification"
                              value={values.id_verification}
                              onChange={(e) => {
                                setFieldValue('id_verification', e.target.value)
                                let govermentIDData = e.target.value === "1" ? individualGovernmentId : companyGovernmentId
                                setFieldValue('government_id_data', govermentIDData)
                                setFieldValue("government_id", " ")
                                setFieldValue("government_id_detail", "")
                              }}
                            >
                              <FormControlLabel value="1" control={<Radio />} label="Individual" />
                              <FormControlLabel value="2" control={<Radio />} label="Company" />
                            </RadioGroup>
                          </FormControl>

                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="government_id">Government Id</label>

                          <FormControl name="government_id" fullWidth size="small">
                            <Select
                              name="government_id"
                              id="government_id"
                              value={values.government_id}
                              onChange={(event) => {
                                setFieldValue("government_id", event.target.value)
                              }}>
                              <MenuItem value=" ">Select government id</MenuItem>
                              {values?.government_id_data?.map((item) => {
                                return <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      {values.government_id !== " " && <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="government_id_detail">Enter your {getGovernmentIdName(values.government_id_data, values.government_id)} </label>
                          <Field id="government_id_detail"
                            type="text" placeholder={`Enter your ${getGovernmentIdName(values.government_id_data, values.government_id)}`}
                            name="government_id_detail"
                            value={values.government_id_detail}
                            className="form-input" />
                          {errors.government_id_detail && <Box className="form-error">{errors.government_id_detail}</Box>}
                        </Box>
                      </Grid>}
                    </Grid>
                  </Box>



                  <Box className="form-button-section">

                    <Link to='/jobsahayak'>
                      <Button className="form-button"
                        variant="contained"
                        onClick={() => {
                          resetForm();
                        }}
                        color="error"
                      >Cancel</Button>
                    </Link>

                    <Button className="form-button"
                      variant="contained"
                      type="submit"
                      color="success"
                      disabled={formSubmitted}>{
                        editJobSahayak ? "Update" : "Create"
                      }
                    </Button>

                  </Box>
                  <FocusError values={values} handleSnackBar={handleSnackBar} />
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>


    </Box > : (<Unauthorized />)
  )

}
export default CreateJWP;
