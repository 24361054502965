import {
  Box,
  Button,
  Container,
  Grid,
  Select,
  MenuItem,
  Typography,
  Switch,
  Breadcrumbs,
  Link as MuiLink,
  CircularProgress,
  Input,
  Skeleton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import {
  CreateFirstFormValidationSchema,
  CreateSecondFormValidationSchema,
  CreateThirdFormValidationSchema,
} from "../../utils/Validations";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  patchRequestWithToken,
  postRequest,
  getRequestWithToken,
  getRequestTemplate,
  deleteRequest,
} from "../../utils/ApiRequests";
import { useContext, useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import FocusError from "../../components/FocusError/FocusError";
import BackBtn from "../../components/backButton/BackBtn";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import "./marketing.scss";
import DoneIcon from "@mui/icons-material/Done";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreatableSelect from "react-select/creatable";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LinearProgress from "@mui/material/LinearProgress";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { set } from "date-fns";

const CreateWhatsapp = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([]);
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [fileType, setFileType] = useState("");
  const [uploadErrors, setUploadErrors] = useState({});
  const [fileUpload, setFileUpload] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [emptyCsvFile, setEmptyCsvFile] = useState(null);
  const [foundButtons, setFoundButtons] = useState([]);

  const handleFileTypeChange = (event) => {
    const newFileType = event.target.value;
    setFileType(newFileType);
    setFileUpload(null);

    if (fileUpload) {
      const isValidFileType =
        (newFileType === "image" && fileUpload.type.startsWith("image/")) ||
        (newFileType === "video" && fileUpload.type.startsWith("video/")) ||
        (newFileType === "document" &&
          [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(fileUpload.type));

      if (!isValidFileType) {
        setUploadErrors((prevErrors) => ({
          ...prevErrors,
          fileUpload: `Please upload a ${newFileType} file.`,
        }));
      } else {
        setUploadErrors((prevErrors) => ({
          ...prevErrors,
          fileUpload: null,
        }));
      }
    }
  };

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB
  const MAX_VIDEO_DOC_SIZE = 10 * 1024 * 1024; // 10MB

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const isValidFileType =
      (fileType === "image" && file.type.startsWith("image/")) ||
      (fileType === "video" && file.type.startsWith("video/")) ||
      (fileType === "document" &&
        [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(file.type));

    const maxSize = fileType === "image" ? MAX_IMAGE_SIZE : MAX_VIDEO_DOC_SIZE;
    const isValidFileSize = file.size <= maxSize;

    console.log("File type:", file.type);
    console.log("File size:", file.size);

    if (!isValidFileType) {
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: `Please upload a ${fileType} file.`,
      }));
      setFileUpload(null);
    } else if (!isValidFileSize) {
      const maxSizeMB = maxSize / (1024 * 1024);
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: `File size should be less than ${maxSizeMB}MB.`,
      }));
      setFileUpload(null);
    } else {
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: null,
      }));
      setFileUpload(file);
      console.log("fileUpload:", fileUpload);
    }
  };

  // const getPlaceholderText = () => {
  //   switch (fileType) {
  //     case "image":
  //       return "Choose an image file";
  //     case "video":
  //       return "Choose a video file";
  //     case "document":
  //       return "Choose a document file";
  //     default:
  //       return "Choose a file";
  //   }
  // };

  // const getNoteText = () => {
  //   switch (fileType) {
  //     case "image":
  //       return "Image size should be less than 5mb";
  //     case "video":
  //       return "Video size should be less than 10mb";
  //     case "document":
  //       return "PDF size should be less than 10mb";
  //     default:
  //       return "File size should be less than 10mb";
  //   }
  // };

  // const [dateTimePickers, setDateTimePickers] = useState([{}]);

  // const addDateTimePicker = () => {
  //   setDateTimePickers([...dateTimePickers, {}]);
  // };

  // const removeDateTimePicker = (index) => {
  //   setDateTimePickers(dateTimePickers.filter((_, i) => i !== index));
  // };

  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    if (values.sendLater && values.scheduleTime === "") {
      setErrors({ scheduleTime: "Please select date and time" });
      return;
    }
    let scheduledTimeValue = values.scheduleTime
      ? values.scheduleTime
      : new Date().toISOString();
    let formData = new FormData();

    formData.append("modeOfSend", "whatsapp");
    formData.append("campaignName", values.campaignName);
    formData.append("campaignType", values.campaignType);
    formData.append("csvFile", values.csvFile);
    formData.append(
      "params",
      JSON.stringify({
        whatsappParams: {
          templateId: values.templateId,
          templateContent: null,
          templateLanguage: values.templateLanguage,
        },
      })
    );
    formData.append("bodyTextContent", values.bodyTextContent);
    formData.append("image", fileUpload);
    formData.append("scheduledTime", scheduledTimeValue);
    formData.append("tag", value?.label);
    setShowLoader(true);

    try {
      let res = await postRequest("admin/campaign-schedule", formData);

      if (res.data.status === "Success") {
        setShowLoader(false);
        setFormSubmitted(false);
        if (!editForm) {
          resetForm();
          fileRef.current.value = "";
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
          }
          handleSnackBar(true, res.data.msg, "success");
        } else {
          handleSnackBar(true, res.data.msg, "success");
        }
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };
  const getCandidateById = async () => {
    try {
      let response = await getRequestWithToken(
        `admin/userbyid/?usertype=job_seeker&userid=${location.state?._id}`
      );
      setDataById(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.msg || error?.message,
        "error"
      );
      setShowLoader(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Whatsapp Campaign";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Whatsapp Campaign"));
  };

  const getTags = async () => {
    try {
      let response = await getRequestWithToken("admin/tags");
      response = response?.data;
      if (response.status === "Success") {
        let tags = response.data
          .filter((tag) => tag && tag !== "undefined")
          .map((tag) => ({
            label: tag.replace("_", " "),
            value: tag.replace("_", " "),
          }));
        setOptions(tags);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
    formNumber === 1 && getTags();

    if (location.state?._id) {
      // getCandidateById();
    } else {
      setShowLoader(false);
    }

    // if (location.state?._id) {
    //   const { candidate = {}, deleted = {} } = dataById;
    //   const {
    //     campaignName = "",
    //     templateId = "",
    //     campaignType = "",
    //     csvFile = "",
    //     bodyTextContent = "",
    //     templateLanguage = "",
    //   } = candidate;

    //   setInitialValues({
    //     campaignName,
    //     templateId,
    //     campaignType,
    //     csvFile,
    //     bodyTextContent,
    //     templateLanguage,
    //   });
    //   setEditForm(true);
    // } else {
    //   setInitialValues({
    //     campaignName: "",
    //     templateId: "",
    //     campaignType: "",
    //     csvFile: "",
    //     bodyTextContent: "",
    //     templateLanguage: "",
    //     sendLater: false,
    //     scheduleTime: "",
    //     tag: "",
    //     // date: null,
    //     // time: null,
    //   });
    //   setEditForm(false);
    // }
  }, [location.state, dataById._id, isIncludesValue]);

  const fileRef = useRef(null);
  const fileInputRef = useRef(null);

  const [formErrors, setFormErrors] = useState({});

  const handleCreate = (inputValue, setFieldValue) => {
    setValue({ value: inputValue, label: inputValue });
    setFieldValue("campaignTag", { value: inputValue, label: inputValue });
  };
  ///new Code
  const [formNumber, setFormNumber] = useState(1);
  const [firstFormValues, setFirstFormValues] = useState({
    campaignName: "",
    campaignTag: "",
    campaignType: "",
    scheduleTime: null,
    sendLater: false,
  });

  const [loading, setLoading] = useState(null);

  const [secondFormValues, setSecondFormValues] = useState({
    dataSource: "",
    condition: "",
    conditionData: [],
    startDate: null,
    endDate: null,
    csv: "",
    count: 0,
    eligibleCount: 0,
  });

  const datePickerRef = useRef(null);

  const onChange = (dates, setFieldValue) => {
    const [start, end] = dates;
    setFieldValue("startDate", start);
    setFieldValue("endDate", end);
    setFieldValue("count", 0);
    setFieldValue("eligibleCount", 0);
  };

  const [thirdFormValues, setThirdFormValues] = useState({
    templateId: "",
    templateLanguage: "",
    templatePreview: "",
    templateAttachement: "",
    addAttachement: false,
    fileType: "",
    file: "",
    variableList: [],
    userKeysData: [],
    isJobId: false,
    isUrlBtn: false,
    isQuickReplyBtn: false,
    utm_medium: "",
    utm_source: "",
    utm_campaign: "",
    dataSource: dataSource
  });

  const [templateData, setTemplateData] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const initialBreadCrumbs = [
    { id: 1, label: "Campaign Details", completed: false },
    { id: 2, label: "Data source", completed: false },
    {
      id: 3,
      label: "Template",
      completed: false,
    },
    {
      id: 4,
      label: "Review",
      completed: false,
    },
  ];
  const [breadCrumbItems, setBreadCrumbItems] = useState(initialBreadCrumbs);

  const handleSubmitFirstForm = async (values, { resetForm, setErrors }) => {
    if (values.sendLater && values.scheduleTime === "") {
      setErrors({ scheduleTime: "Please select date and time" });
      return;
    }
    setFormSubmitted(true);
    let scheduledTimeValue = values.scheduleTime
      ? values.scheduleTime
      : new Date().toISOString();
    setFirstFormValues((prev) => {
      return {
        ...prev,
        campaignName: values.campaignName,
        campaignTag: values.campaignTag,
        campaignType: values.campaignType,
        sendLater: values.sendLater,
        scheduleTime: scheduledTimeValue,
      };
    });
    let formData = new FormData();
    formData = {
      modeOfSend: "whatsapp",
      campaignName: values.campaignName,
      tag: values.campaignTag.label,
      campaignType: values.campaignType,
      scheduledTime: scheduledTimeValue,
    };

    try {
      setFormSubmitted(true);
      let res = await postRequest("admin/campaign/create", formData);

      if (res.data.status === "Success") {
        let campignId = res.data.data.campaignId;
        setCampaignId(campignId);
        setFormSubmitted(false);
        setFormNumber(2);
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const checkCSV = (event, setFieldValue, setErrors) => {
    setErrors({ csvFile: "" });
    setEmptyCsvFile(false);
    const file = event.target.files[0];
    setFieldValue("csvFile", file);
    setCsvFile(file);
    if (!file) return;
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      // console.log(text, "text");
      if (text.trim() === "") {
        setErrors({
          csvFile:
            "The CSV file you uploaded is empty. Please add data to the file and try again",
        });
        setEmptyCsvFile(true);
      }
    };

    reader?.readAsText(file);
  };

  const getCondition = async (setFieldValue, value) => {
    if (value === "CSV") return;
    try {
      let res = await getRequestWithToken(
        `admin/campaign/conditions?modeOfSend=whatsapp&source=${value}`
      );

      if (res.data.status === "Success") {
        setFieldValue("conditionData", res.data.data);
      }
    } catch (err) {
      setFieldValue("conditionData", []);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const changeDataSource = (event, setFieldValue) => {
    setFieldValue("dataSource", event.target.value);
    getCondition(setFieldValue, event.target.value);
    setDataSource(event.target.value);
    setFieldValue("condition", "");
    setFieldValue("count", 0);
    setFieldValue("eligibleCount", 0);
    setFieldValue("startDate", "");
    setFieldValue("endDate", "");
    setFieldValue("radius", "");
    setFieldValue("pincode", "");
    setFieldValue("jobId", "");
  };

  const handleSubmitSecondForm = async (values, { setFieldValue }) => {
    console.log("values", values.radius);
    // return;
    let startingDate = values.startDate
      ? values.startDate.toISOString()
      : new Date(new Date().setFullYear(new Date().getFullYear() - 2));
    let endingDate = values.endDate
      ? values.endDate.toISOString()
      : new Date().toISOString();

    setLoading(true);
    let formData = new FormData();
    formData = {
      modeOfSend: "whatsapp",
      campaignId: campaignId,
      dataSource: values.dataSource,
      condition: values.condition,
      dateCriteria: {
        from: startingDate,
        till: endingDate,
      },
      ...(values.condition === 'Pincode' && { range: values.radius, pincode: values.pincode }),
      ...(values.condition === 'Around Job Location' && { range: values.radius, jobId: values.jobId }),

    };

    try {
      let res = await patchRequestWithToken(
        "admin/campaign/check-count",
        formData
      );
      if (res.data.status === "Success") {
        res = res?.data?.data;
        setFieldValue("count", res.eligibleUsersCount);
        setFieldValue("eligibleCount", res.actualUserCount);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const goToThirdForm = (setErrors) => {
    if (dataSource === "CSV" && !csvFile) {
      setErrors({ csvFile: "Please upload a csv file" });
      return;
    } else if (dataSource === "CSV" && csvFile && csvFile.type !== "text/csv") {
      setErrors({ csvFile: "Please upload a file of csv format " });
      return;
    } else {
      setFormSubmitted(true);
      setTimeout(() => {
        setFormSubmitted(false);
        setFormNumber(3);
      }, [500]);
    }
  };

  const getVariables = (value, setFieldValue) => {
    // setThirdFormValues({ templatePreview: value });
    const regex = /\{{(\w+)}}/g;
    let variables = [];
    let match;
    while ((match = regex.exec(value)) !== null) {
      variables.push(match[1]);
    }
    // setVariablesList(variables);
    // console.log("getVariablesList : ", variablesList);
    setFieldValue("variableList", variables);
    return variables;
  };

  const [userKeysArray, setUserKeysArray] = useState([]);
  const [userKeysArrayJob, setUserKeysArrayJob] = useState([]);
  const [userKeysArrayFinal, setUserKeysArrayFinal] = useState([]);
  const [IsJobId, setIsJobId] = useState(false);

  // const getUserKeys = async (dataSource, setFieldValue) => {
  //   try {
  //     let response = await getRequestWithToken(
  //       `admin/campaign/user-keys?source=${dataSource?.toLowerCase()}`
  //     );
  //     response = response?.data;
  //     if (response.status === "Success") {
  //       setFieldValue("userKeysData", response.data);
  //       setUserKeysArray(response.data);
  //     }
  //   } catch (err) {
  //     setFieldValue("userKeysData", []);
  //     console.error(err);
  //   }
  // };

  const getUserKeys = async (dataSource, setFieldValue) => {
    try {
      let response = await getRequestWithToken(
        `admin/campaign/user-keys?source=${dataSource?.toLowerCase()}`
      );
      response = response?.data;
      if (response.status === "Success") {
        setFieldValue("userKeysData", response.data);

        if (dataSource.toLowerCase() === "job") {
          setUserKeysArrayJob(response.data);
        } else {
          setUserKeysArray(response.data);
        }
      }
    } catch (err) {
      setFieldValue("userKeysData", []);
      console.error(err);
    }
  };

  // Use useEffect to update the final array when IsJobId or either of the arrays changes
  useEffect(() => {
    if (IsJobId) {
      setUserKeysArrayFinal([...userKeysArray, ...userKeysArrayJob]);
    } else {
      setUserKeysArrayFinal([...userKeysArray]);
    }
  }, [IsJobId, userKeysArray, userKeysArrayJob]);

  // Function to toggle IsJobId
  const toggleIsJobId = (newState) => {
    // console.log("newState:", newState);
    setIsJobId(newState); // React will trigger the useEffect hook after the state updates
  };


  const handleTemplateChange = async (event, value, setFieldValue) => {
    let templateValue = value?.name;

    // Find the buttons component
    const buttonsComponent = value.components?.find(
      component => component.type === "BUTTONS"
    );

    // Get the buttons array
    const buttons = buttonsComponent?.buttons || [];
    console.log('Template Buttons:', buttons);
    setFoundButtons(buttons);

    // Check for URL type button and its name

    const urlButton = buttons.find(button => button.type === "URL" && (button?.url?.includes("{{")));
    console.log('URL Button:', urlButton);
    await setFieldValue("isUrlBtn", !!urlButton);
    // await setFieldValue("isUrlBtn", true);
    await setFieldValue("isUrlBtnName", urlButton ? urlButton.text : "");

    // Check for QUICK_REPLY type button and its name
    const quickReplyButton = buttons.find(button => button.type === "QUICK_REPLY" && !button.url);
    await setFieldValue("isQuickReplyBtn", !!quickReplyButton);
    await setFieldValue("isQuickReplyBtnName", quickReplyButton ? quickReplyButton.text : "");

    await setFieldValue("templateId", templateValue);
    await setFieldValue("fileType", "");
    await setFieldValue("jobId2", "");
    await setFieldValue("addAttachement", false)
    await setFileType("");


    if (buttons.length > 0) {
      if (dataSource !== "CSV") {
        await setFieldValue('isJobId', true);

      }
      await getUserKeys('job', setFieldValue);
      toggleIsJobId(true);
    } else {
      await setFieldValue('isJobId', false);
    }

    let templateDataPreview = templateData?.find(
      (template) => template?.name === templateValue
    );
    let templateAttachementType = templateDataPreview?.components?.[0]?.format;
    if (templateAttachementType && templateAttachementType !== "TEXT") {
      let fileType = templateAttachementType?.toLowerCase();
      setFieldValue("fileType", fileType);
      setFieldValue("addAttachement", true);
      setFileType(fileType);
    }
    setFieldValue("templateLanguage", templateDataPreview?.language);
    setFieldValue(
      "templatePreview",
      templateDataPreview?.components?.[1]?.text || templateDataPreview?.components?.[0]?.text
    );
    setThirdFormValues((prev) => {
      return {
        ...prev,
        templatePreview: templateDataPreview?.components?.[1]?.text || templateDataPreview?.components?.[0]?.text,
      };
    });

    setUploadErrors({});
    const templateCheck = templateDataPreview?.components?.[1]?.text || templateDataPreview?.components?.[0]?.text
    dataSource !== "CSV" &&
      getVariables(templateCheck, setFieldValue);
    dataSource !== "CSV" && getUserKeys(dataSource, setFieldValue);
  };

  const handleSubmitThirdForm = async (values, { setErrors }) => {
    console.log("values", values);

    let formData = new FormData();
    let formValid = true;
    let variables = {};
    let newErrors = {};
    values?.variableList.forEach((variable) => {
      variables[`{{${variable}}}`] = values[`variable${variable}`] || "";

      if (variables[`{{${variable}}}`] === "") {
        // console.log(variables[`{{${variable}}}`]);
        let variableName = `variable${variable}`;
        newErrors[variableName] = "This field is required";
        formValid = false;
      }
    });

    if (values.addAttachement && !fileUpload) {
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: `Please upload a ${values?.fileType} file.`,
      }));
      return;
    }
    setFormErrors(newErrors);

    if (!formValid) return;

    formData = {
      campaignId: campaignId,
      templateId: values.templateId,
      templateLanguage: values.templateLanguage,
      jobId: values.jobId2,
      variables: variables,
      button: {
        QUICK_REPLY: values.isQuickReplyBtn,
        URL: values.isUrlBtn
      },
      ...(values.isUrlBtn && {
        utm: {
          utm_campaign: values.utm_campaign,
          utm_source: values.utm_source,
          utm_medium: values.utm_medium
        }
      })
    };
    let formData2 = new FormData();
    if (values.addAttachement) {
      fileUpload && formData2.append("document", fileUpload);
      formData2.append("campaignName", firstFormValues?.campaignName);
    }
    try {
      setFormSubmitted(true);
      let res = await patchRequestWithToken(
        "admin/campaign/template/whatsapp",
        formData
      );
      if (res.data.status === "Success") {
        if (values.addAttachement) {
          let attachementFile = await postRequest(
            `admin/campaign/template/whatsapp/attachment?campaignId=${campaignId}`,
            formData2
          );
        }

        setFormSubmitted(false);
        setFormNumber(4);
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const handleSumbitCSVThirdForm = async (values) => {
    if (values.addAttachement && !fileUpload) {
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: `Please upload a ${values?.fileType} file.`,
      }));
      return;
    }
    setFormSubmitted(true);
    let formData = new FormData();
    formData.append("modeOfSend", "whatsapp");
    formData.append("campaignName", firstFormValues.campaignName);
    formData.append("campaignType", firstFormValues.campaignType);
    formData.append("csvFile", csvFile);
    formData.append(
      "params",
      JSON.stringify({
        whatsappParams: {
          templateId: values.templateId,
          templateContent: null,
          templateLanguage: values.templateLanguage,
          button: {
            QUICK_REPLY: values.isQuickReplyBtn,
            URL: values.isUrlBtn
          },
          jobId: values.jobId2,
          ...(values.isUrlBtn && {
            utm: {
              utm_campaign: values.utm_campaign,
              utm_source: values.utm_source,
              utm_medium: values.utm_medium
            }
          })
        },
      })
    );
    formData.append("bodyTextContent", "");
    formData.append("campaignId", campaignId);
    values.addAttachement && formData.append("document", fileUpload);

    try {
      let res = await postRequest("admin/campaign-schedule/csv", formData);
      if (res.data.status === "Success") {
        setFormSubmitted(false);
        setFormNumber(4);
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const handleSubmitFourthForm = async (values) => {
    setFormSubmitted(true);
    let formData = new FormData();
    formData = {
      campaignId: campaignId,
    };
    try {
      let res = await patchRequestWithToken(
        "admin/campaign/activate",
        formData
      );
      if (res.data.status === "Success") {
        setFormSubmitted(false);
        handleSnackBar(true, "Campaign created successfully", "success");
        setCampaignId(null);

        setTimeout(() => {
          navigate("/whatsapp-campaign");
        }, 1000);
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const cancelCampaign = async () => {
    try {
      let response = await deleteRequest(
        `/admin/campaign?campaignId=${campaignId}`
      );
      response = response.data;
      if (response?.status === "Success") {
        handleSnackBar(true, "Campaign canceled", "success");
        setTimeout(() => {
          navigate("/whatsapp-campaign");
        });
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  useEffect(() => {
    const getTemplates = async () => {
      try {
        let res = await getRequestTemplate("message_templates?status=APPROVED");
        res = res?.data;
        setTemplateData(res);
      } catch (err) {
        setFormSubmitted(false);
        handleSnackBar(true, err?.response?.data?.msg, "error");
      }
    };

    const getCampaignDetails = async () => {
      try {
        setFormSubmitted(true);
        let res = await getRequestWithToken(
          `admin/campaign/campaign-id?campaignId=${campaignId}`
        );
        res = res?.data;
        if (res.status === "Success") {
          setPreviewData(res.data);
          setFormSubmitted(false);
        } else {
          setPreviewData([]);
          setFormSubmitted(false);
        }
      } catch (err) {
        setFormSubmitted(false);
        setPreviewData([]);
        setFormSubmitted(false);
        handleSnackBar(true, err?.response?.data?.msg, "error");
      }
    };

    const completeForm = () => {
      if (formNumber < 1) return;
      setBreadCrumbItems((prevItems) =>
        prevItems.map((item) =>
          item.id === formNumber - 1 ? { ...item, completed: true } : item
        )
      );
    };

    completeForm();
    formNumber === 3 && getTemplates();
    formNumber === 4 && getCampaignDetails();
  }, [formNumber]);

  return isIncludesValue ? (
    <>
      {showLoader ? (
        <ReusableLoader />
      ) : (
        <Box className="create-whatsapp-campaign-section" ref={scrollTargetRef}>
          <Container>
            <Box className="create-whatsapp-campaign">
              <Box className="headingPlusBackBtn">
                <BackBtn />
                <h1 className="formHeadings" id="topEle">
                  {editForm ? "" : "New WhatsApp Campaign"}
                </h1>
              </Box>
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ margin: "20px 0px" }}
                separator=">"
              >
                {breadCrumbItems?.map((item) => (
                  <MuiLink
                    key={item?.id}
                    underline="hover"
                    color={`${item?.id === formNumber ? "text.primary" : "inherit"
                      }`}
                    sx={{ display: "flex", alignItems: "center" }}
                  // onClick={() => {
                  //   item?.completed && setFormNumber(item?.id);
                  // }}
                  >
                    {item?.completed && (
                      <DoneIcon
                        color={`${item?.id === formNumber ? "text.primary" : "inherit"
                          }`}
                      />
                    )}
                    {item?.label}
                  </MuiLink>
                ))}
              </Breadcrumbs>
              {formSubmitted && (
                <LinearProgress
                  style={{
                    width: "100%",
                    position: "absolute",
                    left: "0",
                    top: "66px",
                    zIndex: "999",
                  }}
                />
              )}
              {formNumber === 1 && (
                <Box className="create-whatsapp-campaign-form-wrapper">
                  <Formik
                    initialValues={firstFormValues}
                    validationSchema={CreateFirstFormValidationSchema}
                    onSubmit={handleSubmitFirstForm}
                    enableReinitialize
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="create-whatsapp-campaign-form">
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="campaignName"
                                    >
                                      {" "}
                                      Campaign Name{" "}
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="campaignName"
                                      type="text"
                                      placeholder="Enter Campaign Name"
                                      name="campaignName"
                                      className="form-input"
                                      size="small"
                                    />
                                    {errors.campaignName && (
                                      <Box className="form-error">
                                        {errors.campaignName}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="campaignTag"
                                    >
                                      Campaign Tag
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <CreatableSelect
                                      isClearable
                                      isDisabled={isLoading}
                                      isLoading={isLoading}
                                      onChange={(newValue) => {
                                        setValue(newValue);
                                        setFieldValue("campaignTag", newValue);
                                      }}
                                      onCreateOption={(inputValue) =>
                                        handleCreate(inputValue, setFieldValue)
                                      }
                                      options={options}
                                      value={value}
                                      placeholder="Select or create a campaign tag"
                                    />
                                    {errors.campaignTag && (
                                      <Box className="form-error">
                                        {errors.campaignTag}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="campaignType"
                                    >
                                      {" "}
                                      Campaign Type{" "}
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="cstmSelectBox"
                                      name="campaignType"
                                      id="campaignType"
                                      value={values.campaignType}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "campaignType",
                                          event.target.value
                                        );
                                      }}
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected
                                          ? selected
                                          : "Select campaignType"
                                      }
                                    >
                                      <MenuItem value="" disabled>
                                        Select campaignType
                                      </MenuItem>
                                      <MenuItem value="marketing">
                                        Marketing
                                      </MenuItem>
                                      <MenuItem value="utility">
                                        Utility
                                      </MenuItem>
                                    </Select>

                                    {errors.campaignType && (
                                      <Box className="form-error">
                                        {errors.campaignType}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography>Send Later</Typography>
                                <Switch
                                  checked={values.sendLater}
                                  name="sendLater"
                                  onChange={async () => {
                                    let switchValue = !values.sendLater;
                                    await setFieldValue(
                                      "sendLater",
                                      switchValue
                                    );
                                    if (values?.sendLater) {
                                      setFieldValue("scheduleTime", null);
                                    }
                                  }}
                                />
                              </Box>
                              {values.sendLater && (
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Box className="form-control">
                                      <label
                                        className="form-label"
                                        htmlFor="date"
                                      >
                                        Date and Time
                                      </label>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DateTimePicker
                                          label=""
                                          value={values.scheduleTime}
                                          className="cstmDateTimePicker"
                                          onChange={(newValue) => {
                                            // console.log(newValue, "newValue");
                                            const utcDate = new Date(
                                              newValue
                                            ).toISOString();

                                            setFieldValue(
                                              "scheduleTime",
                                              utcDate
                                            );
                                          }}
                                        />
                                      </LocalizationProvider>

                                      {errors.scheduleTime && (
                                        <Box className="form-error">
                                          {errors.scheduleTime}
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              resetForm();
                              setFormSubmitted(false);
                              setValue("");
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>

                          <Button
                            className="form-button"
                            variant="contained"
                            type="submit"
                            color="success"
                            disabled={formSubmitted}
                          >
                            Next
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}

              {formNumber === 2 && (
                <Box className="create-whatsapp-campaign-form-wrapper">
                  <Formik
                    initialValues={secondFormValues}
                    validationSchema={CreateSecondFormValidationSchema}
                    onSubmit={handleSubmitSecondForm}
                    enableReinitialize
                  >
                    {({
                      errors,
                      values,
                      setFieldValue,
                      resetForm,
                      setErrors,
                    }) => (
                      <Form className="create-whatsapp-campaign-form">
                        {/* {formSubmitted && <LinearProgress />} */}
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={6} md={4}>
                              <Box className="form-control">
                                <label
                                  className="form-label"
                                  htmlFor="dataSource"
                                >
                                  Data Source
                                  <span className="must-fill-fields">*</span>
                                </label>
                                <Select
                                  className="cstmSelectBox"
                                  name="dataSource"
                                  id="dataSource"
                                  value={values.dataSource}
                                  onChange={(event) => {
                                    changeDataSource(event, setFieldValue);
                                  }}
                                  displayEmpty
                                  renderValue={(selected) =>
                                    selected ? selected : "Select Data Source"
                                  }
                                >
                                  <MenuItem value="" disabled>
                                    Data Source
                                  </MenuItem>
                                  <MenuItem value="CSV">CSV</MenuItem>
                                  <MenuItem value="Employer">Employer</MenuItem>
                                  <MenuItem value="Candidate">
                                    Candidate
                                  </MenuItem>
                                </Select>
                                {errors.dataSource && (
                                  <Box className="form-error">
                                    {errors.dataSource}
                                  </Box>
                                )}
                              </Box>
                            </Grid>

                            {values.dataSource === "CSV" && (
                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <label className="form-label">
                                    Upload CSV
                                    <span className="must-fill-fields">*</span>
                                  </label>

                                  <input
                                    disabled={
                                      values.dataSource !== "CSV" ||
                                      values.dataSource === ""
                                    }
                                    type="file"
                                    name="csvFile"
                                    className="mt-3 input-width upload_button"
                                    id="csvFile"
                                    onChange={(event) => {
                                      checkCSV(event, setFieldValue, setErrors);
                                    }}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    style={{
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "8px",
                                      backgroundColor: "#f7f7f7",
                                      color: "#333",
                                      fontSize: "14px",
                                    }}
                                    ref={fileRef}
                                  />
                                  {errors.csvFile && (
                                    <Box className="form-error">
                                      {errors.csvFile}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            )}
                            {(values.dataSource === "Employer" ||
                              values.dataSource === "Candidate") && (
                                <>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <Box className="form-control">
                                      <label
                                        className="form-label"
                                        htmlFor="condition"
                                      >
                                        Condition
                                        <span className="must-fill-fields">
                                          *
                                        </span>
                                      </label>
                                      <Select
                                        className="cstmSelectBox"
                                        name="condition"
                                        id="condition"
                                        value={values.condition || ""}
                                        onChange={async (event) => {
                                          const { value } = event.target;
                                          await setFieldValue("condition", value);
                                          await setFieldValue("count", 0);
                                          await setFieldValue("eligibleCount", 0);
                                          await setFieldValue("startDate", "");
                                          await setFieldValue("endDate", "");
                                          await setFieldValue("pincode", "");
                                          await setFieldValue("radius", "");
                                          await setFieldValue("jobId", "");
                                        }}
                                        displayEmpty
                                        renderValue={(selected) =>
                                          selected ? selected : "Select Condition"
                                        }
                                      >
                                        <MenuItem value="" disabled>
                                          Condition
                                        </MenuItem>

                                        {values.conditionData?.map(
                                          (condition, index) => (
                                            <MenuItem
                                              value={condition}
                                              key={index}
                                            >
                                              {condition}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      {errors.condition && (
                                        <Box className="form-error">
                                          {errors.condition}
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>

                                  {((values.condition === "Pincode" || values.condition === "Around Job Location") ||
                                    firstFormValues?.modeOfSend === "banner") && (
                                      <Grid item xs={12} sm={6} md={4}>
                                        <Box className="form-control">
                                          <label className="form-label" htmlFor="radius">
                                            Enter Radius (Km)
                                            <span className="must-fill-fields">*</span>
                                          </label>
                                          <Field
                                            id="radius"
                                            type="number"
                                            placeholder="Enter Radius"
                                            name="radius"
                                            className="form-input"
                                            size="small"
                                            min="0"
                                          />
                                          {errors.radius && (
                                            <Box className="form-error">
                                              {errors.radius}
                                            </Box>
                                          )}
                                        </Box>
                                      </Grid>
                                    )}

                                  {values.condition === "Around Job Location" && (
                                    <Grid item xs={12} sm={6} md={4}>
                                      <Box className="form-control">
                                        <label className="form-label" htmlFor="jobId">
                                          Job ID
                                          <span className="must-fill-fields">*</span>
                                        </label>
                                        <Field
                                          id="jobId"
                                          type="text"
                                          placeholder="Enter Job Id"
                                          name="jobId"
                                          className="form-input"
                                          size="small"
                                          onChange={(e) => {
                                            const capitalizedValue = e.target.value.toUpperCase().replace(/\s+/g, "");
                                            setFieldValue("jobId", capitalizedValue); // Use Formik's `setFieldValue` to update the value
                                          }}
                                        />
                                        <Typography className="exPlaceholder">Ex: JYJ-00000</Typography>
                                        {errors.jobId && (
                                          <Box className="form-error">
                                            {errors.jobId}
                                          </Box>
                                        )}
                                      </Box>
                                    </Grid>
                                  )}

                                  {values.condition === "Pincode" && (
                                    <Grid item xs={12} sm={6} md={4}>
                                      <Box className="form-control">
                                        <label className="form-label" htmlFor="pincode">
                                          Pincode
                                          <span className="must-fill-fields">*</span>
                                        </label>
                                        <Field name="pincode">
                                          {({ field, form }) => (
                                            <input
                                              {...field}
                                              type="text"
                                              inputMode="numeric"
                                              pattern="[0-9]*"
                                              maxLength="6"
                                              placeholder="Enter Pincode"
                                              className="form-input"
                                              onChange={(e) => {
                                                const value = e.target.value
                                                  .replace(/[^0-9]/g, "")
                                                  .slice(0, 6);
                                                form.setFieldValue(field.name, value);
                                              }}
                                            />
                                          )}
                                        </Field>
                                        {errors.pincode && (
                                          <Box className="form-error">
                                            {errors.pincode}
                                          </Box>
                                        )}
                                      </Box>
                                    </Grid>
                                  )}

                                  <Grid item xs={12} sm={6} md={4}>
                                    <Box className="form-control">
                                      <label className="form-label">
                                        Date Range
                                      </label>

                                      <Box
                                        style={{
                                          border: "1px solid #c4c4c4",
                                          borderRadius: "5px",
                                          display: "flex",
                                          gap: "10px",
                                          padding: "5px",
                                          alignItems: "center",
                                          background: "#ffffff",
                                        }}
                                      >
                                        <DatePicker
                                          selected={values.startDate}
                                          onChange={(dates) => {
                                            onChange(dates, setFieldValue);
                                          }}
                                          startDate={values.startDate}
                                          endDate={values.endDate}
                                          selectsRange
                                          ref={datePickerRef}
                                          disabled={values.dataSource === "CSV"}
                                          placeholderText="Select Date Range"
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          dateFormat="MMMM d, yyyy"
                                        />
                                        <CalendarMonthIcon
                                          onClick={() =>
                                            datePickerRef.current.setOpen(true)
                                          }
                                        />
                                      </Box>

                                      {errors.dateRange && (
                                        <Box className="form-error">
                                          {errors.dateRange}
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} sm={3} md={4}></Grid>
                                  <Grid item xs={12} sm={3} md={4}></Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <Box className="form-control">
                                      <Button
                                        className="form-button"
                                        variant="contained"
                                        color="success"
                                        style={{
                                          width: "100%",
                                          marginLeft: "auto",
                                        }}
                                        disabled={
                                          values.dataSource === "CSV" ||
                                          values.dataSource === "" ||
                                          values.condition === "" ||
                                          formSubmitted ||
                                          (values.condition === "Pincode" &&
                                            (!values.pincode || !values.radius || values.pincode.length !== 6)) ||
                                          (values.condition === "Around Job Location" &&
                                            (!values.radius || !values.jobId))
                                        }
                                        type="submit"
                                      >
                                        Check Count
                                      </Button>
                                    </Box>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12} sm={4} md={4}></Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Box
                                          style={{
                                            background: "white",
                                            padding: "10px",
                                            border: "1px solid lightgray",
                                            borderRadius: "10px",
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            // width: "200px",
                                            // height: "180px",
                                          }}
                                        >
                                          <Typography>
                                            Number of total user
                                          </Typography>
                                          {loading ? (
                                            <CircularProgress />
                                          ) : (
                                            <Typography
                                              style={{
                                                fontSize: "40px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {values.count}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Box
                                          style={{
                                            background: "white",
                                            padding: "10px",
                                            border: "1px solid lightgray",
                                            borderRadius: "10px",
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            // width: "190px",
                                            // height: "180px",
                                          }}
                                        >
                                          <Typography>
                                            Number of users eligible for campaign
                                          </Typography>
                                          {loading ? (
                                            <CircularProgress />
                                          ) : (
                                            <Typography
                                              style={{
                                                fontSize: "40px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {values.eligibleCount}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              setOpenCancelModal(true);
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>
                          <Button
                            className="form-button"
                            variant="contained"
                            color="success"
                            disabled={
                              ((formSubmitted ||
                                !values.count ||
                                !values.eligibleCount) &&
                                values.dataSource !== "CSV") ||
                              (emptyCsvFile && values.dataSource === "CSV")
                            }
                            onClick={() => goToThirdForm(setErrors)}
                          >
                            Next
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}

              {formNumber === 3 && (
                <Box
                  className="create-whatsapp-campaign-form-wrapper"
                  style={{ position: "relative" }}
                >
                  <Formik
                    initialValues={thirdFormValues}
                    validationSchema={CreateThirdFormValidationSchema}
                    onSubmit={
                      dataSource === "CSV"
                        ? handleSumbitCSVThirdForm
                        : handleSubmitThirdForm
                    }
                  // enableReinitialize
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="create-whatsapp-campaign-form">
                        {/* {formSubmitted && <LinearProgress />} */}
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="templateId"
                                    >
                                      Select Template
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>

                                    <Autocomplete
                                      id="templateId"
                                      options={templateData || []}
                                      getOptionLabel={(option) =>
                                        option?.name || ""
                                      }
                                      value={
                                        templateData.find(
                                          (template) =>
                                            template?.name === values.templateId
                                        ) || ""
                                      }
                                      onChange={(event, value) => {
                                        handleTemplateChange(
                                          event,
                                          value,
                                          setFieldValue
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Select Template Id"
                                          // label="Select Template Id"
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {params.InputProps.endAdornment}
                                              </>
                                            ),
                                          }}
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <MenuItem
                                          {...props}
                                          value={option?.name}
                                        >
                                          {option?.name}
                                        </MenuItem>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.name === value?.name
                                      }
                                    />
                                    {errors.templateId && (
                                      <Box className="form-error">
                                        {errors.templateId}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                {(values?.variableList?.length > 0 || foundButtons?.length > 0) &&
                                  <>
                                    <Grid item xs={12} sm={6} md={3}>
                                      <Box
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography>JobID</Typography>
                                        <Switch
                                          checked={values.isJobId}
                                          name="isJobId"
                                          onChange={async () => {
                                            let switchValue = !values.isJobId;
                                            await setFieldValue(
                                              "isJobId",
                                              switchValue
                                            );
                                            console.log("values.isJobId", switchValue);
                                            if (switchValue) {
                                              getUserKeys('job', setFieldValue)
                                            }
                                            toggleIsJobId(switchValue);
                                          }}
                                        />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={7}>
                                      {values.isJobId && <Box className="form-control">

                                        <Field
                                          id="jobId2"
                                          type="text"
                                          placeholder="Enter Job Id"
                                          name="jobId2"
                                          className="form-input"
                                          size="small"
                                          onChange={(e) => {
                                            const capitalizedValue = e.target.value.toUpperCase().replace(/\s+/g, "");
                                            setFieldValue("jobId2", capitalizedValue); // Use Formik's `setFieldValue` to update the value
                                          }}
                                        />
                                        <Typography className="exPlaceholder">Ex: JYJ-00000</Typography>
                                        {errors.jobId2 && (
                                          <Box className="form-error">
                                            {errors.jobId2}
                                          </Box>
                                        )}
                                      </Box>}
                                    </Grid>
                                  </>
                                }

                                <Grid item xs={12} sm={12} md={12}>
                                  {values?.variableList?.length > 0 &&
                                    values?.variableList?.map((variable) => {
                                      let variableName = `variable${variable}`;

                                      // Convert options array to format required by react-select
                                      const selectOptions = userKeysArrayFinal?.map(key => ({
                                        label: key,
                                        value: key
                                      })) || [];

                                      // Custom styles to match your existing select
                                      const customStyles = {
                                        control: (provided) => ({
                                          ...provided,
                                          width: '300px',
                                        }),
                                      };

                                      return (
                                        <Box key={variable}>
                                          <Box
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                              alignItems: "center",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <label className="form-label">
                                              {`{{${variable}}}`}
                                            </label>
                                            <CreatableSelect
                                              styles={customStyles}
                                              className="cstmSelectBo"
                                              name={variableName}
                                              id={variableName}
                                              options={selectOptions}
                                              value={
                                                values[variableName]
                                                  ? {
                                                    label: values[variableName].replace("static=", "").replace(/_/g, " "),
                                                    value: values[variableName],
                                                  }
                                                  : null
                                              }
                                              onChange={(newValue) => {
                                                // Handle the value change
                                                let finalValue = newValue?.value;

                                                // If it's a custom value (created by user), add "static=" prefix
                                                if (newValue?.__isNew__) {
                                                  finalValue = `static=${newValue.value.replace(/\s+/g, "_")}`;
                                                }

                                                setFieldValue(variableName, finalValue);
                                                setFormErrors((prevErrors) => ({
                                                  ...prevErrors,
                                                  [variableName]: "",
                                                }));
                                              }}
                                              placeholder="Select variable key"
                                              isClearable
                                              formatCreateLabel={(inputValue) => `Use custom value: ${inputValue}`}
                                            />
                                          </Box>
                                          {formErrors[variableName] && (
                                            <Box
                                              className="form-error"
                                              style={{ marginTop: "10px" }}
                                            >
                                              {formErrors[variableName]}
                                            </Box>
                                          )}
                                        </Box>
                                      );
                                    })}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                style={{
                                  margin: "10px",
                                  border: "1px solid lightgray",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  minHeight: "200px",
                                  background: "white",
                                }}
                              >
                                {values.templatePreview}
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  gap: '5px',
                                  alignItems: "center",
                                }}

                                style={{
                                  margin: "10px",
                                  padding: "10px",

                                }}>
                                {foundButtons.map((btn, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                      margin: "10px"
                                    }}
                                  >
                                    {btn.type === "URL" && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "-5px",
                                          right: "-5px",
                                          backgroundColor: "#ff5757",
                                          color: "#fff",
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                          padding: "2px 5px",
                                          borderRadius: "5px",
                                          boxShadow: "0px 1px 3px rgba(0,0,0,0.3)"
                                        }}
                                      >
                                        URL
                                      </div>
                                    )}
                                    <Typography
                                      style={{
                                        padding: "10px",
                                        borderRadius: "10px",
                                        backgroundColor: "#ededed",
                                        boxShadow: "0px 2px 4px #696969",
                                      }}
                                    >
                                      {btn.text}
                                    </Typography>
                                  </div>
                                ))}


                              </Box>

                              {values.isUrlBtn && (<Box>

                                <Box className="form-control">
                                  <label className="form-label" htmlFor="utm_medium">
                                    UTM Medium

                                  </label>
                                  <Field name="utm_medium">
                                    {({ field, form }) => (
                                      <input
                                        {...field}
                                        type="text"

                                        placeholder="Enter utm_medium"
                                        className="form-input"
                                        onChange={(e) => {
                                          setFieldValue("utm_medium", e.target.value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                  {errors.utm_medium && (
                                    <Box className="form-error">
                                      {errors.utm_medium}
                                    </Box>
                                  )}
                                </Box>


                                <Box className="form-control">
                                  <label className="form-label" htmlFor="utm_source">
                                    UTM Source

                                  </label>
                                  <Field name="utm_source">
                                    {({ field, form }) => (
                                      <input
                                        {...field}
                                        type="text"

                                        placeholder="Enter utm_source"
                                        className="form-input"
                                        onChange={(e) => {
                                          setFieldValue("utm_source", e.target.value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                  {errors.utm_source && (
                                    <Box className="form-error">
                                      {errors.utm_source}
                                    </Box>
                                  )}
                                </Box>


                                <Box className="form-control">
                                  <label className="form-label" htmlFor="utm_campaign">
                                    UTM Campaign

                                  </label>
                                  <Field name="utm_campaign">
                                    {({ field, form }) => (
                                      <input
                                        {...field}
                                        type="text"

                                        placeholder="Enter utm_campaign"
                                        className="form-input"
                                        onChange={(e) => {
                                          setFieldValue("utm_campaign", e.target.value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                  {errors.utm_campaign && (
                                    <Box className="form-error">
                                      {errors.utm_campaign}
                                    </Box>
                                  )}
                                </Box>

                              </Box>)}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              {/* <Box
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography>Add attachement</Typography>
                                  <Switch
                                    checked={values.addAttachement}
                                    name="addAttachement"
                                    onChange={() =>
                                      setFieldValue(
                                        "addAttachement",
                                        !values.addAttachement
                                      )
                                    }
                                  />
                                </Box> */}
                              {values.addAttachement && (
                                <Grid container gap={2}>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <Box className="form-control">
                                      <label
                                        className="form-label"
                                        htmlFor="fileType"
                                      >
                                        File Type
                                      </label>
                                      <Select
                                        disabled={fileType}
                                        className="cstmSelectBox"
                                        name="fileType"
                                        id="fileType"
                                        value={fileType}
                                        onChange={handleFileTypeChange}
                                        displayEmpty
                                        renderValue={(selected) =>
                                          selected
                                            ? selected
                                            : "Select File Type"
                                        }
                                      >
                                        <MenuItem value="" disabled>
                                          Select File Type
                                        </MenuItem>
                                        <MenuItem value="image">Image</MenuItem>
                                        <MenuItem value="video">Video</MenuItem>
                                        <MenuItem value="document">
                                          Document
                                        </MenuItem>
                                      </Select>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <Box className="form-control">
                                      <label className="form-label">
                                        Upload File
                                        <span className="must-fill-fields">
                                          *{" "}
                                        </span>
                                      </label>
                                      <Input
                                        disabled={fileType === ""}
                                        type="file"
                                        id="fileUpload"
                                        name="fileUpload"
                                        accept={
                                          fileType === "image"
                                            ? "image/*"
                                            : fileType === "video"
                                              ? "video/*"
                                              : ".doc,.docx,.pdf"
                                        }
                                        className="form-input"
                                        onChange={handleFileUpload}
                                      />
                                    </Box>

                                    {uploadErrors.fileUpload && (
                                      <Box
                                        className="form-error"
                                        style={{ marginTop: "10px" }}
                                      >
                                        {uploadErrors.fileUpload}
                                      </Box>
                                    )}
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              setOpenCancelModal(true);
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>

                          <Button
                            className="form-button"
                            variant="contained"
                            type="submit"
                            color="success"
                            disabled={formSubmitted || uploadErrors.fileUpload}
                          >
                            Next
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}

              {formNumber === 4 && (
                <Box
                  className="create-whatsapp-campaign-form-wrapper"
                  style={{ position: "relative" }}
                >
                  <Formik
                    initialValues={{}}
                    // validationSchema={CreateWhatsAppCampaignValidationSchema}
                    onSubmit={handleSubmitFourthForm}
                    enableReinitialize
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="create-whatsapp-campaign-form">
                        {/* {formSubmitted && <LinearProgress />} */}
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={12} md={4}>
                              {!previewData ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Campaign Name :</Typography>
                                  <Typography>
                                    {previewData?.campaignName}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                              {!previewData ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Campaign Type :</Typography>
                                  <Typography
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {previewData?.campaignType}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                              {!previewData ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Tags :</Typography>
                                  <Typography>{previewData?.tag}</Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              {!previewData ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>
                                    Campaign Date & Time :
                                  </Typography>
                                  <Typography>
                                    {new Date(
                                      previewData?.scheduledDateOrTime
                                    ).toLocaleString("en-US", {
                                      timeZone: "Asia/Kolkata",
                                    })}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              {!previewData ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Number of users :</Typography>
                                  <Typography>
                                    {previewData?.numberOfUploads}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              {!previewData ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Template ID :</Typography>
                                  <Typography>
                                    {previewData?.whatsappParams?.templateId}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                              {!previewData ? (
                                <Skeleton
                                  sx={{ height: "200px" }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    border: "1px solid lightgray",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    minHeight: "200px",
                                    background: "white",
                                  }}
                                >
                                  {thirdFormValues?.templatePreview}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              setOpenCancelModal(true);
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>

                          <Button
                            className="form-button"
                            variant="contained"
                            type="submit"
                            color="success"
                          // disabled={formSubmitted || uploadErrors.fileUpload}
                          >
                            Save and Schedule
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </Box>
          </Container>

          <SureYouWantToDeletModal
            modalTitle=""
            dialogBody="Are you sure you want to cancel this campaign?"
            buttonText="Yes"
            openDeleteModal={openCancelModal}
            setOpenDeleteModal={setOpenCancelModal}
            onYesClick={() => {
              cancelCampaign();
            }}
          />
        </Box>
      )}
    </>
  ) : (
    <Unauthorized />
  );
};
export default CreateWhatsapp;
