import "./jobsahayak.scss";
import * as React from 'react';
import { Box, Button, FormControl, Select, MenuItem, Tooltip, CircularProgress, Chip, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequestWithToken, patchRequestWithToken } from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ModalComponent from "../../components/ModalComponent/ModalComponent";

const JobShayak = () => {
  const [jobsahayakDeleted, setJobSahayakDeleted] = useState(false);
  const [jobSahayakName, setJobSahayakName] = useState("");
  const [jobsahayakData, setJobSahayakData] = useState([]);
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [jobSahayakId, setJobSahayakId] = useState("");
  const [jobSahayakEditId, setJobSahayakEditId] = useState("");
  const [userDataArray, setUserDataArray] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isTheDataLoaded, setIsTheDataLoaded] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null)

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [profileStatus, setProfileStatus] = useState('');
  const [jsID, setJsID] = useState(null);


  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [tabName, setTabname] = useState("activeTab");

  const [tabvalue, setTabValue] = React.useState(0);
  const [searchBarValue, setSearchBarValue] = useState("");

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const filteredRows = showDeletedRows
    ? jobsahayakData
    : jobsahayakData.filter(jobsahayak => jobsahayak.hasOwnProperty('deleted') && jobsahayak.deleted.is_deleted === false);

  const createNewJobsahayak = () => {
    navigate('/jobsahayak/create');
  }

  const columns = [
    {
      field: 'serial_no',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'full_name',
      headerName: 'Name',
      width: 200,
      valueGetter: (params) => {
        const { first_name = "", middle_name = "", last_name = "" } = params.row;
        const fullName = `${first_name} ${middle_name} ${last_name}`;
        return fullName;
      },
    },
    {
      field: 'email',
      headerName: 'Email Address',
      width: 300,
    },

    {
      field: 'mobile',
      headerName: 'Mobile Number',
      width: 200,
      // valueGetter: (params) => params.row.employer.city
    },

    {
      field: 'referred_jobsahayak',
      headerName: 'Referred',
      width: 350,
      valueGetter: (params) => {
        const referredJobsahayak = params.row?.referred_jobsahayak;
        if (referredJobsahayak) {
          const { first_name = "", middle_name = "", last_name = "", mobile = "" } = referredJobsahayak;
          const fullName = `${first_name} ${middle_name} ${last_name} | ${mobile}`;
          return fullName;
        } else {
          return "--";
        }
      },
    },

    {
      field: 'samuday_details',
      headerName: 'Samuday Details',
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <table>
              <thead>
                <tr>
                  <th style={{ fontWeight: "400", paddingRight: "10px" }}>Candidates</th>
                  <th style={{ fontWeight: "400", paddingRight: "10px" }}>JSK</th>
                  <th style={{ fontWeight: "400", paddingRight: "10px" }}>JWP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{(params?.row?.usersCount) ? params?.row?.usersCount : "0"}</td>
                  <td>{(params?.row?.jobSevaKedraCount) ? params?.row?.jobSevaKedraCount : "0"}</td>
                  <td>{(params?.row?.jobWahanPartnersCount) ? params?.row?.jobWahanPartnersCount : "0"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      },
    },

    (canUpdate || canCreate) && {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        // console.log(params?.row?.deleted);
        const { id, row: { full_name = "" } } = params;

        return (
          params?.row?.deleted?.is_deleted ? < Box style={{ display: "flex", gap: "2rem" }}>
            {/* <VisibilityIcon /> */}
            {canUpdate ? (<EditIcon color="disabled" />) : ""}
            {canDelete ? (<DeleteIcon color="disabled" />) : ""}
          </Box > : < Box style={{ display: "flex", gap: "2rem" }}>
            {/* <VisibilityIcon /> */}

            {canRead ? (<Tooltip title="View" >
              <VisibilityIcon sx={{ cursor: "pointer" }} color="primary"
                onClick={() => {
                  console.log("Candidate ID:", params.row._id);
                  navigate(`/jobsahayak/detail/${params.row._id}`)
                }}
              />
            </Tooltip>) : ""}
            {canUpdate ? (<Tooltip sx={{ cursor: "pointer" }} title="Edit" >
              <EditIcon onClick={() => {
                let obj = userDataArray.filter((val) => val._id === id)
                navigate("/jobsahayak/create", {
                  state: {
                    obj,
                    userid: id
                  }
                });
              }} color="primary" />
            </Tooltip>) : ""}
            {canDelete && params.row.profile_active !== "pending" ? (<Tooltip sx={{ cursor: "pointer" }} title="Delete">
              <DeleteIcon onClick={() => {
                setOpenDeleteModal(true);
                setJobSahayakId(id);
                setJobSahayakName(full_name);
              }} color="error" />
            </Tooltip>) : ""}

          </Box >
        );
      },

    },
    {
      field: 'isDeleted',
      headerName: 'Deleted',
      width: 100,
      renderCell: (params) => {

        return (<Box display="flex" justifyContent="center" >
          {params?.row?.deleted?.is_deleted && <Chip variant="outlined" label="Deleted" color="error" />}
        </Box>)
      },
    },

  ];

  const requestedColumns = [
    {
      field: 'serial_no',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'full_name',
      headerName: 'Name',
      width: 200,
      valueGetter: (params) => {
        const { first_name = "", middle_name = "", last_name = "" } = params.row;
        const fullName = `${first_name} ${middle_name} ${last_name}`;
        return fullName;
      },
    },
    {
      field: 'email',
      headerName: 'Email Address',
      width: 300,
    },

    {
      field: 'mobile',
      headerName: 'Mobile Number',
      width: 200,
      // valueGetter: (params) => params.row.employer.city
    },

    {
      field: 'referred_jobsahayak',
      headerName: 'Referred',
      width: 300,
      valueGetter: (params) => {
        const referredJobsahayak = params.row?.referred_jobsahayak;
        if (referredJobsahayak) {
          const { first_name = "", middle_name = "", last_name = "", mobile = "" } = referredJobsahayak;
          const fullName = `${first_name} ${middle_name} ${last_name} | ${mobile}`;
          return fullName;
        } else {
          return "--";
        }
      },
    },

    {
      field: 'profile_active',
      headerName: 'Action',
      width: 180,
      renderCell: (params) => {
        const jobsahayakID = params.row._id;
        return (
          <>
            < Box style={{ display: "flex", gap: "2rem", marginRight: "10px" }}>

              {canRead ? (<Tooltip title="View" >
                <VisibilityIcon sx={{ cursor: "pointer" }} color="primary"
                  onClick={() => {
                    console.log("Candidate ID:", params.row._id);
                    navigate(`/jobsahayak/detail/${params.row._id}`)
                  }}
                />
              </Tooltip>) : ""}

            </Box >
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <Select
                value={params.value}
                onChange={(event) => handleChange(event, jobsahayakID)}
                displayEmpty
              >
                <MenuItem value="pending" disabled>Pending</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </>

        );
      }
    },


  ];

  const handleChange = async (event, jobsahayakID) => {
    setProfileStatus(event.target.value);
    setJsID(jobsahayakID);
    if (event.target.value === "active") {
      setOpenTask(true);
    } else {
      executeStateChange(event.target.value, jobsahayakID);
    }
  };

  const executeStateChange = async (status, JsId) => {
    try {
      let response = await patchRequestWithToken(`jobsahayak/profile-active?id=${JsId}&status=${status}`);
      if (response.data.status === "Success") {
        handleSnackBar(true, response.data.msg, "success");
        setOpenTask(false);
        // setShowLoader(true);
        getAllJobSahayak();
      }
      // setLoadingDataInGrid(false);
    }
    catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
    }
  }


  const deleteJobSahayak = async () => {
    try {
      const data = await deleteRequest(`admin?usertype=jobsahayak&&userid=${jobSahayakId}`);
      // console.log(data, " this is data");
      if (data.data.status === "Success") {
        setOpenDeleteModal(false);
        handleSnackBar(true, data.data.data, "success");
        setIsTheDataLoaded(false);
        getAllJobSahayak();
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg, "error");
    }
  }

  const getAllJobSahayak = async () => {
    setLoadingDataInGrid(true);
    try {
      if (tabvalue === 0) {
        let response = await getRequestWithToken(`admin?usertype=jobsahayak&page=${parseInt(pageNumber + 1)}&limit=${pageSize}&search=${searchBarValue}`);
        if (response.data.status === "Success") {
          setRowCount(response.data.totalCount)
          setUserDataArray([...response?.data?.data]);
          setJobSahayakData([...response?.data?.data]);
          setIsTheDataLoaded(true);
        }
        setLoadingDataInGrid(false);
      }
      else if (tabvalue === 1) {
        let response = await getRequestWithToken(`admin?usertype=jobsahayak&page=${parseInt(pageNumber + 1)}&limit=${pageSize}&profile_active=pending&search=${searchBarValue}`);
        if (response.data.status === "Success") {
          setRowCount(response.data.totalCount)
          setUserDataArray([...response?.data?.data]);
          setJobSahayakData([...response?.data?.data]);
          setIsTheDataLoaded(true);
        }
        setLoadingDataInGrid(false);
      }
    }
    catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setIsTheDataLoaded(true);
      setLoadingDataInGrid(false);
    }

  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Job Sahayak";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
    // console.log("resourceToUpdate:", resourceToUpdate);
    // console.log("Can read:", permissions.canRead);
    // console.log("Can create:", permissions.canCreate);
    // console.log("Can delete:", permissions.canDelete);
    // console.log("Can update:", permissions.canUpdate);
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Job Sahayak"))
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  useEffect(() => {
    getAllJobSahayak();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageNumber, pageSize, isIncludesValue, profileStatus, tabvalue, searchBarValue])

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const currentTabSelect = (event, newValue) => {
    setTabValue(newValue);
  };

  const [openTask, setOpenTask] = useState(false);

  const handleCloseTask = () => setOpenTask(false)

  return ((isIncludesValue) ?
    <Box className="jobsahayaks-section">

      < ModalComponent
        openModal={openTask}
        setOpenModal={setOpenTask}
        modalTitle={`${profileStatus?.charAt(0)?.toUpperCase() + profileStatus.slice(1)} Jobsahayak`}
        modalWidth="35%"
        handleOnCloseBtnClick={handleCloseTask}
      >
        <Box style={{ padding: "15px" }}>
          <p>Do you want to {profileStatus?.charAt(0)?.toUpperCase() + profileStatus.slice(1)} Jobsahayak?</p>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" color="error" style={{ marginRight: "10px" }} onClick={handleCloseTask}>No</Button>
            <Button variant="contained" color="success" onClick={() => executeStateChange(profileStatus, jsID)}>Yes</Button>
          </Box>

        </Box>
      </ModalComponent >

      {/* <SearchBar placeholder="Search by name, email address, phone number and pincode"
        searchBarValue={searchBarValue} setSearchBarValue={setSearchBarValue} /> */}
      <Box className="jobsahayaks-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        {canCreate ? (<Button variant="contained" color="success" onClick={createNewJobsahayak}>
          Create New Jobsahayak
        </Button>) : ""}

        {/* <FormGroup>
          <FormControlLabel control={<Switch checked={showDeletedRows} onChange={handleToggleChange} />} label="Include Deleted Users" labelPlacement="start" />
        </FormGroup> */}

      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabvalue} onChange={currentTabSelect} aria-label="basic tabs example">
            <Tab label="All Jobsahayak" style={{ fontWeight: "700" }}  {...a11yProps(0)} />
            <Tab label="Pending JobSahayak Request" style={{ fontWeight: "700" }}  {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabvalue} index={0}>
          <Box className="jobsahayaks-data">
            {isTheDataLoaded ?
              <DataGrid
                rows={filteredRows}
                columns={columns}
                getRowId={(candidateData) => candidateData._id}
                paginationMode="server"
                pagination
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                page={pageNumber}
                pageSize={pageSize}
                onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
                onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
                loading={loadingDataInGrid}
                rowCount={rowCount}
              /> : <Box sx={{ width: "100%", height: "100%" }} display="flex" justifyContent="center" alignItems="center" > <CircularProgress /> </Box>}
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={tabvalue} index={1}>
          <Box className="jobsahayaks-data">
            {isTheDataLoaded ?
              <DataGrid
                rows={filteredRows}
                columns={requestedColumns}
                getRowId={(candidateData) => candidateData._id}
                paginationMode="server"
                pagination
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                page={pageNumber}
                pageSize={pageSize}
                onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
                onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
                loading={loadingDataInGrid}
                rowCount={rowCount}
              /> : <Box sx={{ width: "100%", height: "100%" }} display="flex" justifyContent="center" alignItems="center" > <CircularProgress /> </Box>}
          </Box>
        </CustomTabPanel>
      </Box>


      <Box>
        <SureYouWantToDeletModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          modalTitle=""
          dialogBody={`Are you sure you want to delete Job Sahayak  ${jobSahayakName?.charAt(0)?.toUpperCase() + jobSahayakName?.slice(1, jobSahayakName?.length)} from your records.`}
          onYesClick={() => {
            deleteJobSahayak();
          }}
        />
      </Box>
    </Box> : (<Unauthorized />)
  )
}
export default JobShayak;
