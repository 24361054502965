export const getURL = () => {
  let api_url;
  api_url = import.meta.env.VITE_API_BASE_URL;
  return api_url;
};

export const TimeDisplay = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are 0-based, so add 1
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const formatScheduleDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}T`;
};
export const formatTimeTo24Hour = (time) => {
  const date = new Date(time.$d);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}Z`;
};
export const formatDate = (commentedAt) => {
  if (!commentedAt) return "";
  let date = new Date(commentedAt);
  const optionsDate = { day: "2-digit", month: "long", year: "numeric" };
  const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: false };

  const formattedDate = date?.toLocaleDateString("en-GB", optionsDate);
  const formattedTime = date?.toLocaleTimeString("en-GB", optionsTime);

  return `${formattedDate} ${formattedTime}`;
};

export const convertToUTC = (timeString) => {
  let date = new Date(timeString);
  const utcDate = new Date(date.toUTCString());
  if (isNaN(utcDate.getTime())) {
    throw new Error("Invalid date format");
  }

  const utcString = utcDate.toISOString();
  return utcString;
};


export const downloadFile = async (fileUrl, fileName) => {
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download failed", error);
    alert("Failed to download PDF");
  }
};