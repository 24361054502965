import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import './backBtn.scss'

const BackBtn = () => {
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <ArrowBack className="backBtn" onClick={handleBackClick} />
  );
};

export default BackBtn;