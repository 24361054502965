import "./jobsection.scss";
import { Box, Chip } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import {
  CircularProgress,
  Button,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import { getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../../utils/functions";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

const RejectedJobs = () => {
  const [rejectedJobData, setRejectedJobData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [isTheDataLoaded, setIsTheDataLoaded] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [activeData, setActiveData] = useState(null);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);


  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);



  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: []
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No.',
      size: 100,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + (pageIndex * pageSize)}</span>;
      },
    },
    {
      accessorKey: 'jobId',
      header: 'Job Id',
      size: 160,
    },
    {
      accessorKey: 'jobRoleNameEn',
      header: 'Job Title',
      size: 250,
      Cell: ({ renderedCellValue }) => (
        <div style={{ textTransform: "capitalize" }}>{renderedCellValue}</div>
      ),
      id: 'jobTitle'
    },
    {
      accessorKey: "job description",
      header: "Job Description",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const openDescription = () => {
          window.open(`/posted-jobs/${row.original._id}`, "_blank")
        };
        return (
          <span
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={openDescription}
          >
            view
          </span>
        );
      },
    },
    {
      accessorKey: 'state',
      header: 'State',
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.state,
    },
    {
      accessorKey: 'city',
      header: 'City',
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.city,
    },
    {
      accessorKey: 'date_and_time',
      header: 'Date & Time',
      enableColumnFilter: false,
      size: 200,
      Cell: ({ row }) => {
        const createdAt = row.original.createdAt || "--";
        return (
          <span>
            {new Date(createdAt).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
            })}
          </span>
        );
      },
    },
    {
      accessorKey: 'company_name',
      header: 'Company Name',
      size: 500,
      accessorFn: (row) => row.companyName,
      id: 'companyName'
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 200,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        canRead && (
          <Button
            variant="contained"
            className="newBtns"
            color="primary"
            disabled={!row.original.comments}
            onClick={() => {
              setOpenChat(true);
              setActiveData(row.original);
            }}
          >
            View Comment
          </Button>
        )
      ),
    },
  ];

  const getRejectedJobs = async () => {
    if (!rejectedJobData.length) {
      setLoadingDataInGrid(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const filterParams = {
        ...Object.fromEntries(
          columnFilters.map((filter) => [filter.id, filter.value])
        ),
      };

      const filterQueryString = new URLSearchParams(filterParams).toString();

      const response = await getRequestWithToken(
        `/admin/rejected-job?isrejectedbyadmin=true&sortBy=-updatedAt&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&${filterQueryString}`
      );

      setRejectedJobData(response?.data?.data);
      setRowCount(response?.data?.totalcounts);
      setIsError(false);
      setLoadingDataInGrid(false);
      setShowLoader(false);
      setIsTheDataLoaded(true);
    } catch (error) {
      setIsError(true);
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setLoadingDataInGrid(false);
      setShowLoader(false);
      setIsTheDataLoaded(false); // Update to handle the loading state properly on error
    } finally {
      setLoadingDataInGrid(false);
      setIsRefetching(false);
      setShowLoader(false);
    }
  };

  const closeChat = () => {
    setOpenChat(false);
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "All Jobs";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Rejected Jobs"))
  }

  useEffect(() => {
    // getRejectedJobs();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  useEffect(() => {
    getRejectedJobs();
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const table = useMaterialReactTable({
    columns,
    data: rejectedJobData,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableSorting: false,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: "#ff671f", // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "No Data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return ((isIncludesValue) ?
    <Box className="rejected-jobs-section">
      <Typography className="section-title">Rejected Jobs</Typography>
      <Box className="rejected-jobs-data">
        {isTheDataLoaded ? (<MaterialReactTable table={table} />) : (
          <Box
            sx={{ width: "100%", height: "100%" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {openChat && (
        <ClickAwayListener onClickAway={closeChat}>
          <Box className="chat-box">
            <Box className="inner-wrapper">
              <Typography className="chat-title">Appeal</Typography>
              <CloseIcon className="close-icon" onClick={closeChat} />
              <Box className="admin-message">
                <Typography className="title">Admin</Typography>
                <Typography className="time">
                  {formatDate(
                    activeData?.comments?.adminComment?.[0]?.commentedAt
                  )}
                </Typography>
                <Typography className="message">
                  {activeData?.comments?.adminComment?.[0]?.comment}
                </Typography>
              </Box>
              <Box className="appeal-message">
                <Typography className="title">
                  {activeData?.aboutOrganisation?.organisationName || ""}
                </Typography>
                <Typography className="time">
                  {formatDate(
                    activeData?.comments?.employerComment?.[0]?.commentedAt
                  ) || ""}
                </Typography>
                <Typography className="message">
                  {activeData?.comments?.employerComment?.[0]?.comment ||
                    "No Comments till now"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </Box> : (<Unauthorized />)
  );
};

export default RejectedJobs;
