import { Box, Button, Snackbar, Alert, MenuItem, FormControl, Select, Container, Grid, Typography, InputLabel, Autocomplete, TextField, Switch } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import "./recruiter.scss";
import { useContext, useEffect, useState, useRef } from 'react';
import { CreateEmployerValidationSchema, CreateRecruiterClientValidationSchema, UpdateEmployerValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { useDropzone } from "react-dropzone";
import NorthIcon from '@mui/icons-material/North';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";



const AddClient = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const [dataById, setDataById] = useState([]);
  const [brandLogoIs, setBrandLogoIs] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [fileImg, setFileImg] = useState(null);
  const [imageUploader, setImageUploader] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [sector, setSector] = useState([]);
  const [imageUpload, setImageUpload] = useState('');
  const [pincodeLoader, setPincodeLoader] = useState(false);
  const [recruiterId, setRecruiterId] = useState(null);

  const [uploadImage, setUploadImage] = useState([]);




  const [initialValues, setInitialValues] = useState({
    userid: "",
    usertype: "",
    employerName: "",
    employerId: "",
    organizationName: "",
    tradeOrBrandName: "",
    numberOfFullTimeEmployees: "",
    officeLocationPresence: "",
    headQuartersAddress: "",
    pincode: "",
    city: "",
    state: "",
    organizationSector: "",
    image: '',
    showBrandName: false
  })
  const [edit, setEdit] = useState(false);
  const inputRefs = {};

  const scrollToErrorField = (fieldName) => {
    if (inputRefs[fieldName]) {
      inputRefs[fieldName].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true);
    const emplData = JSON.parse(localStorage.getItem('EmpData'));

    let formData = new FormData();

    if (edit) {

      const appendToFormData = (key, value) => {
        formData.append(key, value === null || value === undefined ? "" : value);
      };

      appendToFormData("userid", location?.state?._id);
      appendToFormData("usertype", "client");
      appendToFormData("employerName", emplData?.employer?.first_name);
      appendToFormData("employerId", emplData?._id);
      appendToFormData("organizationName", values.organizationName);
      appendToFormData("tradeOrBrandName", values.tradeOrBrandName);
      appendToFormData("numberOfFullTimeEmployees", values.numberOfFullTimeEmployees);
      appendToFormData("officeLocationPresence", values.officeLocationPresence);
      appendToFormData("headQuartersAddress", values.headQuartersAddress);
      appendToFormData("pincode", values.pincode);
      appendToFormData("city", values.city);
      appendToFormData("state", values.state);
      formData.append("organizationSector", values?.organizationSector?._id ? values?.organizationSector?._id : dataById.organizationSector);
      formData.append("showBrandName", values.showBrandName);
      // formData.append("image", null);

      if (uploadImage) {
        formData.append("image", uploadImage);
      } else {
        formData.append("image", null);
      }


    } else {
      formData.append("usertype", "client");
      formData.append("employerName", emplData?.employer?.first_name);
      formData.append("employerId", emplData?._id);
      formData.append("organizationName", values.organizationName);
      formData.append("tradeOrBrandName", values.tradeOrBrandName);
      formData.append("numberOfFullTimeEmployees", values.numberOfFullTimeEmployees);
      formData.append("officeLocationPresence", values.officeLocationPresence);
      formData.append("headQuartersAddress", values.headQuartersAddress);
      formData.append("pincode", values.pincode);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("organizationSector", values?.organizationSector?._id);
      formData.append("showBrandName", values.showBrandName);
      if (uploadImage) {
        formData.append("image", uploadImage);
      }
    }

    try {
      let res = edit ?
        await patchRequestWithToken('admin', formData) : await postRequest('admin', formData);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!edit) {
          resetForm();
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          getEmployerById()
          // setTimeout(() => {
          //   navigate(-1);
          // }, 1000);
        }
      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      console.log(err);
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }
  }

  const fetchPincodeDetails = async (pincode, setFieldValue) => {
    setPincodeLoader(true);
    try {
      // console.log(pincode)
      let response = await getRequest(`pincode/${pincode}`);
      // console.log(response)
      const data = response.data.data;
      setFieldValue('district', data.Districtname);
      setFieldValue('state', data.statename);
      setFieldValue('city', data.circlename);
      setPincodeLoader(false);
    } catch (err) {
      console.log(err)
      setPincodeLoader(false);

    }
  }

  const getEmployerById = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid/?usertype=client&userid=${location.state?._id}`);
      setDataById(response.data.data)

      setImageUpload(response.data.data.brandLogo)

      // setFieldValue('organizationSector', newValue);

      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Employer";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Employer"))
  }

  const getSectors = async () => {
    try {
      let data = await getRequestWithToken("admin/sectors");
      if (data.data.status === "Success") {
        setSector([...data.data.data]);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
    }
  };

  useEffect(() => {
    getSectors();
    checkPermissionFunc();
    againCheckPermissions();

    const valFromLocalStorage = JSON.parse(localStorage.getItem('EmpData'));
    setRecruiterId(valFromLocalStorage?.employerId);

    if (location.state?._id) {
      getEmployerById()
    }
    else {
      setShowLoader(false);
    }


    if (location?.state?._id) {
      // console.log("dataById2 : ", dataById)
      const { employer = {} } = dataById;
      setFileImg(dataById?.brandLogo);
      const {
        userid = "",
        usertype = "",
        employerName = dataById?.employerName,
        employerId = dataById?.employerId,
        organizationName = dataById?.organizationName,
        tradeOrBrandName = dataById?.tradeOrBrandName,
        numberOfFullTimeEmployees = dataById?.numberOfFullTimeEmployees,
        officeLocationPresence = dataById?.officeLocationPresence,
        headQuartersAddress = dataById?.headQuartersAddress,
        pincode = dataById?.pincode,
        city = dataById?.city,
        state = dataById?.state,
        organizationSector = dataById?.organizationSector,
        showBrandName = dataById?.showBrandName
      } = employer;

      const {firstName,middleName,lastName} = dataById?.employerId || {};
      const employerFullName = [
        firstName,
        middleName,
        lastName,
      ]
        .filter(Boolean)
        .join(' ');

      setInitialValues({
        userid: userid,
        usertype: usertype,
        employerName: employerFullName,
        employerId: employerId,
        organizationName: organizationName,
        tradeOrBrandName: tradeOrBrandName,
        numberOfFullTimeEmployees: numberOfFullTimeEmployees,
        officeLocationPresence: officeLocationPresence,
        headQuartersAddress: headQuartersAddress,
        pincode: pincode,
        city: city,
        state: state,
        organizationSector: organizationSector,
        showBrandName: showBrandName
      });
      setEdit(true);
    } else {
      const employer_full_name = [
        valFromLocalStorage?.employer?.first_name,
        valFromLocalStorage?.employer?.middle_name,
        valFromLocalStorage?.employer?.last_name,
      ]
        .filter(Boolean)
        .join(' ');
      setInitialValues({
        userid: "",
        usertype: "",
        employerName: employer_full_name,
        employerId: valFromLocalStorage?._id,
        organizationName: "",
        tradeOrBrandName: "",
        numberOfFullTimeEmployees: "",
        officeLocationPresence: "",
        headQuartersAddress: "",
        pincode: "",
        city: "",
        state: "",
        organizationSector: "",
        showBrandName: false
      });
      setEdit(false);
    }
  }, [location.state, dataById?._id, isIncludesValue])


  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const onDrop = async (acceptedFiles) => {

    const oversizedFiles = acceptedFiles.filter(file => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      handleSnackBar(true, "File size exceeds the maximum limit of 2 MB", "error")
      return;
    }
    setFileData(acceptedFiles)
    // setBrandLogoIs(acceptedFiles[0])

    acceptedFiles.forEach((file, index) => {

      let url = URL.createObjectURL(file);
      if (index === 0)
        setFileImg(url)
    })

    setUploadImage(acceptedFiles[0]);

  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-employer-section" ref={scrollTargetRef}>
        {formSubmitted && <LinearProgress
          style={{
            width: "100%",
            position: "absolute",
            left: "0",
            top: "66px",
            zIndex: "999",
          }}
        />}
        <Container>
          <Box className="create-employer">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings'>{edit ? "Update Client" : "Create Client"}</h1>
            </Box>
            <Box className="create-employer-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CreateRecruiterClientValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-employer-form ">

                    <Box className="backgroundStyle">
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="employerName"> Recruiter Name  <span className='must-fill-fields'>*</span></label>
                            <Field innerRef={(el) => { inputRefs.employerName = el }} id="employerName"
                              type="text" placeholder="Recruiter Name"
                              name="employerName"
                              disabled
                              className="form-input" />
                            {errors.employerName && <Box className="form-error">{errors.employerName}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="employerId"> Recruiter ID  <span className='must-fill-fields'>*</span></label>
                            <Typography className='sameAsInput'>{recruiterId}</Typography>
                            {/* <Field disabled innerRef={(el) => { inputRefs.employerId = el }} id="employerId"
                              type="text" placeholder="Recruiter ID"
                              name="employerId"
                              className="form-input" /> */}
                            {errors.employerId && <Box className="form-error">{errors.employerId}</Box>}
                          </Box>
                        </Grid>

                      </Grid>
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="organizationName"> Organisation Name of Client <span className='must-fill-fields'>*</span></label>
                            <Field innerRef={(el) => { inputRefs.organizationName = el }} id="organizationName"
                              type="text" placeholder="Enter Organisation Name"
                              name="organizationName"
                              className="form-input" />
                            {errors.organizationName && <Box className="form-error">{errors.organizationName}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="tradeOrBrandName"> Trade/Brand Name of Client <span className='must-fill-fields'>*</span></label>
                            <Field innerRef={(el) => { inputRefs.tradeOrBrandName = el }} id="tradeOrBrandName"
                              type="text" placeholder="Enter Trade/Brand Name "
                              name="tradeOrBrandName"
                              className="form-input" />
                            {errors.tradeOrBrandName && <Box className="form-error">{errors.tradeOrBrandName}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="numberOfFullTimeEmployees">Number of Full Time Employee </label>
                            <FormControl name="numberOfFullTimeEmployees" fullWidth size="small">
                              {values.numberOfFullTimeEmployees === "" && <InputLabel shrink={false}>Select Employees no. </InputLabel>}
                              <Select
                                style={{ backgroundColor: "white" }}
                                placeholder="Select Document"
                                name="numberOfFullTimeEmployees"
                                id="numberOfFullTimeEmployees"
                                value={values.numberOfFullTimeEmployees}
                                onChange={(event) => {
                                  const selectedEmployeesNumber = event.target.value;
                                  setFieldValue("numberOfFullTimeEmployees", selectedEmployeesNumber);
                                }}
                              >
                                {/* <MenuItem value="0">NA</MenuItem> */}
                                <MenuItem value="1">0-10</MenuItem>
                                <MenuItem value="2">11-100</MenuItem>
                                <MenuItem value="3">101-1000</MenuItem>
                                <MenuItem value="4">More than 1000</MenuItem>
                              </Select>
                            </FormControl>

                            {errors.numberOfFullTimeEmployees && <Box className="form-error">{errors.numberOfFullTimeEmployees}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="officeLocationPresence">Office Work Location Present </label>
                            <FormControl name="officeLocationPresence" fullWidth size="small">
                              {values.officeLocationPresence === "" && <InputLabel shrink={false}>Select Work Location</InputLabel>}
                              <Select
                                style={{ backgroundColor: "white" }}
                                placeholder="Select Document"
                                name="officeLocationPresence"
                                id="officeLocationPresence"
                                value={values.officeLocationPresence}
                                onChange={(event) => {
                                  const selectedGenderValue = event.target.value;
                                  setFieldValue("officeLocationPresence", selectedGenderValue);
                                }}
                              >
                                {/* <MenuItem value="0">NA</MenuItem> */}
                                <MenuItem value="1">Only 1 City</MenuItem>
                                <MenuItem value="2">1-5 City</MenuItem>
                                <MenuItem value="3">More than 5 Cities</MenuItem>
                              </Select>
                            </FormControl>

                            {errors.officeLocationPresence && <Box className="form-error">{errors.officeLocationPresence}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="organizationSector">Company Sector <span className='must-fill-fields'>*</span></label>
                            <Autocomplete
                              style={{ backgroundColor: "white" }}
                              id="tags-outlined"
                              options={sector || []}
                              getOptionLabel={(option) => option.sectorNameEn}
                              filterSelectedOptions
                              value={
                                edit
                                  ? (values.organizationSector?._id
                                    ? (sector.find((option) => option._id === values.organizationSector?._id) || null)
                                    : (sector.find((option) => option._id === dataById.organizationSector) || null))
                                  : (sector.find((option) => option._id === values.organizationSector?._id) || null)}
                              onChange={(event, newValue) => {
                                setFieldValue('organizationSector', newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Sector"
                                />
                              )}
                            />
                            {errors.organizationSector && <Box className="form-error">{errors.organizationSector}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="showBrandName">Show Company's public name when posting jobs </label>
                            <Switch
                              checked={values.showBrandName}
                              onChange={(e) => setFieldValue('showBrandName', e.target.checked)}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="headQuartersAddress">Address </label>
                            <Field
                              innerRef={(el) => { inputRefs.headQuartersAddress = el }}

                              id="headQuartersAddress"

                              type="text" placeholder="Enter Address"
                              name="headQuartersAddress"
                              className="form-input" />
                            {errors.headQuartersAddress && <Box className="form-error">{errors.headQuartersAddress}</Box>}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="pincode"> Pincode  {pincodeLoader && <CircularProgress className="pincodeLoader" />}</label>
                            <Field id="pincode"
                              type="text" placeholder="Enter pincode"
                              name="pincode"
                              className="form-input"
                              onBlur={(e) => fetchPincodeDetails(e.target.value, setFieldValue)}
                            // onChange={getPincode}
                            />
                            {errors.pincode && <Box className="form-error">{errors.pincode}</Box>}
                          </Box>
                        </Grid>


                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="city">City </label>
                            <Field id="city"
                              type="text" placeholder="Enter city"
                              name="city"
                              className="form-input" />
                            {errors.city && <Box className="form-error">{errors.city}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="state">State </label>
                            <Field disabled={edit} id="state"
                              type="text" placeholder="Enter state"
                              name="state"
                              className="form-input" />
                            {errors.state && <Box className="form-error">{errors.state}</Box>}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="mt-5 px-4 py-3">

                          <label className="form-label" htmlFor="image"> Upload your Business Logo</label>

                          <Grid justifyContent="center" className='' style={{ border: "1px solid #e4e3e3", borderRadius: "5px", backgroundColor: "white" }} item xs={12}>
                            <Box display="flex" className="cursor-pointer items-center flex-col" style={{ position: "relative" }} justifyContent="center" width="100%">
                              {<Box {...getRootProps({ className: 'dropzone' })} className="" style={{ textAlign: "center", minHeight: "180px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                {fileImg !== null && <img style={{ height: "100%", width: "100%", display: "block", objectFit: "cover", maxHeight: "180px" }} src={fileImg} alt="selected png, svg" />}

                                {fileImg === null && <>
                                  <input {...getInputProps()} />
                                  <NorthIcon color="text-secondary-black" fontSize="small" />
                                  <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                    Upload Here
                                  </Typography>
                                  <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                    File types supported: jpg, jpeg, png
                                  </Typography>
                                  <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                    Maximum file size: 2 MB
                                  </Typography></>}
                              </Box>}

                              {imageUploader && <Box className="loaderClass" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px' }} >
                                <CircularProgress />
                              </Box>}
                            </Box>

                            {errors.businessLogo && <Typography fontSize="14px" color="#d32f2f" marginTop={2} textAlign="center">{errors.businessLogo.message}</Typography>}
                          </Grid>
                          <Typography onClick={() => { setFileImg(null); setUploadImage([]) }} style={{ color: "#ff671f", cursor: "pointer" }}>Change</Typography>
                        </Box>
                      </Grid>

                      {/* <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="mt-5 px-4 py-3">
                            <label className="form-label" htmlFor="city"> Upload your Business Logo</label>
                            <Grid container justifyContent={'space-between'}>
                              {(imageUpload != null) && <Grid justifyContent="center" className='' item xs={5}>
                                <img style={{ height: "100%", width: "100%", display: "block", objectFit: "cover", maxHeight: "180px", border: "1px solid #e4e3e3", borderRadius: "5px", backgroundColor: "white" }} src={imageUpload} alt="selected png, svg" />
                                <Typography onClick={() => { setImageUpload(null); }} style={{ color: "orange", fontWeight: "bold", cursor: "pointer" }}>Delete</Typography>
                              </Grid>}
                              <Grid justifyContent="center" className='' style={{ border: "1px solid #e4e3e3", borderRadius: "5px", backgroundColor: "white" }} item xs={6}>
                                <Box display="flex" className="cursor-pointer items-center flex-col" style={{ position: "relative" }} justifyContent="center" width="100%">
                                  {<Box {...getRootProps({ className: 'dropzone' })} className="" style={{ textAlign: "center", minHeight: "180px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    {fileImg.length > 0 && <img style={{ height: "100%", width: "100%", display: "block", objectFit: "cover", maxHeight: "180px" }} src={fileImg} alt="selected png, svg" />}

                                    {fileImg.length === 0 && <>
                                      <input {...getInputProps()} />
                                      <NorthIcon color="text-secondary-black" fontSize="small" />
                                      <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                        Upload Here
                                      </Typography>
                                      <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                        File types supported: jpg, jpeg, png
                                      </Typography>
                                      <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                        Maximum file size: 2 MB
                                      </Typography></>}
                                  </Box>}

                                  {imageUploader && <Box className="loaderClass" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px' }} >
                                    <CircularProgress />
                                  </Box>}
                                </Box>

                                {errors.businessLogo && <Typography fontSize="14px" color="#d32f2f" marginTop={2} textAlign="center">{errors.businessLogo.message}</Typography>}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid> */}
                    </Box>

                    <Box className="form-button-section">
                      <Link to='/employer'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}
                      >{edit ? "Update" : "Create"}</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>

      </Box >}
    </> : (<Unauthorized />)

  )

}
export default AddClient;