import React, { useContext, useEffect, useState } from 'react';

import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Chip, Container, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { getRequestWithToken, getRequestForResume } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { downloadFile } from "../../utils/functions";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";

const PostedJobs = () => {

  const { jobId } = useParams();
  const [jobDetailsData, setJobDetailsData] = useState([]);
  const [employerData, setEmployerData] = useState();
  // const [jobDescription, setJobDescription] = useState();
  const [loadingDataInCandidateTable, setLoadingDataInCandidateTable] = useState();
  const [obCandidateData, setjobCandidateData] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderEmp, setShowLoaderEmp] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  // const [isRefetching, setIsRefetching] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [downloadingResume, setDownloadingResume] = useState(false);


  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // const [canCreate, setCanCreate] = useState(false);
  // const [canDelete, setCanDelete] = useState(false);
  // const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);


  const getJobDetails = async () => {
    try {
      setShowLoader(true); // Ensure loader is shown during fetch
      const response = await getRequestWithToken(`admin/employer/job-details?jobId=${jobId}`);

      const jobData = response?.data?.data || {};
      setJobDetailsData(jobData);

      if (jobData?.employerId) {
        getEmployerDetails(jobData.employerId);
      }

      // const description = jobData?.jobDescription?.description?.[0]?.description || "";
      // setJobDescription(description);

      // console.log("Fetched description:", description);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
    }
  };


  const getEmployerDetails = async (employerId) => {
    setShowLoaderEmp(true);
    try {
      let response = await getRequestWithToken(`admin/userbyid?usertype=employer&userid=${employerId}`);
      setEmployerData(response.data.data);
      // console.log("response : " + JSON.stringify(response.data.data))
      setShowLoaderEmp(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setShowLoaderEmp(false);
    }
  };

  useEffect(() => {
    getJobDetails();
  }, [jobId]);


  const getJobCandidatesDetails = async () => {
    setLoadingDataInCandidateTable(true);
    try {

      const filterParams = {
        // global: globalFilter,
        ...Object.fromEntries(
          columnFilters.map((filter) => [filter.id, filter.value])
        ),
      };
      const filterQueryString = new URLSearchParams(filterParams).toString();

      let response = await getRequestWithToken(`admin/employer/job-candidates?page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&jobId=${jobId}&${filterQueryString}`);
      setjobCandidateData(response.data.data);
      setRowCount(response?.data?.totalCount);
      // console.log("response : " + JSON.stringify(response.data.data))
      setLoadingDataInCandidateTable(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setLoadingDataInCandidateTable(false);
    }
  };

  useEffect(() => {
    getJobCandidatesDetails();
  }, [jobId,
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const DownloadResume = async (jobId, candidateId, candidateName) => {
    setDownloadingResume(true);
    try {
      let response = await getRequestForResume(
        `admin/resume/download?candidateid=${candidateId}&jobid=${jobId}`
      );
      downloadFile(response, candidateName)
      setDownloadingResume(false);
    } catch (error) {
      console.log(error)
      setDownloadingResume(false);

    }

  }

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 120,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + pageIndex * pageSize}</span>;
      },
    },
    { accessorKey: "candidateId", header: "Candidate ID", accessorFn: (row) => row.candidate?.candidate?.candidate_id ?? "---", },
    {
      accessorKey: "name", header: "Name",
      enableColumnFilter: false,
      accessorFn: (row) => {
        const { first_name, middle_name, last_name } = row.candidate?.candidate || {};
        return (
          [first_name, middle_name, last_name]
            .filter(Boolean)
            .join(" ")
            .trim() || ""
        );
      },
    },
    { accessorKey: "mobile", header: "Mobile Number", accessorFn: (row) => row.candidate?.candidate?.mobile ?? "---", },
    {
      accessorKey: "city", header: "City/State",
      enableColumnFilter: false,
      accessorFn: (row) => {
        const { state, district } = row.candidate?.candidate || {};
        return (
          [district, state]
            .filter(Boolean)
            .join(", ")
            .trim() || ""
        );
      },
    },

    {
      accessorKey: "reRankScore",
      enableColumnFilter: false,
      header: "Matching %",
      accessorFn: (row) => {
        const { reRankScore, recommendationScore } = row || {};
        if (reRankScore != null) {
          return reRankScore;
        }
        if (recommendationScore && !isNaN(Number(recommendationScore))) {
          return `${Number(recommendationScore).toFixed(2)}%`;
        }
        return "--";
      },
    },

    { accessorKey: "status", header: "Status" },
    {
      accessorKey: "last_login", header: "Last Active",
      enableColumnFilter: false,
      accessorFn: (row) => {
        const { last_login } = row.candidate?.candidate || {};
        return (
          new Date(last_login).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })
        );
      },
    },

    {
      accessorKey: "z_action",
      header: "Action",
      size: 180,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <Box style={{ display: "flex", gap: "2rem" }}>

          <div
            style={{
              cursor: canRead ? "pointer" : "not-allowed",
              color: "blue",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
            disabled={downloadingResume}
            onClick={() => {
              if (!canRead) return;
              const { first_name, middle_name, last_name } = row?.original?.candidate?.candidate || {};
              let candidateName = [first_name, middle_name, last_name]
                .filter(Boolean)
                .join(" ")
                .trim() || ""
              DownloadResume(jobId, row?.original?.candidate?._id, candidateName)
            }}
          >
            Download Resume
          </div>

        </Box>
      ),
    },
  ];


  const table = useMaterialReactTable({
    columns,
    data: obCandidateData,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableSorting: false,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: "#ff671f", // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    // muiToolbarAlertBannerProps: isError
    //   ? {
    //     color: "error",
    //     children: "No Data",
    //   }
    //   : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInCandidateTable,
      pagination,
      // showAlertBanner: isError,
      // showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      console.log("Total Pages: ", totalPages, rowCount, pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} {totalPages ? (`of ` + totalPages) : ''}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  const checkPermissionFunc = () => {
    const resourceToUpdate = "All Jobs";
    const permissions = checkPermission(resourceToUpdate);
    // setCanCreate(permissions.canCreate);
    // setCanDelete(permissions.canDelete);
    // setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("All Jobs"));
  };

  useEffect(() => {
    // getAllJobs();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);


  return isIncludesValue ? (

    (showLoader) ? <ReusableLoader /> : <Box className="create-candidates-section">

      <Container>

        <Box className="create-candidates">
          {/* <Box className="headingPlusBackBtn">
            <BackBtn />
          </Box> */}
          <Box className="create-candidates-form-wrapper">

            <Box>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography variant="h7" component="h4">Job Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="backgroundStyle">
                    <Grid container spacing={2} className='cstmFormGridEmployer'>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <Typography variant="h7" component='h5'  >Job ID:</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobDetailsData?.jobId}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <Typography variant="h7" component='h5'  >JOB Role</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobDetailsData?.jobRoleNameEn}</Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    {jobDetailsData?.jobDescription?.description?.[0]?.description
                      ? parse(jobDetailsData.jobDescription.description[0].description)
                      : "Loading..."}

                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h7" component="h4">Employer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {(showLoaderEmp) ? <ReusableLoader /> : <Box className="backgroundStyle">
                    <Box className='marginBottom10'>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5'> First Name</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.firstName ? employerData?.firstName : '--'}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5'> Middle Name</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.middleName ? employerData?.middleName : '--'}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5' >Last Name</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.lastName ? employerData?.lastName : '--'}</Typography>
                          </Box>
                        </Grid>

                      </Grid>
                    </Box>

                    <Box className='marginBottom10'>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5'>Mobile Number</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.phone ? employerData?.phone : '--'}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5'>Email Address</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.email ? employerData?.email : '--'}</Typography>
                          </Box>
                        </Grid>

                      </Grid>
                    </Box>

                    <Box className='marginBottom10'>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5'  >Company Name</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.companyName ? employerData?.companyName : '--'}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5' > Address</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.fullAddress ? employerData?.companyAddress?.fullAddress : '--'}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5' > Pincode</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.pincode ? employerData?.companyAddress?.pincode : '--'}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5' >City</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.city ? employerData?.companyAddress?.city : '--'}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5' >State</Typography>
                            <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.state ? employerData?.companyAddress?.state : '--'}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {employerData?.businessLogo && <Grid container spacing={2} className='cstmFormGridEmployer' style={{ marginTop: '5px' }}>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <Typography variant="h7" component='h5' >BusinessLogo</Typography>
                            {/* <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.state}</Typography> */}
                            <img src={employerData?.businessLogo} width={250} style={{ borderRadius: "10px" }} alt="" />
                          </Box>
                        </Grid>

                      </Grid>}
                    </Box>
                  </Box>}
                </AccordionDetails>
              </Accordion>


              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography variant="h7" component="h4">Applicant Summary</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="backgroundStyle">
                    <Grid container spacing={2} className='cstmFormGridEmployer'>

                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box className="form-control">
                          <Typography variant="h7" component='h5'  >Total Applicant</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobDetailsData?.applicantSummary?.totalApplicants}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box className="form-control">
                          <Typography variant="h7" component='h5'  >Contacted</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobDetailsData?.contactedCount}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box className="form-control">
                          <Typography variant="h7" component='h5'  >Shortlisted</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobDetailsData?.applicantSummary?.shortlisted}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box className="form-control">
                          <Typography variant="h7" component='h5'  >Hired</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobDetailsData?.jobDescription?.numberOfHired ?? '0'}</Typography>
                        </Box>
                      </Grid>

                    </Grid>

                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography variant="h7" component="h4">Candidates</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <MaterialReactTable
                    data={staticData}
                    columns={columns}
                    rowCount={staticData.length}
                    isLoading={false}
                    enablePagination={false}
                  /> */}

                  <MaterialReactTable table={table} />
                </AccordionDetails>
              </Accordion>

            </Box>

          </Box>
        </Box>
      </Container >

    </Box >

  ) : <Unauthorized />;
};

export default PostedJobs;
