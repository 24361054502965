import { Box, Button, Container, Grid, Typography, Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { useContext, useEffect, useState, useRef } from 'react';
import { CreateJobLocationValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
// import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import CircularProgress from '@mui/material/CircularProgress';
import RichTextEditor from 'react-rte';

const CreateLocations = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const [dataById, setDataById] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  // const [canCreate, setCanCreate] = useState(true);
  // const [canDelete, setCanDelete] = useState(true);
  // const [canUpdate, setCanUpdate] = useState(true);
  // const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  const [isIncludesValue, setisIncludesValue] = useState(true);
  const [contentError, setContentError] = useState(false);

  const [sector, setSector] = useState([]);
  // const [fetchingPincode, setfetchingPincode] = useState(false);
  const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());


  const [initialValues, setInitialValues] = useState({
    // email: "",
    cityName: "",
    cityInitial: "",
    metaTitle: "",
    metaDescription: "",
    mapUrl: "",
    content: "",


  })
  const [edit, setEdit] = useState(false);

  // console.log(location, "this is location");


  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {

    if (editorValue.toString("html") === "<p><br></p>") {
      setContentError(true);
      return
    }
    setContentError(false);
    setFormSubmitted(true);
    let formData = edit ? {
      cityName: values.cityName,
      cityInitial: values.cityInitial,
      metaTitle: values.metaTitle,
      metaDescription: values.metaDescription,
      mapUrl: values.mapUrl,
      content: editorValue.toString("html"),
      jobLocationContentId: location.state?._id
    } : {
      cityName: values.cityName,
      cityInitial: values.cityInitial,
      metaTitle: values.metaTitle,
      metaDescription: values.metaDescription,
      mapUrl: values.mapUrl,
      content: editorValue.toString("html"),

    }


    try {
      let res = edit ?
        await patchRequestWithToken('admin/jobLocationContent', formData) : await postRequest('admin/jobLocationContent', formData);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!edit) {
          resetForm();

          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/jobs-by-locations');
          }, 1000);
        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/jobs-by-locations');
          }, 1000);
        }
      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      console.log(err);
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }
  }



  const getEmployerById = async () => {
    try {
      let response = await getRequestWithToken(`admin/jobLocationContent?cityName=&jobLocationContentId=${location.state?._id}`);
      // getting city counts
      try {
        let data = await getRequestWithToken(`admin/jobLocationContent/city-count?cityName=${response.data.data.locationContents[0].cityName}`);
        if (data.data.status === "Success") {
          setJobCount(data.data.data[0].count);
        }
      } catch (error) {
        handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      }


      setDataById(response.data?.data?.locationContents[0])
      // setEditorValue(response.data?.data?.locationContents[0].content)
      setEditorValue(RichTextEditor.createValueFromString(response.data?.data?.locationContents[0].content || "", "html"));
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  // const checkPermissionFunc = () => {
  //   const resourceToUpdate = "jobLocationContent";
  //   // const permissions = checkPermission(resourceToUpdate);
  //   // setCanCreate(permissions.canCreate)
  //   // setCanDelete(permissions.canDelete)
  //   // setCanUpdate(permissions.canUpdate)
  //   // setCanRead(permissions.canRead)
  // }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("jobLocationContent"))
  }


  useEffect(() => {

    // checkPermissionFunc();
    againCheckPermissions();
    if (location.state?._id) {
      getEmployerById()
    }
    else {
      setShowLoader(false);
    }

    if (location?.state?._id) {
      const { employer = {} } = dataById;

      const {

        cityName = dataById?.cityName,
        cityInitial = dataById?.cityInitial,
        metaTitle = dataById?.metaTitle,
        metaDescription = dataById?.metaDescription,
        mapUrl = dataById?.mapUrl,
        content = dataById?.content,

      } = employer;

      setInitialValues({
        cityName,
        cityInitial,
        metaTitle,
        metaDescription,
        mapUrl,
        content,
      });
      setEdit(true);
    } else {
      setInitialValues({
        cityName: "",
        cityInitial: "",
        metaTitle: "",
        metaDescription: "",
        mapUrl: "",
        content: "",
      });
      setEdit(false);
    }
  }, [location.state, dataById._id, isIncludesValue, sector])

  const getCity = async () => {
    try {
      let data = await getRequestWithToken("admin/jobLocationContent/city-count");
      if (data.data.status === "Success") {
        setSector([...data.data.data]);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
    }
  };

  useEffect(() => {
    getCity();
  }, [])

  return (
    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-employer-section" ref={scrollTargetRef}>

        <Container>

          <Box className="create-employer">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings'>{edit ? "Update Location" : "Create Location"}</h1>
            </Box>
            <Box className="create-employer-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CreateJobLocationValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-employer-form ">

                    <Box className="backgroundStyle">

                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={12} md={8}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="cityName">Type City name<span className='must-fill-fields'>*</span></label>

                            <Autocomplete
                              id="tags-outlined"
                              options={sector || []}
                              getOptionLabel={(option) => option._id.toUpperCase()} // Show uppercase text
                              filterSelectedOptions
                              disabled={edit}
                              value={sector.find((option) => option._id === values.cityName) || null}
                              onChange={(event, newValue) => {
                                if (newValue && sector.some((option) => option._id === newValue._id)) {
                                  // If newValue is in options, update state
                                  setFieldValue('cityName', newValue._id);
                                  setJobCount(newValue?.count);
                                } else {
                                  // If not found in options, reset state
                                  setFieldValue('cityName', '');
                                  setJobCount(0);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select City" />
                              )}
                            />

                            {errors.cityName && <Box className="form-error">{errors.cityName}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8}>
                          <Typography>Total jobs <span style={{ fontSize: "20px", fontWeight: 500 }}>{jobCount}</span></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="cityInitial"> Type City initial to display on website <span className='must-fill-fields'>*</span></label>
                            <Field id="cityInitial"
                              type="text" placeholder="enter city initial"
                              name="cityInitial"
                              className="form-input" size="small"
                            />
                            {errors.cityInitial && <Box className="form-error">{errors.cityInitial}</Box>}

                          </Box>
                        </Grid>

                        <Grid item xs={12} ssm={12} md={8}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="metaTitle"> Meta Title<span className='must-fill-fields'>*</span></label>
                            <Field id="metaTitle"
                              type="text" placeholder="Enter Meta Title"
                              name="metaTitle"
                              className="form-input" />
                            {errors.metaTitle && <Box className="form-error">{errors.metaTitle}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="metaDescription">Meta Description <span className='must-fill-fields'>*</span></label>
                            <Field id="metaDescription"
                              type="text" placeholder="Enter Meta Description"
                              name="metaDescription"
                              className="form-input" />
                            {errors.metaDescription && <Box className="form-error">{errors.metaDescription}</Box>}
                          </Box>
                        </Grid>


                        <Grid item xs={12} sm={12} md={8} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="mapUrl">Embed Map URL</label>
                            <Field id="mapUrl"
                              type="text" placeholder="Paste Embed Map URL"
                              name="mapUrl"
                              className="form-input"

                            />
                            {errors.mapUrl && <Box className="form-error">{errors.mapUrl}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="content">Page Content<span className='must-fill-fields'>*</span></label>
                            <RichTextEditor
                              value={editorValue}
                              onChange={setEditorValue}
                              placeholder="Enter/Paste Content here"

                            />
                            {contentError && <Box className="form-error">Content is required</Box>}
                          </Box>
                        </Grid>

                      </Grid>

                    </Box>

                    <Box className="form-button-section">
                      <Link to='/employer'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted || jobCount === 0}
                      >
                        {formSubmitted ? <CircularProgress size={24} /> :
                          edit ? "Update" : "Create"}
                      </Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>

      </Box >}
    </> : (<Unauthorized />)

  )

}
export default CreateLocations;