import { Box, Button, Snackbar, Alert, MenuItem, FormControl, Select, Container, Grid, InputLabel } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import "./announcement.scss";
import { useContext, useEffect, useState, useRef } from 'react';
import { CreateAnnouncementValidationSchema, UpdateAnnouncementValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { useLocation, Link, useNavigate, json } from 'react-router-dom';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { SketchPicker } from 'react-color';
import { PartyModeSharp } from '@mui/icons-material';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import BackBtn from '../../components/backButton/BackBtn';




const CreateAnnouncement = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const [dataById, setDataById] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [filteredAllJobsData, setFilteredAllJobsData] = useState([]);
  const [selectedJobData, setSelectedJobData] = useState(null);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);


  const [initialValues, setInitialValues] = useState({
    title: "",
    message: "",
    bgColor: "",
    jobId: ""
  })

  const [edit, setEdit] = useState(false);

  const inputRefs = {};

  const scrollToErrorField = (fieldName) => {
    if (inputRefs[fieldName]) {
      inputRefs[fieldName].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    console.log("these are the form values : " + values)
    setFormSubmitted(true);
    let formData = new FormData();
    formData = edit ? {
      announcementData: {
        id: location.state.id,
        title: values.title,
        message: values.message,
        bgColor: values.bgColor,
        // jobCategoryId: values.jobCategoryId,
        jobId: values.jobId,
      },

    } : {
      announcementData: {
        title: values.title,
        message: values.message,
        bgColor: values.bgColor,
        // jobCategoryId: values.jobCategoryId,
        jobId: values.jobId,
      }
    }

    try {
      let res = edit ?
        await patchRequestWithToken('admin/announcement', { ...formData }) : await postRequest('admin/announcement', formData);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!edit) {
          resetForm();
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/announcement');
          }, 1000);

        }

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      console.log(err);
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }

  }

  const getAllJobsFromJobSeva = async () => {
    try {
      let response = await getRequestWithToken(`jobs/jobseva-jobs?page=1&limit=100`);
      if (response.data.status === "Success") {
        let allJobsData = response.data.data;
        const filteredJobsData = allJobsData.map(({ job_id, job_role, id }) => ({
          job_id,
          job_role,
          id
        }));
        setFilteredAllJobsData(filteredJobsData);
      }
    }
    catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }

  useEffect(() => {
    getAllJobsFromJobSeva();
    checkPermissionFunc();
    againCheckPermissions();

    if (location?.state?._id) {
      const {
        title = "",
        message = "",
        bgColor = "",
        // jobCategoryId = "",
        jobId = ""
      } = location.state;
      setShowLoader(false);

      setInitialValues({
        title: title,
        bgColor: bgColor,
        message: message,
        // jobCategoryId: jobCategoryId ? jobCategoryId : "",
        jobId: jobId
      });
      setEdit(true);
    } else {
      setInitialValues({
        title: "",
        bgColor: "",
        message: "",
        jobCategoryId: "",
        jobId: ""
      });
      setEdit(false);
      setShowLoader(false);

    }
  }, [location.state, dataById._id, isIncludesValue])
  return (
    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-announcement-section" ref={scrollTargetRef}>

        <Container>
          <Box className="create-announcement">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings'>{edit ? "Update Announcement" : "Create Announcement"}</h1>
            </Box>

            <Box className="create-announcement-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={edit ? UpdateAnnouncementValidationSchema : CreateAnnouncementValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-announcement-form ">

                    <Box className="backgroundStyle">
                      <p className='formSubSectionHeading'>Announcement Details</p>
                      <Grid container spacing={2} className='cstmFormGridAnnouncement'>
                        <Grid item xs={12} sm={6} md={8}>
                          <Grid container spacing={2} className='cstmFormGridAnnouncement'>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box className="form-control">
                                <label className="form-label" htmlFor="title"> Title <span className='must-fill-fields'>*</span></label>
                                <Field innerRef={(el) => { inputRefs.title = el }} id="title"
                                  type="text" placeholder="Enter Title"
                                  name="title"
                                  className="form-input" />
                                {errors.title && <Box className="form-error">{errors.title}</Box>}
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <Box className="form-control">
                                <label className="form-label" htmlFor="message">Message <span className='must-fill-fields'>*</span></label>
                                <Field
                                  innerRef={(el) => { inputRefs.message = el }}
                                  id="message"
                                  as="textarea"  // Use "as" prop to specify the textarea
                                  placeholder="Enter Message"
                                  name="message"
                                  className="form-input"
                                  rows="9"
                                />
                                {errors.message && <Box className="form-error">{errors.message}</Box>}
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <Box className="form-control">
                                <label className="form-label" htmlFor="jobId"> Job ID </label>
                                <FormControl name="jobId" fullWidth size="small">
                                  {values.jobId === "" && <InputLabel shrink={false}>Please select jobId</InputLabel>}
                                  <Select
                                    placeholder='Please Select Jobshayak'
                                    name="jobId"
                                    id="jobId"
                                    value={values.jobId}
                                    onChange={(event) => {
                                      const selectedJobID = event.target.value;
                                      setFieldValue("jobId", selectedJobID.toString());

                                      const sahayak = filteredAllJobsData.find(job => job.job_id === selectedJobID);
                                      setSelectedJobData(sahayak);
                                    }}
                                  >
                                    {filteredAllJobsData.map((job, index) => (
                                      <MenuItem key={index} value={job.id}>
                                        {`${job.job_id} | ${job.job_role} `}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                {errors.jobId && <Box className="form-error">{errors.jobId}</Box>}
                              </Box>
                            </Grid>

                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="bgColor"> Background Color <span className='must-fill-fields'>*</span></label>

                            <Field name="bgColor">
                              {() => (
                                <SketchPicker
                                  color={values.bgColor}
                                  onChange={(color) => setFieldValue('bgColor', color.hex)}
                                />
                              )}
                            </Field>
                            {errors.bgColor && <Box className="form-error">{errors.bgColor}</Box>}

                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="form-button-section">
                      <Link to='/announcement'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}
                      >{edit ? "Update" : "Create"}</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>

      </Box >}
    </> : (<Unauthorized />)

  )

}
export default CreateAnnouncement;