import React, { useEffect, useState } from 'react'
import { Box, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import { getRequest, getRequestQa } from '../../utils/ApiRequests';
import { styled } from '@mui/material/styles';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: "100vh",
//     width: "100%",
//     overflow: "hidden"
//   },
//   form: {
//     width: '100%',
//     maxWidth: 400,
//     marginTop: theme.spacing(1),
//     '& > *': {
//       marginBottom: theme.spacing(1.5),
//     },
//   },
//   button: {
//     backgroundColor: "#ff671f !important"
//   },
//   loader: {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginTop: -12,
//     marginLeft: -12,
//   },
// }));

const Root = styled('div')({
  height: "100vh",
  width: "100%",
  overflow: "hidden",
});

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  marginTop: theme.spacing(1),
  '& > *': {
    marginBottom: theme.spacing(1.5),
  },
}));
const Loader = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});


const ResumeView = ({
  isMobile = false,
  sortListed = [],
  hired = [],
  contacted = false,
  mobile = false,
  targetRef = false,

}) => {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('id');

  useEffect(() => {
    // Open the page in a new tab when the component loads
    if (paramValue) {
      const resumeUrl = `${import.meta.env.VITE_RESUME}/${paramValue}`;
      console.log("Sadf", resumeUrl, paramValue)
      window.open(resumeUrl, '_blank');
    }

  }, [paramValue]);

  return (
    <Root>
      <iframe
        src={`${import.meta.env.VITE_RESUME}/${paramValue}`}
        title="Embedded Website"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      ></iframe>

    </Root>
  );
};

export default ResumeView;

