
import * as React from "react";
import {
  Box,
  Button,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteRequest,
  getRequestWithToken,
} from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const LocationsTable = () => {
  const [employerData, setEmployerData] = useState([]);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);
  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [tabvalue, setTabValue] = React.useState(0);


  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);


  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const createNewEmployer = () => {
    navigate("/jobs-by-locations/create");
  };

  const [showDeletedRows, setShowDeletedRows] = useState(true);

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const filteredRows = showDeletedRows
    ? employerData
    : employerData.filter(
      (employer) =>
        employer.hasOwnProperty("deleted") &&
        employer.deleted.is_deleted === false
    );

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 50,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      header: "City Name",
      size: 100,
      id: 'cityName',
      accessorKey: "cityName",
    },
    {

      header: "City Initial ",
      size: 100,
      id: "cityInitial",
      accessorKey: "cityInitial",
    },
    {

      header: "Meta Title",
      enableColumnFilter: false,

      size: 200,
      id: "metaTitle",
      accessorKey: "metaTitle",
    },
    {
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return (
          <span>
            {value.length > 100 ? `${value.substring(0, 100)}...` : value}
          </span>
        );
      },
      header: "Meta Description",
      size: 200,
      id: "metaDescription",
      enableColumnFilter: false,
      accessorKey: "metaDescription",
    },

    {
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return (
          <span>
            {value.length > 100 ? `${value.substring(0, 100)}...` : value}
          </span>
        );
      },
      header: "Content",
      size: 100,
      enableColumnFilter: false,
      id: "content",
      accessorKey: "content",
    },
    {

      header: "Map Url",
      size: 100,
      enableColumnFilter: false,
      id: "mapUrl",
      accessorKey: "mapUrl",
    },


    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },


    {
      accessorKey: "z_action",
      header: "Action",
      size: 280,
      enableColumnFilter: false,
      Cell: ({ row }) =>
        row.original.deleted?.is_deleted ? (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canUpdate && <EditIcon color="disabled" />}
            {canDelete && <DeleteIcon color="disabled" />}
          </Box>
        ) : (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canRead && (
              <Tooltip title="View">
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  // onClick={() =>
                  //   navigate(`/employer/detail/${row.original._id}`)
                  // }
                  onClick={() =>
                    window.open(`/jobs-by-locations/view/${row.original._id}`, "_blank")
                  }

                />
              </Tooltip>
            )}
            {/* {canUpdate && (
              <Tooltip title="Update wallet">
                <CurrencyRupeeIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() =>
                    navigate(`/update/wallet-amount/${row.original._id}`, {
                      state: "Employer",
                    })
                  }
                />
              </Tooltip>
            )} */}
            {canUpdate && (
              <EditIcon
                sx={{ cursor: "pointer" }}
                color="primary"
                onClick={() =>
                  navigate("/jobs-by-locations/create", { state: row.original })
                }
              />
            )}
            {canDelete && (
              <DeleteIcon
                sx={{ cursor: "pointer" }}
                color="error"
                onClick={() => {
                  setOpenDeleteModal(true);
                  setCandidateId(row.original._id);
                }}
              />
            )}
          </Box>
        ),
    },

  ];

  const deleteEmployer = async () => {
    let formData =
    {
      "jobLocationContentId": candidateId
    }

    try {
      console.log(candidateId);
      let response = await deleteRequest(
        `admin/jobLocationContent`, formData
      );

      handleSnackBar(true, response?.data?.msg, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllEmployers();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  };

  const getAllEmployers = async () => {
    setLoadingDataInGrid(true);
    try {
      if (tabvalue === 0) {
        let response = await getRequestWithToken(
          `admin/jobLocationContent?page=${pagination.pageIndex + 1}&limit=${pagination.pageSize
          }`
        );
        setRowCount(response?.data?.data.totalCount);
        setEmployerData(response?.data?.data.locationContents);
        setLoadingDataInGrid(false);
        setShowLoader(false);
      }
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.msg || error?.message,
        "error"
      );
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!employerData.length) {
        setLoadingDataInGrid(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const filterParams = {
          // global: globalFilter,
          ...Object.fromEntries(
            columnFilters.map((filter) => [filter.id, filter.value])
          ),
        };

        const filterQueryString = new URLSearchParams(filterParams).toString();

        const response = await getRequestWithToken(
          `admin/jobLocationContent?&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&${filterQueryString}`
        );

        setEmployerData(response?.data?.data.locationContents);
        setRowCount(response?.data?.data.totalCount);
        setIsError(false);
        setLoadingDataInGrid(false);
        setShowLoader(false);
      } catch (error) {
        setIsError(true);
        handleSnackBar(
          true,
          error?.response?.data?.message || error?.message,
          "error"
        );
        setLoadingDataInGrid(false);
      }
      setLoadingDataInGrid(false);
      setIsRefetching(false);
      setShowLoader(false);
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const checkPermissionFunc = () => {
    const resourceToUpdate = "jobLocationContent";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("jobLocationContent"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [
    isIncludesValue,
    tabvalue,
  ]);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const currentTabSelect = (event, newValue) => {
    setTabValue(newValue);
  };


  const table = useMaterialReactTable({
    columns,
    data: filteredRows,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableSorting: false,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: "#ff671f", // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "No Data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return isIncludesValue ? (
    <Box className="employers-section">

      <Box
        className="employers-top-section"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {canCreate ? (
          <Button
            variant="contained"
            color="success"
            onClick={createNewEmployer}
          >
            Create New Location
          </Button>
        ) : (
          ""
        )}

        {/* <FormGroup>
          <FormControlLabel control={<Switch checked={showDeletedRows} onChange={handleToggleChange} />} label="Include Deleted Users" labelPlacement="start" />
        </FormGroup> */}
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* <Tabs
            value={tabvalue}
            onChange={currentTabSelect}
            aria-label="basic tabs example"
          >
            <Tab
              label="All Locations"
              style={{ fontWeight: "700" }}
              {...a11yProps(0)}
            />
          </Tabs> */}
        </Box>

        {showLoader ? <ReusableLoader /> : <MaterialReactTable table={table} />}
      </Box>

      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this Location"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deleteEmployer();
        }}
      />
    </Box>
  ) : (
    <Unauthorized />
  );
};
export default LocationsTable;
