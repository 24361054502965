import "./manageqr.scss";
import { Alert, Box, Button, Snackbar, Autocomplete, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, Field } from 'formik';
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import { useState, useEffect } from "react";
import { TimeDisplay } from "../../utils/functions";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { getRequestWithToken, patchRequestWithToken } from "../../utils/ApiRequests";
import { checkPermission } from '../../utils/userPermissions';
import Unauthorized from "../../components/unauthorized/Unauthorized";

const ManageQR = () => {
  const [fileDownload, setFileDownload] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [assignedBatch, setAssignedBatch] = useState(false);
  const [qrData, setQrData] = useState([]);
  const [assignUserData, setAssignUserData] = useState([]);
  const [assignUser, setAssignUser] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [assigningBatch, setAssigningBatch] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  /*Pagination*/
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  // permissions
  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const columns = [
    {
      field: 'batch_id',
      headerName: 'Batch Id',
      width: 100,
    },

    {
      field: 'createdAt',
      headerName: 'Time Stamp',
      width: 400,
      renderCell: (params) => {
        return (<>{TimeDisplay((params.row.createdAt))}</>)
      },
    },
    // {
    //   field: 'active',
    //   headerName: 'Status',
    //   width: 200,
    // },
    {
      field: 'user_assign',
      headerName: 'User Assign',
      width: 200,
      renderCell: (params) => {
        return (<>{params.row?.user_assign === true ? "Assigned" : "Not Assigned"}</>)
      },
    },
    {
      field: 'assign_and_download',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => {

        const handleAssignToUser = () => {
          // Logic to handle job rejection
          setBatchId(params?.row?._id)
          setFormOpen(true)
        };

        return (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canUpdate ? (<Button onClick={handleAssignToUser} color="success" variant="contained">Assign user</Button>) : ""}

            <a style={{
              color: "white !important",
              textDecoration: "none !important"
            }}
              href={params.row.file_url}
              className="download_link" download>
              {canUpdate ? (<Button color="error" variant="contained" disabled={params.row.file_url === undefined}>Download </Button>) : ""}


            </a>
          </Box>
        );
      },

    }

  ];


  const checkPermissionFunc = () => {
    const resourceToUpdate = "Manage Qr";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
    // console.log("resourceToUpdate:", resourceToUpdate);
    // console.log("Can read:", permissions.canRead);
    // console.log("Can create:", permissions.canCreate);
    // console.log("Can delete:", permissions.canDelete);
    // console.log("Can update:", permissions.canUpdate);
  }


  // const getRowId = (row) => row.batch_id;
  const getRowId = (row) => row._id;
  useEffect(() => {
    let getQRData = async () => {
      try {
        let data = await getRequestWithToken("qrcode/all?page=" + currentPage);
        if (data.status === 201 || data.status === "Success") {
          // setQrData([])
          setLoadingDataInGrid(false);
          setShowLoader(false);
          setQrData(data?.data?.data);
          setPageNumber(data?.data?.totalPage);
          setRowCount(data?.data?.totalCount);
        }
        else {
          setLoadingDataInGrid(false);
          setShowLoader(false);
          setQrData([])
        }
      }
      catch (err) {
        setLoadingDataInGrid(false);
        setShowLoader(false);
        setQrData([]);
      }
    }
    let getJobSahayakCandidate = async () => {
      let data = await getRequestWithToken("admin/fetchjobshayak");
      data = data?.data?.data.map(item => ({
        label: `${item.first_name} ${item.last_name || ''}`,
        _id: item._id
      }));
      setAssignUserData(data);

    }
    getQRData();
    getJobSahayakCandidate();
    checkPermissionFunc();
    const aa = () => {
      const checkAccesInArray = localStorage.getItem("resourceNames");
      // console.log("resourceNames : " + checkAccesInArray)
      setisAccess(checkAccesInArray);
      // isIncludes(isAccess.includes("Manage Qr"));
      // console.log("sssssssssssssssssssssssssssssss", isAccess);
      setisIncludesValue(isAccess.includes("Manage Qr"))
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", isIncludesValue);
    }
    aa()


  }, [currentPage, isIncludesValue, isAccess])

  // const againCheckPermissions = () => {

  // }

  // const isIncludes = () => {
  //   setisIncludesValue(isAccess.includes("Manage Qr")cons)
  //   // console.log("ddddddddddddddddddddddd".isIncludesValue);
  // }



  const handleSubmit = async (values, { resetForm }) => {
    setAssigningBatch(true);
    setUsernameError("");
    if (values.button_action === "submit_data") {

      if (values?.username?._id !== undefined) {
        let formData = new FormData();
        formData = {
          userid: values?.username?._id,
          batchid: batchId
        }
        try {
          let response = await patchRequestWithToken("/qrcode/assignbatch", formData);
          if (response.status === 201) {
            setAssignedBatch(true);
            setFormOpen(false);
            setTimeout(() => window.location.reload(), 4000);



          }
        } catch (err) {
          console.log(err);
          setAssigningBatch(false);
        }
      } else {
        setUsernameError("user name is required");
        setAssigningBatch(false);
      }


    }
    if (values.button_action === "cancel") {
      setFormOpen(false);
      setAssigningBatch(false);

    }

  }
  return (
    (isIncludesValue) ? (<Box className="manage-qr-section">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={assignedBatch}
        autoHideDuration={4000}
        onClose={() => setAssignedBatch(false)}
      >
        <Alert severity="success">You have successfully assigned the Batch.</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={fileDownload}
        autoHideDuration={4000}
        onClose={() => setFileDownload(false)}
      >
        <Alert severity="success">You have successfully downloaded the file.</Alert>
      </Snackbar>

      <Modal
        open={formOpen}
        className="assign-batch-modal"
      >
        <Box className="assign-batch-form-outerwrapper">
          <Box className="assign-batch-form-wrapper">
            <Box className="assign-batch-form-topsection">
              <CloseIcon onClick={() => setFormOpen(false)} />
            </Box>
            <Formik
              initialValues={{ username: "" }}
              onSubmit={handleSubmit}
            >
              {({ errors, values, setFieldValue }) => (
                <Form className="assign-batch-form">

                  <Box className="form-control">
                    <label className="form-label" htmlFor="username"> Assign JobSahayak</label>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={assignUserData}
                      sx={{ width: 300 }}
                      value={values.username} // Set the value of Autocomplete
                      onChange={(event, newValue) => {
                        setFieldValue("username", newValue); // Set the Formik field value
                      }}
                      renderInput={(params) => <TextField {...params} label="Jobsahayak" />}
                    />
                    {usernameError && <Box className="form-error" style={{
                      color: "#ad3030",
                      margin: "10px 0px"
                    }}>{usernameError}</Box>}
                  </Box>

                  <Box className="form-button-section">
                    <Button className="form-button"
                      variant="contained"
                      type="submit"
                      color="success"
                      disabled={assigningBatch}
                      onClick={() => setFieldValue("button_action", "submit_data")}>Submit</Button>
                    <Button className="form-button"
                      variant="contained"
                      type="submit"
                      color="error"
                      onClick={() => setFieldValue("button_action", "cancel")}>Cancel</Button>
                  </Box>


                </Form>
              )}
            </Formik>
          </Box>

        </Box>
      </Modal>

      <Box className="manage-qr-section-data">


        {showLoader ? <ReusableLoader /> : <>
          <DataGrid
            rows={qrData && qrData}
            columns={columns}
            getRowId={getRowId}
            paginationMode="server"
            pagination
            rowsPerPageOptions={[10]}
            page={(currentPage - 1)}
            pageSize={pageSize}
            onPageChange={(newPage) => { setLoadingDataInGrid(true); setCurrentPage(newPage + 1); }}
            loading={loadingDataInGrid}
            rowCount={rowCount}
          />


          <div style={{ marginTop: "10px", display: "flex", gap: "15px", justifyContent: "end" }}>
            <Button variant="contained" color="primary" onClick={() => { setCurrentPage(1); console.log("currentPage : " + currentPage) }}>
              Go to first row
            </Button>
            <Button variant="contained" color="primary" onClick={() => setCurrentPage(pageNumber)}>
              Go to last row
            </Button>
          </div>
        </>}

      </Box>
    </Box>) : (<Unauthorized />)
  );
};

export default ManageQR;