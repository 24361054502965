import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Container, Grid, Typography } from '@mui/material';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";

const ViewJobLocationPage = () => {
  const { jobLocationId } = useParams();
  const [searchParams] = useSearchParams();
  const u = searchParams.get("u");
  const [employerData, setEmployerData] = useState([]);

  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);


  const getEmployerDetails = async () => {
    try {
      let response = await getRequestWithToken(`admin/jobLocationContent?cityName=&jobLocationContentId=${jobLocationId}`);
      console.log("response : " + JSON.stringify(response.data.data.locationContents[0]))
      setEmployerData(response.data.data.locationContents[0]);
      // console.log("response : " + JSON.stringify(response.data.data))
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
    }
  };


  useEffect(() => {

    getEmployerDetails();
  }, [jobLocationId]);

  const checkPermissionFunc = () => {
    const resourceToUpdate = "jobLocationContent";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("jobLocationContent"));
  };

  useEffect(() => {
    // getAllJobs();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  return isIncludesValue ? (
    (showLoader) ? <ReusableLoader /> :
      <Box className="create-candidates-section">

        <Container>

          <Box className="create-candidates">

            <Box className="create-candidates-form-wrapper">

              <Box>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h7" component="h4">Job Location Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="backgroundStyle">
                      <Box className='marginBottom10'>

                        <Grid container spacing={2} className='cstmFormGridEmployer'>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'> City Name</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.cityName ? employerData?.cityName : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'> City Initials</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.cityInitial ? employerData?.cityInitial : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' >Meta  Title</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.metaTitle ? employerData?.metaTitle : '--'}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'>Meta Description</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.metaDescription ? employerData?.metaDescription : '--'}</Typography>
                            </Box>
                          </Grid>

                        </Grid>
                      </Box>

                      <Box className='marginBottom10'>

                        <Grid container spacing={2} className='cstmFormGridEmployer'>


                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'>Map URL</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.mapUrl ? employerData?.mapUrl : '--'}</Typography>
                            </Box>
                          </Grid>

                        </Grid>
                      </Box>

                      <Box className='marginBottom10'>

                        <Grid container spacing={2} className='cstmFormGridEmployer'>

                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' style={{ marginBottom: '10px' }} >Page Content</Typography>
                              {/* <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.content ? employerData?.content : '--'}</Typography> */}
                              {employerData?.content ? <div dangerouslySetInnerHTML={{ __html: employerData?.content }} style={{
                                border: "1px solid #e0e0e0", padding: "10px", backgroundColor: "#fff", borderRadius: "10px"
                              }}></div> : "--"}
                            </Box>
                          </Grid>
                        </Grid>


                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>




              </Box>

            </Box>
          </Box>
        </Container>

      </Box >) : <Unauthorized />;
};

export default ViewJobLocationPage;
