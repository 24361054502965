import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Box, Button, DialogActions, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const SureYouWantToDeletModal = ({
  modalTitle = "Modal Title",
  dialogBody = "Dialog Body",
  buttonText = "Yes",
  openDeleteModal = false,
  setOpenDeleteModal = () => { },
  onYesClick = () => { },
  endIcon = "",
  disabled = false
}) => {
  return (
    <Dialog fullWidth open={openDeleteModal} aria-describedby="are-you-sure-you-want-to-delete-modal"
      maxWidth="xs"
      onClose={() => {
        setOpenDeleteModal(false);
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" >
        <DialogTitle>
          {modalTitle}
        </DialogTitle>
        <CloseIcon sx={{ cursor: "pointer", marginRight: 1 }} onClick={() => {
          setOpenDeleteModal(false);
        }} />
      </Box>

      <DialogContent dividers>
        <Typography style={{ fontWeight: "bold" }} textAlign="center">{dialogBody}</Typography>
      </DialogContent>
      <DialogActions>
        <Button disableElevation variant="contained" color="error" onClick={onYesClick} endIcon={endIcon} disabled={disabled} >{buttonText}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SureYouWantToDeletModal