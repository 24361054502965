import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Checkbox,
  Stepper,
  StepLabel,
  Step
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  deleteRequest,
  getRequestWithToken,
  patchRequestWithToken,
  postRequest,
} from "../../utils/ApiRequests";
import "./new.scss";
import { blueGrey } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { Formik } from "formik";
import {
  CreateJobRolesValidationSchema,
  CreateOccupationValidationSchema,
  CreateOccupationValidationSecondSchema,
  CreateSectorValidationSchema,
  CreateJobRolesValidationSecondSchema,
  CreateJobRolesValidationThirdSchema

} from "../../utils/Validations";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { useTheme, useMediaQuery } from "@mui/material";

import DragAndDropComponent from "../../components/DragAndDropComponent/DragAndDropComponent";
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[50]),
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: blueGrey[50],
  },
}));

const JobSecOccuRole = ({ pageName }) => {
  const [sector, setSector] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [jobRoles, setJobRoles] = useState([]);
  const [initialStateForJobSector, setInitialStateForJobSector] = useState({
    sectorNameEn: "",
    sectorNameHn: "",
    sectorNameHi: "",
    sectorNameTa: "",
    sectorNameTe: "",
    sectorNameKn:"",
    sectorNameBn:"",
    sectorNameGu:"",
    sectorNameMr:"",
    sectorSeoTitle: "",
    sectorSeoDescription: "",
    sectorPageTitle: "",
    sectorPageContent: "",
    svgImage: "",
    
  });
  const [initialStateForDepartment, setInitialStateForDepartment] = useState({
    occupationNameEn: "",
    occupationNameHi: "",
    occupationNameHn: "",
    occupationNameTa: "",
    occupationNameTe: "",
    occupationNameKn:"",
    occupationNameBn:"",
    occupationNameGu:"",
    occupationNameMr:"",
    ncoId: "",
    sectorId: "",
    occupationSeoTitle: "",
    occupationSeoDescription: "",
    occupationPageTitle: "",
    occupationPageContent: "",
    svgImage2: "",
  });

  const [initialStateForJobRole, setInitialStateForJobRole] = useState({
    jobRoleNameEn: "",
    jobRoleNameHi: "",
    jobRoleNameHn: "",
    jobRoleNameTa: "",
    jobRoleNameTe: "",
    jobRoleNameKn:"",
    jobRoleNameBn:"",
    jobRoleNameGu:"",
    jobRoleNameMr:"",
    jobRoleImageUrl: "",
    occupationType: "",
    ncoCode: "",
    nsqfLevel: null,
    jobRoleSeoTitle: "",
    jobRoleSeoDescription: "",
    jobRolePageTitle: "",
    jobRolePageContent: "",
    svgImage: "",
    hiringCreditPerVacancy: "",
    mulHiringCreditAfterVacancy: "",
    hiringCreditForContact: "",
    hiringCreditForCVDownload: "",
  });
  const [editJobSectorId, setEditJobSectorId] = useState("");

  const { handleSnackBar } = useContext(GlobalContext);
  const [algoilaProcess, setAlgoliaProsess] = useState(false);
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState("");

  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [selectedOccupationId, setSelectedOccupationId] = useState("");


  const [openSectorModal, setOpenSectorModal] = useState(false);
  const [openSectorViewModal, setOpenSectorViewModal] = useState(false);
  const [openDepartmentViewModal, setOpenDepartmentViewModal] = useState(false);
  const [openJobRoleViewModal, setOpenJobRoleViewModal] = useState(false);
  const [openDepartmentModal, setOpenDepartmentModal] = useState("");
  const [openJobRoleModal, setOpenJobRoleModal] = useState(false);
  const [openDeleteJobSectorModal, setOpenDeleteJobSectorModal] =
    useState(false);
  const [departmentId, setDepartmentId] = useState("");
  const [jobRoleId, setJobRoleId] = useState("");
  const [openDeleteModalOfDepartment, setOpenDeleteModalOfDepartment] =
    useState(false);
  const [openDeleteModalOfJobRole, setOpenDeleteModalOfJobRole] =
    useState(false);
  const [showLoaderForSector, setShowLoaderForSector] = useState(false);
  const [showLoaderForDepartement, setShowLoaderForDepartement] =
    useState(false);
  const [showLoaderForJobRoles, setShowLoaderForJobRoles] = useState(false);

  // permissions
  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);
  const [fileData, setFileData] = useState("");

  const getSector = async () => {
    setShowLoaderForSector(true);
    try {
      let data = await getRequestWithToken(
        `admin/sectors?search=`
      );
      if (data.data.status === "Success") {
        setSector([...data.data.data]);
        setShowLoaderForSector(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setShowLoaderForSector(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = pageName === "seo" ? "SEO" : "Job Category";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  useEffect(() => {
    getSector();
  }, []);

  useEffect(() => {
    if (selectedSectorId) {
      getDepartment(selectedSectorId);
    }
  }, [selectedSector, selectedSectorId]);

  useEffect(() => {
    if (selectedOccupationId) {
      // setSelectedDepartment("");
      getJobRoles(selectedOccupationId);
    }
  }, [selectedOccupation, selectedOccupationId]);

  const getDepartment = async (jobsector) => {
    setJobRoles([]);
    setShowLoaderForDepartement(true);
    try {
      let data = await getRequestWithToken(
        `admin/occupations?sectorId=${jobsector}`
      );
      if (data.data.status === "Success") {
        console.log(
          " this is data2...................",
          data.data.data.occupationNameEn
        );
        setOccupations([...data.data.data]);
        console.log("data found", [data.data.data]);
        setShowLoaderForDepartement(false);
      } else {
        console.log("data not found");
        handleSnackBar(true, data.data.status, "error");
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setOccupations([]);
      setJobRoles([]);
    }
    setShowLoaderForDepartement(false);
  };

  const getJobRoles = async (jobOccupation) => {
    setShowLoaderForJobRoles(true);
    try {
      let data = await getRequestWithToken(
        `admin/job-roles?occupation=${jobOccupation}`
      );
      if (data.data.status === "Success") {
        // console.log(" this is data2...................", data.data.data);
        setJobRoles([...data.data.data]);
        // console.log("data found", [data.data.data]);
        setShowLoaderForJobRoles(false);
      } else {
        console.log("data not found");
        handleSnackBar(true, data.data.status, "error");
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setJobRoles([]);
    }
    setShowLoaderForJobRoles(false);
  };
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const handleJobSectorSubmit = async (values) => {
    // setErrors("sectorPageContent", "hello");
    // return;
    let payload = new FormData();
    // payload = {
    //   sectorNameEn: capitalize(values.sectorNameEn),
    //   sectorNameHn: capitalize(values.sectorNameHn),
    //   sectorNameHi: capitalize(values.sectorNameHi),
    //   metaTitle: !values.sectorSeoTitle ? "" : values.sectorSeoTitle,
    //   metaDescription: !values.sectorSeoDescription
    //     ? ""
    //     : values.sectorSeoDescription,
    //   pageContentTitle: !values.sectorPageTitle ? "" : values.sectorPageTitle,
    //   pageContentText: !values.sectorPageContent
    //     ? ""
    //     : values.sectorPageContent,
    //   image: values.svgPngImg
    // };


    payload.append("sectorNameEn", capitalize(values.sectorNameEn));
    payload.append("sectorNameHn", capitalize(values.sectorNameHn));
    payload.append("sectorNameHi", capitalize(values.sectorNameHi));
    payload.append("sectorNameTa", capitalize(values.sectorNameTa));
    payload.append("sectorNameTe", capitalize(values.sectorNameTe));
    payload.append("sectorNameKn", capitalize(values.sectorNameKn));
    payload.append("sectorNameBn", capitalize(values.sectorNameBn));
    payload.append("sectorNameGu", capitalize(values.sectorNameGu));
    payload.append("sectorNameMr", capitalize(values.sectorNameMr));
    payload.append("metaTitle", values.sectorSeoTitle || "");
    payload.append("metaDescription", values.sectorSeoDescription || "");
    payload.append("pageContentTitle", values.sectorPageTitle || "");
    payload.append("pageContentText", values.sectorPageContent || "");

    // Append the image (file) if it exists
    if (values.svgPngImg) {
      payload.append("image", values.svgPngImg[0]);
    }


    // payload.append("sectorNameEn", capitalize(values.sectorNameEn));
    // payload.append("sectorNameHn", capitalize(values.sectorNameHn));
    // payload.append("sectorNameHi", capitalize(values.sectorNameHi));
    // payload.append("metaTitle", values.sectorSeoTitle);
    // payload.append("metaDescription", values.sectorSeoDescription);
    // payload.append("pageContentTitle", values.sectorPageTitle);
    // payload.append("pageContentText", values.sectorPageContent);

    // if(editJobSectorId === ''){
    //   payload.append("image", values.svgPngImg[0]);
    // }
    try {
      const response = editJobSectorId
        ? await patchRequestWithToken(
          `admin/sectors/${editJobSectorId}`,
          payload
        )
        : await postRequest("admin/sectors", payload);
      const { status = "", msg = "" } = response.data;
      if (status === "Success") {
        await getSector();
        handleSnackBar(true, msg, "success");
        handleCloseJobSectorModal();
      } else handleSnackBar(true, msg, "error");
      setShowLoaderForSector(false);
    } catch (error) {
      setShowLoaderForSector(false);
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setOpenSectorModal(false);
    }
  };

  const handleCloseJobSectorModal = () => {
    setEditJobSectorId("");
    setInitialStateForJobSector({ jobSector: "" });
    setOpenSectorModal(false);
  };
  const handleCloseJobSectorViewModal = () => {
    setEditJobSectorId("");
    setInitialStateForJobSector({ jobSector: "" });
    setOpenSectorViewModal(false);
  };

  const handleDeleteJobSector = async () => {
    try {
      const response = await deleteRequest(`admin/sectors/${editJobSectorId}`);
      const { status, msg } = response.data;
      // console.log(response.data);

      if (status === "Success") {
        await getSector();
        handleCloseJobSectorModal();
        setOpenDeleteJobSectorModal(false);
        setEditJobSectorId("");
        setSelectedSector("");
        setSelectedOccupationId("");
        handleSnackBar(true, msg, "success");
      } else {
        handleSnackBar(true, msg, "error");
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg, "error");
    }
  };

  const handleDeleteDepartment = async () => {
    try {
      const response = await deleteRequest(`admin/occupations/${departmentId}`);
      const { status = "", msg = "" } = response.data;
      if (status === "Success") {
        await getDepartment(selectedSectorId);
        handleSnackBar(true, msg, "success");
        setOpenDeleteModalOfDepartment(false);
        setDepartmentId("");
        setSelectedOccupation("");
        setSelectedOccupationId("");
        setJobRoles([]);
        // getJobRoles(selectedOccupationId);
      } else {
        handleSnackBar(true, msg, "error");
        // setShowLoaderForDepartement(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setShowLoaderForDepartement(false);
    }
  };

  const handleDeleteJobRole = async () => {
    try {
      const response = await deleteRequest(`admin/job-roles/${jobRoleId}`);
      const { status = "", msg = "" } = response.data;
      if (status === "Success") {
        await getJobRoles(selectedOccupationId);
        handleSnackBar(true, msg, "success");
        setOpenDeleteModalOfJobRole(false);
        setDepartmentId("");
      } else {
        handleSnackBar(true, msg, "error");
        // setShowLoaderForDepartement(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setShowLoaderForDepartement(false);
    }
  };

  const [selectedSectors, setSelectedSectors] = useState([]);

  const handleAutocompleteChange = (event, value, setFieldValue) => {
    setSelectedSectors(value);
    // console.log(selectedSectors)
    // setInitialStateForDepartment({ ...initialStateForDepartment, sectorId: value });
  };

  const handleDepartmentSubmit = async (values, setErrors) => {

    // console.log("valuesss : ", values);

    if (selectedSectors.length === 0) {
      setErrors({ sectorId: "Please select atleast one sector" });
      setShowLoaderForDepartement(false);
      return;
    }

    const selectedSectorIds = selectedSectors?.map((sector) => sector._id);
    // const payload = {
    //   occupationNameEn: capitalize(values.occupationNameEn),
    //   occupationNameHi: capitalize(values.occupationNameHi),
    //   occupationNameHn: capitalize(values.occupationNameHn),
    //   ncoId: values.ncoId,
    //   sectorId: selectedSectorIds,
    //   metaTitle: values.occupationSeoTitle,
    //   metaDescription: values.occupationSeoDescription,
    //   pageContentTitle: values.occupationPageTitle,
    //   pageContentText: values.occupationPageContent,
    // };
    const payload = new FormData();
    payload.append("occupationNameEn", capitalize(values.occupationNameEn));
    payload.append("occupationNameHi", capitalize(values.occupationNameHi));
    payload.append("occupationNameHn", capitalize(values.occupationNameHn));
    payload.append("occupationNameTa", capitalize(values.occupationNameTa));
    payload.append("occupationNameTe", capitalize(values.occupationNameTe));
    payload.append("occupationNameKn", capitalize(values.occupationNameKn));
    payload.append("occupationNameBn", capitalize(values.occupationNameBn));
    payload.append("occupationNameGu", capitalize(values.occupationNameGu));
    payload.append("occupationNameMr", capitalize(values.occupationNameMr));
    payload.append("ncoId", values.ncoId);
    // payload.append("sectorId", selectedSectorIds);
    payload.append("sectorId", JSON.stringify(selectedSectorIds));

    payload.append("metaTitle", values.occupationSeoTitle || "");
    payload.append("metaDescription", values.occupationSeoDescription || "");
    payload.append("pageContentTitle", values.occupationPageTitle || "");
    payload.append("pageContentText", values.occupationPageContent || "");

    // Append the image (file) if it exists
    if (values.svgPngImg) {
      payload.append("image", values.svgPngImg[0]); // Assuming values.image is the file object (from file input)
    }

    try {
      const response = departmentId
        ? await patchRequestWithToken(
          `admin/occupations/${departmentId}`,
          payload
        )
        : await postRequest("admin/occupations", payload);
      const { status, msg } = response.data;
      if (status === "Success") {
        await getDepartment(selectedSectorId);
        handleSnackBar(true, msg, "success");
        handleCloseDepartmentModal();
      } else {
        handleSnackBar(true, msg, "error");
        setShowLoaderForDepartement(false);
        setOpenDepartmentModal(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg, "error");
      setShowLoaderForDepartement(false);
      setOpenDepartmentModal(false);
    }
  };

  const handleJobRoleSubmit = async (values) => {
    // const payload = {
    //   jobRoleNameEn: capitalize(values.jobRoleNameEn),
    //   jobRoleNameHi: capitalize(values.jobRoleNameHi),
    //   jobRoleNameHn: capitalize(values.jobRoleNameHn),
    //   occupationType: selectedOccupationId,
    //   ncoCode: values.ncoCode,
    //   nsqfLevel: values.nsqfLevel ? values.nsqfLevel : 0,
    //   hiringCredit: values.hiringCreditPerVacancy
    //     ? parseInt(values.hiringCreditPerVacancy)
    //     : 0,
    //   metaTitle: values.jobRoleSeoTitle,
    //   metaDescription: values.jobRoleSeoDescription,
    //   pageContentTitle: values.jobRolePageTitle,
    //   pageContentText: values.jobRolePageContent,
    // };

    const payload = new FormData();

    // Append text fields to FormData
    payload.append("jobRoleNameEn", capitalize(values.jobRoleNameEn));
    payload.append("jobRoleNameHi", capitalize(values.jobRoleNameHi));
    payload.append("jobRoleNameHn", capitalize(values.jobRoleNameHn));
    payload.append("jobRoleNameTa", capitalize(values.jobRoleNameTa));
    payload.append("jobRoleNameTe", capitalize(values.jobRoleNameTe));
    payload.append("jobRoleNameKn", capitalize(values.jobRoleNameKn));
    payload.append("jobRoleNameBn", capitalize(values.jobRoleNameBn));
    payload.append("jobRoleNameGu", capitalize(values.jobRoleNameGu));
    payload.append("jobRoleNameMr", capitalize(values.jobRoleNameMr));
    payload.append("occupationType", selectedOccupationId);
    payload.append("ncoCode", values.ncoCode);
    payload.append("nsqfLevel", values.nsqfLevel ? values.nsqfLevel : 0);
    payload.append("hiringCredit", values.hiringCreditPerVacancy ? parseInt(values.hiringCreditPerVacancy) : 0);
    payload.append("metaTitle", values.jobRoleSeoTitle || "");
    payload.append("metaDescription", values.jobRoleSeoDescription || "");
    payload.append("pageContentTitle", values.jobRolePageTitle || "");
    payload.append("pageContentText", values.jobRolePageContent || "");

    payload.append("hiringCreditAfterOneVacancy", values.mulHiringCreditAfterVacancy ? parseInt(values.mulHiringCreditAfterVacancy) : 0);
    payload.append("hiringCreditForContact", values.hiringCreditForContact ? parseInt(values.hiringCreditForContact) : 0);
    payload.append("hiringCreditForCvDownload", values.hiringCreditForCVDownload ? parseInt(values.hiringCreditForCVDownload) : 0);


    // Append the image (file) if it exists
    if (values.svgPngImg) {
      payload.append("image", values.svgPngImg[0]);
    }
    try {
      const response = jobRoleId
        ? await patchRequestWithToken(`admin/job-roles/${jobRoleId}`, payload)
        : await postRequest("admin/job-roles", payload);
      const { status, msg } = response.data;
      if (status === "Success") {
        await getJobRoles(selectedOccupationId);
        handleSnackBar(true, msg, "success");
        handleCloseJobRoleModal();
      } else {
        handleSnackBar(true, msg, "error");
        setShowLoaderForJobRoles(false);
        setOpenJobRoleModal(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg, "error");
      setShowLoaderForJobRoles(false);
      setOpenJobRoleModal(false);
    }
  };

  const handleCloseDepartmentModal = () => {
    setInitialStateForDepartment({ department: "" });
    setDepartmentId("");
    setOpenDepartmentModal(false);
  };

  const handleCloseJobOccupationViewModal = () => {
    setInitialStateForDepartment({ department: "" });
    setDepartmentId("");
    setOpenDepartmentViewModal(false);
  };

  const handleCloseJobRoleModal = () => {
    setInitialStateForJobRole({ department: "" });
    setJobRoleId("");
    setOpenJobRoleModal(false);
  };
  const handleCloseJobRoleViewModal = () => {
    // setInitialStateForJobRole({ department: "" });
    setJobRoleId("");
    setOpenJobRoleViewModal(false);
  };

  const isIncludes = () => {
    setisIncludesValue(
      isAccess.includes(pageName === "seo" ? "SEO" : "Job Category")
    );
  };

  const syncAlgolia = () => {
    const syncData = async () => {
      setAlgoliaProsess(true);
      const response = await postRequest(
        `${import.meta.env.VITE_API_BASE_URL}algolia/sync-data`
      );
      setAlgoliaProsess(false);
    };

    syncData();
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));

  const modalWidth = isXs ? "50%" : isMd ? "70%" : "35%";

  //Rich text Area content

  const [activeStep, setActiveStep] = useState(0);
 
  const JobSectorStepper = pageName === "seo" ?["Add Job Sector","Upload Image","SEO"] :["Add Job Sector","Upload Image"] 
  const JobOccupationStepper = pageName === "seo" ?["Add Job Occupation","Other Informations","SEO"] :["Add Job Occupation","Other Informations"]
  const JobRoleStepper = pageName === "seo" ? ["Add Job Role","Other Informations","Hiring Credits Details","SEO"] : ["Add Job Role","Other Informations","Hiring Credits Details"]

  const openJobSectorsModal = () => {
    setEditJobSectorId("");
    setInitialStateForJobSector({ sectorNameEn: "",
      sectorNameHn: "",
      sectorNameHi: "",
      sectorNameTa: "",
      sectorNameTe: "",
      sectorNameKn:"",
      sectorNameBn:"",
      sectorNameGu:"",
      sectorNameMr:"",
      sectorSeoTitle: "",
      sectorSeoDescription: "",
      sectorPageTitle: "",
      sectorPageContent: "",
      svgImage: "", });
    setOpenSectorModal(true);
    setActiveStep(0)
  }
  const handleAddOccupationModal = () => {
    setDepartmentId("");
    setInitialStateForDepartment({ occupationNameEn: "",
      occupationNameHi: "",
      occupationNameHn: "",
      occupationNameTa: "",
      occupationNameTe: "",
      occupationNameKn:"",
      occupationNameBn:"",
      occupationNameGu:"",
      occupationNameMr:"",
      ncoId: "",
      sectorId: "",
      occupationSeoTitle: "",
      occupationSeoDescription: "",
      occupationPageTitle: "",
      occupationPageContent: "",
      svgImage2: "" });
    setOpenDepartmentModal(true);
    setActiveStep(0)
    setSelectedSectors([]);
  }

  const handleAddJobRoleModal = ()  => {
    setDepartmentId("");
    setInitialStateForJobRole({ jobRoleNameEn: "",
      jobRoleNameHi: "",
      jobRoleNameHn: "",
      jobRoleNameTa: "",
      jobRoleNameTe: "",
      jobRoleNameKn:"",
      jobRoleNameBn:"",
      jobRoleNameGu:"",
      jobRoleNameMr:"",
      jobRoleImageUrl: "",
      occupationType: "",
      ncoCode: "",
      nsqfLevel: null,
      jobRoleSeoTitle: "",
      jobRoleSeoDescription: "",
      jobRolePageTitle: "",
      jobRolePageContent: "",
      svgImage: "",
      hiringCreditPerVacancy: "",
      mulHiringCreditAfterVacancy: "",
      hiringCreditForContact: "",
      hiringCreditForCVDownload: "" });
    setOpenJobRoleModal(true);
    setActiveStep(0)
  }

  const UpdateJobSectorValues = (item) => {
      setInitialStateForJobSector({
        ...initialStateForJobSector,
        sectorNameEn: item.sectorNameEn,
        sectorNameHn: item.sectorNameHn,
        sectorNameHi: item.sectorNameHi,
        sectorNameTa: item.sectorNameTa,
        sectorNameTe: item.sectorNameTe,
        sectorNameKn:item.sectorNameKn,
        sectorNameBn:item.sectorNameBn,
        sectorNameGu:item.sectorNameGu,
        sectorNameMr:item.sectorNameMr,
        sectorSeoTitle: item.metaTitle
          ? item.metaTitle
          : "",
        sectorSeoDescription: item.metaDescription
          ? item.metaDescription
          : "",
        sectorPageContent: item.pageContentText
          ? item.pageContentText
          : "",
        sectorPageTitle: item.pageContentTitle
          ? item.pageContentTitle
          : "",
      });
      
    }

  const UpdateJobOccupationValues = (item) => {
    setInitialStateForDepartment({
      ...initialStateForDepartment,
      occupationNameEn: item.occupationNameEn,
      occupationNameHi: item.occupationNameHi,
      occupationNameHn: item.occupationNameHn,
      occupationNameTa: item.occupationNameTa,
      occupationNameTe: item.occupationNameTe,
      occupationNameKn: item.occupationNameKn,
      occupationNameBn: item.occupationNameBn,
      occupationNameGu:item.occupationNameGu,
      occupationNameMr:item.occupationNameMr,
      ncoId: item.ncoId,
      // sectorId: sector?.filter(Item => item.sectorId.includes(Item._id)),
      occupationSeoTitle: item.metaTitle
        ? item.metaTitle
        : "",
      occupationSeoDescription:
        item.metaDescription
          ? item.metaDescription
          : "",
      occupationPageContent:
        item.pageContentText
          ? item.pageContentText
          : "",
      occupationPageTitle: item.pageContentTitle
        ? item.pageContentTitle
        : "",
    });
  }

  const UpdateJobRoleValues = (item) => {
    setInitialStateForJobRole({
      ...initialStateForJobRole,
      // department: department?.key,
      jobRoleNameEn: item.jobRoleNameEn,
      jobRoleNameHi: item.jobRoleNameHi,
      jobRoleNameHn: item.jobRoleNameHn,
      jobRoleNameTa: item.jobRoleNameTa,
      jobRoleNameTe: item.jobRoleNameTe,
      jobRoleNameKn: item.jobRoleNameKn,
      jobRoleNameBn: item.jobRoleNameBn,
      jobRoleNameGu: item.jobRoleNameGu,
      jobRoleNameMr: item.jobRoleNameMr,
      jobRoleImageUrl: item.jobRoleImageUrl,
      occupationType: item.occupationType,
      ncoCode: item.ncoCode,
      hiringCreditPerVacancy: item.hiringCredit,
      nsqfLevel: item.nsqfLevel,
      jobRoleSeoTitle: item.metaTitle
        ? item.metaTitle
        : "",
      jobRoleSeoDescription:
        item.metaDescription
          ? item.metaDescription
          : "",
      jobRolePageTitle: item.pageContentTitle
        ? item.pageContentTitle
        : "",
      jobRolePageContent: item.pageContentText
        ? item.pageContentText
        : "",

      mulHiringCreditAfterVacancy: item?.hiringCreditAfterOneVacancy,
      hiringCreditForContact: item?.hiringCreditForContact,
      hiringCreditForCVDownload: item?.hiringCreditForCvDownload,

    });
  }
  return isIncludesValue ? (
    <Box marginX={2} marginY={2} className="job_sector">
      <Box
        style={{ display: pageName === "seo" ? "none" : "flex" }}
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "#ff671f", margin: "5px" }}
          onClick={() => {
            syncAlgolia();
          }}
        >
          {algoilaProcess ? "Syncing" : "Sync"}
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Card variant="outlined" sx={{ p: 1 }}>
            <h3>Job Sectors </h3>
            {/* <SearchBar style={{ marginBottom: "100px" }} placeholder="Search by Name"
              searchBarValue={searchBarValue} setSearchBarValue={setSearchBarValue} /> */}
            {canCreate && pageName !== "seo" ? (
              <ColorButton
                variant="contained"
                onClick={openJobSectorsModal}
                sx={{ width: "98%" }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <AddIcon fontSize="small" />
                  <Typography fontSize="14px" fontWeight="600">
                    Add Job Sectors
                  </Typography>
                </Box>
              </ColorButton>
            ) : (
              ""
            )}

            <CardContent
              sx={{ height: "70vh", overflow: "scroll", width: "100%", px: 0 }}
            >
              {showLoaderForSector ? (
                <Box
                  display="flex"
                  sx={{ width: "100%", height: "100%" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {" "}
                  <CircularProgress />{" "}
                </Box>
              ) : (
                sector.map((item, index) => (
                  <Paper
                    sx={{ my: 0.8 }}
                    fullWidth
                    key={index}
                    onClick={() => {
                      setSelectedSector(item.sectorNameEn);
                      setSelectedSectorId(item._id);
                      setSelectedOccupation("");
                      // setJobRoles([]);
                      setSelectedOccupationId("");
                      console.log("sector id is : ", item._id);
                    }}
                    className="cursor-pointer"
                  >
                    <Box padding={2}>
                      <Grid container>
                        <Grid item xs={8} className="dflex">
                          <img
                            src={
                              item.imageURL
                                ? item.imageURL
                                : "	https://jobyahanp.s3.ap-south-1.amazonaws.com/images/category-icons/field-sales-officer.png"
                            }
                            width={40}
                            height={40}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {item.sectorNameEn}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            display="flex"
                            sx={{ width: "100%" }}
                            justifyContent="center"
                          >
                            {canUpdate ? (
                              <Box>
                                <EditIcon
                                  onClick={()=>{
                                    UpdateJobSectorValues(item);
                                    setActiveStep(0)
                                    setOpenSectorModal(true);
                                    setEditJobSectorId(item._id);}}
                                  color="primary"
                                />
                              </Box>
                            ) : (
                              ""
                            )}

                            {canRead ? (
                              <Box>
                                <RemoveRedEyeIcon
                                  onClick={() => {
                                    UpdateJobSectorValues(item)
                                    setOpenSectorViewModal(true);
                                    setEditJobSectorId(item._id);
                                  }}
                                  color="primary"
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                            {canDelete && pageName !== "seo" ? (
                              <Box marginLeft={1}>
                                <DeleteIcon
                                  onClick={() => {
                                    setInitialStateForJobSector({
                                      ...initialStateForJobSector,
                                      sectorNameEn: item.sectorNameEn,
                                    });
                                    setEditJobSectorId(item._id);
                                    setOpenDeleteJobSectorModal(true);
                                  }}
                                  color="error"
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                ))
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card variant="outlined" sx={{ p: 1 }}>
            <h3>Job Occupations</h3>
            <h4>
              {selectedSector
                ? `Job Sector => ${selectedSector}`
                : "Select Sector"}
            </h4>
            {selectedSector && pageName !== "seo" && canCreate && (
              <>
                <ColorButton
                  variant="contained"
                  onClick={handleAddOccupationModal}
                  sx={{ width: "98%" }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AddIcon fontSize="small" />
                    <Typography fontSize="14px" fontWeight="600">
                      Add Occupations
                    </Typography>
                  </Box>
                </ColorButton>
              </>
            )}
            <CardContent
              sx={{ height: "70vh", overflow: "scroll", width: "100%", px: 0 }}
            >
              {showLoaderForDepartement ? (
                <Box
                  display="flex"
                  sx={{ width: "100%", height: "100%" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {" "}
                  <CircularProgress />{" "}
                </Box>
              ) : (
                occupations.map((item, index) => (
                  <Paper
                    sx={{ my: 0.8 }}
                    fullWidth
                    key={item?.id}
                    onClick={() => {
                      setSelectedOccupation(item.occupationNameEn);
                      setSelectedOccupationId(item._id);
                      console.log("Occupation id is : ", item._id);
                    }}
                    className="cursor-pointer"
                  >
                    <Box padding={2}>
                      <Grid container>
                        <Grid item xs={8} className="dflex">
                          <img
                            src={
                              item.imageURL
                                ? item.imageURL
                                : "	https://jobyahanp.s3.ap-south-1.amazonaws.com/images/category-icons/field-sales-officer.png"
                            }
                            width={40}
                            height={40}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />

                          <Typography sx={{ textTransform: "capitalize" }}>
                            {item?.occupationNameEn}
                            {/* {item.jobRoleName} */}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            display="flex"
                            sx={{ width: "100%" }}
                            justifyContent="center"
                          >
                            {canUpdate ? (
                              <Box>
                                <EditIcon
                                  onClick={() => {
                                    UpdateJobOccupationValues(item);
                                    setActiveStep(0);
                                    setOpenDepartmentModal(true);
                                    setDepartmentId(item?._id);
                                    let sectorId = sector?.filter((Item) =>
                                      item.sectorId.includes(Item._id)
                                    );
                                    setSelectedSectors(sectorId);
                                  }}
                                  color="primary"
                                />
                              </Box>
                            ) : (
                              ""
                            )}

                            {canRead ? (
                              <Box>
                                <RemoveRedEyeIcon
                                  onClick={() => {
                                    UpdateJobOccupationValues(item)
                                    setOpenDepartmentViewModal(true);
                                    setDepartmentId(item?._id);
                                  }}
                                  color="primary"
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                            {canDelete && pageName !== "seo" ? (
                              <Box marginLeft={1}>
                                <DeleteIcon
                                  onClick={() => {
                                    setInitialStateForDepartment({
                                      ...initialStateForDepartment,
                                      occupationNameEn: item?.occupationNameEn,
                                    });
                                    setDepartmentId(item?._id);
                                    setOpenDeleteModalOfDepartment(true);
                                  }}
                                  color="error"
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                        <Box></Box>
                        <Box></Box>
                      </Grid>
                    </Box>
                  </Paper>
                ))
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card variant="outlined" sx={{ p: 1 }}>
            <h3>Job Roles</h3>
            <h4>
              {selectedOccupation
                ? `Job Occupation => ${selectedOccupation}`
                : "Select Occupation"}
            </h4>
            {selectedOccupation && pageName !== "seo" && canCreate && (
              <>
                <ColorButton
                  variant="contained"
                  onClick={handleAddJobRoleModal}
                  sx={{ width: "98%" }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AddIcon fontSize="small" />
                    <Typography fontSize="14px" fontWeight="600">
                      Add Job Roles
                    </Typography>
                  </Box>
                </ColorButton>
              </>
            )}
            <CardContent
              sx={{ height: "70vh", overflow: "scroll", width: "100%", px: 0 }}
            >
              {showLoaderForJobRoles ? (
                <Box
                  display="flex"
                  sx={{ width: "100%", height: "100%" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {" "}
                  <CircularProgress />{" "}
                </Box>
              ) : (
                jobRoles.map((item, index) => (
                  <Paper sx={{ my: 0.8 }} fullWidth key={item?.id}>
                    <Box padding={2}>
                      <Grid container>
                        <Grid item xs={8} className="dflex">

                          <img
                            src={
                              item.imageURL
                                ? item.imageURL
                                : "https://jobyahanp.s3.ap-south-1.amazonaws.com/images/category-icons/field-sales-officer.png"
                            }
                            width={40}
                            height={40}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />

                          <Typography sx={{ textTransform: "capitalize" }}>
                            {item?.jobRoleNameEn}
                            {/* {item.jobRoleName} */}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            display="flex"
                            sx={{ width: "100%" }}
                            justifyContent="center"
                          >
                            {canUpdate ? (
                              <Box>
                                <EditIcon
                                  onClick={() => {
                                    UpdateJobRoleValues(item)
                                    setActiveStep(0)
                                    setOpenJobRoleModal(true);
                                    setJobRoleId(item?._id);
                                  }}
                                  color="primary"
                                />
                              </Box>
                            ) : (
                              ""
                            )}

                            {canRead ? (
                              <Box>
                                <RemoveRedEyeIcon
                                  onClick={() => {
                                    UpdateJobRoleValues(item);
                                    setOpenJobRoleViewModal(true);
                                    setJobRoleId(item._id);
                                  }}
                                  color="primary"
                                />
                              </Box>
                            ) : (
                              ""
                            )}

                            {canDelete && pageName !== "seo" ? (
                              <Box marginLeft={1}>
                                <DeleteIcon
                                  onClick={() => {
                                    setInitialStateForJobRole({
                                      ...initialStateForJobRole,
                                      jobRoleNameEn: item?.jobRoleNameEn,
                                    });
                                    setJobRoleId(item?._id);
                                    setOpenDeleteModalOfJobRole(true);
                                  }}
                                  color="error"
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                        <Box></Box>
                        <Box></Box>
                      </Grid>
                    </Box>
                  </Paper>
                ))
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ModalComponent
        openModal={openSectorModal}
        setOpenModal={setOpenSectorModal}
        modalTitle={
          editJobSectorId ? "Update New Job Sector" : "Create New Job Sector"
        }
        modalWidth={modalWidth}
        handleOnCloseBtnClick={() => {
          handleCloseJobSectorModal();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialStateForJobSector}
          validationSchema={CreateSectorValidationSchema}
          onSubmit={(values, { setFieldError }) => {
            if(activeStep < JobSectorStepper.length - 1){
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              return;
            }
            if (pageName==="seo" &&
              values?.sectorPageContent?.length !== 0 &&
              values?.sectorPageContent?.length < 250
            ) {
              setFieldError(
                "sectorPageContent",
                "Page Content must be atleast 250 characters"
              );
              return;
            }

            setShowLoaderForSector(true);
            handleJobSectorSubmit(values);
          }}
        >
          {({
            errors,
            values,
            setFieldValue,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Stepper activeStep={activeStep} sx={{ width:"80%",margin:"10px auto"}}>
                  {JobSectorStepper.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                 
                {activeStep === 0 && <Grid container paddingX={2} paddingY={2} spacing={1}>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameEn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      English
                    </label>
                    <TextField
                      value={values.sectorNameEn}
                      size="small"
                      // label="Sector Name English"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameEn)}
                      helperText={errors.sectorNameEn}
                      id="sectorNameEn"
                      name="sectorNameEn"
                      disabled={pageName === "seo"}
                      placeholder="English"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameHn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Hinglish
                    </label>
                    <TextField
                      value={values.sectorNameHn}
                      size="small"
                      placeholder="Hinglish"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameHn)}
                      helperText={errors.sectorNameHn}
                      name="sectorNameHn"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameHn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Hindi
                    </label>
                    <TextField
                      value={values.sectorNameHi}
                      size="small"
                      placeholder="Hindi"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameHi)}
                      helperText={errors.sectorNameHi}
                      name="sectorNameHi"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameTa"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Tamil
                    </label>
                    <TextField
                      value={values.sectorNameTa}
                      size="small"
                      placeholder="Tamil"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameTa)}
                      helperText={errors.sectorNameTa}
                      name="sectorNameTa"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameTe"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Telugu
                    </label>
                    <TextField
                      value={values.sectorNameTe}
                      size="small"
                      placeholder="Telugu"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameTe)}
                      helperText={errors.sectorNameTe}
                      name="sectorNameTe"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameKn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Kannada
                    </label>
                    <TextField
                      value={values.sectorNameKn}
                      size="small"
                      placeholder="Kannada"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameKn)}
                      helperText={errors.sectorNameKn}
                      name="sectorNameKn"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameBn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Bengali
                    </label>
                    <TextField
                      value={values.sectorNameBn}
                      size="small"
                      placeholder="Bengali"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameBn)}
                      helperText={errors.sectorNameBn}
                      name="sectorNameBn"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameGu"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Gujrati
                    </label>
                    <TextField
                      value={values.sectorNameGu}
                      size="small"
                      placeholder="Gujrati"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameGu)}
                      helperText={errors.sectorNameGu}
                      name="sectorNameGu"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="sectorNameMr"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Marathi
                    </label>
                    <TextField
                      value={values.sectorNameMr}
                      size="small"
                      placeholder="Marathi"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.sectorNameMr)}
                      helperText={errors.sectorNameMr}
                      name="sectorNameMr"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  
                </Grid>}

                {activeStep === 1 && <Grid justifyContent="center" item xs={12}>
                    <Box
                      display="flex"
                      className="cursor-pointer"
                      justifyContent="center"
                      width="100%"
                      height={200}
                      paddingX={2} paddingY={2}
                    >
                      <DragAndDropComponent
                        dndTitle="Please Drag and Drop your svg , png File"
                        dndwidth={50}
                        dndHeight={100}
                        name="svgPngImg"
                        onChange={(file) => {
                          setFieldValue("svgPngImg", file);
                        }}
                        fileData={fileData}
                        disabled={pageName === "seo"}
                      />
                    </Box>
                    {errors.svgPngImg && (
                      <Typography
                        fontSize="14px"
                        color="#d32f2f"
                        marginTop={2}
                        textAlign="center"
                      >
                        {errors.svgPngImg}
                      </Typography>
                    )}
                  </Grid>}
                
                {pageName === "seo" && activeStep === 2 ? (
                  <Grid container paddingX={2} paddingY={2} spacing={1}>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        padding: "0px 10px",
                        fontWeight: "600",
                      }}
                    >
                      SEO Section
                    </Typography>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.sectorSeoTitle}
                        size="small"
                        label="Title"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="sectorSeoTitle"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.sectorSeoDescription}
                        size="small"
                        label="Description"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        minRows={3}
                        // error={Boolean(errors.sectorSeoDescription)}
                        // helperText={errors.sectorSeoDescription}
                        name="sectorSeoDescription"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.sectorPageTitle}
                        size="small"
                        label="Page Title"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // error={Boolean(errors.sectorPageTitle)}
                        // helperText={errors.sectorPageTitle}
                        name="sectorPageTitle"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.sectorPageContent}
                        size="small"
                        label="Page Description"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        minRows={6}
                        error={Boolean(errors.sectorPageContent)}
                        helperText={errors.sectorPageContent}
                        name="sectorPageContent"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                <Box
                  sx={{ borderTop: "1px solid #bdbdbd" }}
                  paddingY={1}
                  display="flex"
                  justifyContent="flex-end"
                >  
                  {activeStep === JobSectorStepper.length - 1 ? (
                    <>
                      <Box marginRight={1}>
                        <Button
                          onClick={() => {
                            handleCloseJobSectorModal();
                          }}
                          disableElevation
                          variant="contained"
                          color="error"
                          disabled={showLoaderForSector}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box marginRight={1}>
                        <Button
                          type="submit"
                          disableElevation
                          variant="contained"
                          endIcon={
                            showLoaderForSector ? (
                              <CircularProgress
                                sx={{ color: "#fff" }}
                                size={20}
                              />
                            ) : (
                              ""
                            )
                          }
                          disabled={showLoaderForSector}
                        >
                          {editJobSectorId ? "Update" : "Create"}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box marginRight={1}>
                      <Button
                        type="submit"
                        disableElevation
                        variant="contained"
                        color='success'
                        onClick={(e)=>{
                          e.stopPropagation()
                        }}
                      >
                        Continue
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>
            );
          }}
        </Formik>
      </ModalComponent>

      <SureYouWantToDeletModal
        openDeleteModal={openDeleteJobSectorModal}
        setOpenDeleteModal={setOpenDeleteJobSectorModal}
        modalTitle="Delete Job Sector"
        dialogBody={`Are you sure you want to delete ${initialStateForJobSector.sectorNameEn}`}
        onYesClick={() => {
          setShowLoaderForSector(true);
          handleDeleteJobSector();
        }}
        endIcon={
          showLoaderForSector ? (
            <CircularProgress sx={{ color: "#fff" }} size={20} />
          ) : (
            ""
          )
        }
        disabled={showLoaderForSector}
      />

      <ModalComponent
        openModal={openDepartmentModal}
        setOpenModal={setOpenDepartmentModal}
        modalTitle={departmentId ? "Update Occupation" : "Create Occupation"}
        modalWidth={modalWidth}
        handleOnCloseBtnClick={() => {
          handleCloseDepartmentModal();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialStateForDepartment}
          validationSchema={activeStep === 0 ? CreateOccupationValidationSchema : CreateOccupationValidationSecondSchema}
          onSubmit={(values, { setErrors, setFieldError }) => {
            if(activeStep < JobSectorStepper.length - 1){
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              return;
            }
            if (
              values?.occupationPageContent?.length > 0 &&
              values?.occupationPageContent?.length < 250
            ) {
              setFieldError(
                "occupationPageContent",
                "Content should be atleast 250 characters long"
              );
              return;
            }
            setShowLoaderForDepartement(true);
            handleDepartmentSubmit(values, setErrors);
          }}
        >
          {({
            errors,
            values,
            handleBlur,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Stepper activeStep={activeStep} sx={{ width:"80%",margin:"10px auto"}}>
                  {JobOccupationStepper.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === 0 && <Grid container paddingX={2} paddingY={2} spacing={1}>
                  <Grid item xs={12} md={6}>
                    <label
                      className="form-label"
                      htmlFor="occupationNameEn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     English
                    </label>
                    <TextField
                      value={values.occupationNameEn}
                      size="small"
                      placeholder="English"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameEn)}
                      helperText={errors.occupationNameEn}
                      name="occupationNameEn"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameHn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Hinglish
                    </label>
                    <TextField
                      value={values.occupationNameHn}
                      size="small"
                      placeholder="Hinglish"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameHn)}
                      helperText={errors.occupationNameHn}
                      name="occupationNameHn"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameHi"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Hindi
                    </label>
                    <TextField
                      value={values.occupationNameHi}
                      size="small"
                      placeholder="Hindi"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameHi)}
                      helperText={errors.occupationNameHi}
                      name="occupationNameHi"
                      disabled={pageName === "seo"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameTa"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Tamil 
                    </label>
                    <TextField
                      value={values.occupationNameTa}
                      size="small"
                      placeholder="Tamil"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameTa)}
                      helperText={errors.occupationNameTa}
                      name="occupationNameTa"
                      disabled={pageName === "seo"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameTe"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Telugu
                    </label>
                    <TextField
                      value={values.occupationNameTe}
                      size="small"
                      placeholder="Telugu"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameTe)}
                      helperText={errors.occupationNameTe}
                      name="occupationNameTe"
                      disabled={pageName === "seo"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameKn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Kannada
                    </label>
                    <TextField
                      value={values.occupationNameKn}
                      size="small"
                      placeholder="Kannada"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameKn)}
                      helperText={errors.occupationNameKn}
                      name="occupationNameKn"
                      disabled={pageName === "seo"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameBn"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Bengali
                    </label>
                    <TextField
                      value={values.occupationNameBn}
                      size="small"
                      placeholder="Bengali"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameBn)}
                      helperText={errors.occupationNameBn}
                      name="occupationNameBn"
                      disabled={pageName === "seo"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameGu"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Gujrati
                    </label>
                    <TextField
                      value={values.occupationNameGu}
                      size="small"
                      placeholder="Gujrati"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameGu)}
                      helperText={errors.occupationNameGu}
                      name="occupationNameGu"
                      disabled={pageName === "seo"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                  <label
                      className="form-label"
                      htmlFor="occupationNameMr"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Marathi
                    </label>
                    <TextField
                      value={values.occupationNameMr}
                      size="small"
                      placeholder="Marathi"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.occupationNameMr)}
                      helperText={errors.occupationNameMr}
                      name="occupationNameMr"
                      disabled={pageName === "seo"}
                    />
                  </Grid>

                </Grid>}

                {activeStep === 1 && <Grid container paddingX={2} paddingY={2} spacing={1}>
                <Grid item xs={12} md={12}>
                  <label
                      className="form-label"
                      htmlFor="ncoId"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     NCO ID
                    </label>
                    <TextField
                      value={values.ncoId}
                      size="small"
                      placeholder="NCO Id"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.ncoId)}
                      helperText={errors.ncoId}
                      name="ncoId"
                      disabled={pageName === "seo"}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                  <label
                      className="form-label"
                      style={{
                        color: "rgb(38 38 38)",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                     Sectors
                    </label>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={sector}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.sectorNameEn}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ textTransform: "capitalize" }}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.sectorNameEn}
                        </li>
                      )}
                      // style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          placeholder="Sectors"
                          error={errors.sectorId}
                          helperText={
                            errors.sectorId && errors.sectorId.toUpperCase()
                          }
                          InputProps={{
                            ...params.InputProps,
                            style: { textTransform: "capitalize" },
                          }}
                        />
                      )}
                      onChange={(event, value) =>
                        handleAutocompleteChange(event, value, setFieldValue)
                      }
                      disabled={pageName === "seo"}
                      value={selectedSectors}
                    // value={initialStateForDepartment.sectorId}
                    />
                  </Grid>
                  <Grid justifyContent="center" item xs={12}>
                    <Box display="flex" className="cursor-pointer" justifyContent="center" width="100%" height={200} >
                      <DragAndDropComponent
                        dndTitle="Please Drag and Drop your svg , png File" dndwidth={50} dndHeight={100}
                        name="svgPngImg"
                        onChange={(file) => {
                          setFieldValue("svgPngImg", file);
                        }}
                        disabled={pageName === "seo"}
                      />
                    </Box>
                    {errors.svgPngImg && <Typography fontSize="14px" color="#d32f2f" marginTop={2} textAlign="center" >{errors.svgPngImg}</Typography>}

                  </Grid>
                  </Grid>}

                {(pageName === "seo" && activeStep === JobOccupationStepper.length -1) ? (
                  <Grid container paddingX={2} paddingY={2} spacing={1}>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        padding: "0px 10px",
                        fontWeight: "600",
                      }}
                    >
                      SEO Section
                    </Typography>

                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.occupationSeoTitle}
                        size="small"
                        label="Title"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="occupationSeoTitle"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.occupationSeoDescription}
                        size="small"
                        label="Description"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        minRows={3}
                        // error={Boolean(errors.sectorSeoDescription)}
                        // helperText={errors.sectorSeoDescription}
                        name="occupationSeoDescription"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.occupationPageTitle}
                        size="small"
                        label="Page Title"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // error={Boolean(errors.sectorPageTitle)}
                        // helperText={errors.sectorPageTitle}
                        name="occupationPageTitle"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>


                      <TextField
                        value={values.occupationPageContent}
                        size="small"
                        label="Page Description"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        minRows={6}
                        error={Boolean(errors.occupationPageContent)}
                        helperText={errors.occupationPageContent}
                        name="occupationPageContent"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                <Box
                  sx={{ borderTop: "1px solid #bdbdbd" }}
                  paddingY={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {activeStep === JobOccupationStepper.length - 1 ? (
                    <>
                     <Box marginRight={1}>
                    <Button
                      onClick={() => {
                        handleCloseDepartmentModal();
                      }}
                      disableElevation
                      variant="contained"
                      color="error"
                      disabled={showLoaderForDepartement}
                    >
                      Cancel
                    </Button>
                      </Box>
                      <Box marginRight={1}>
                        <Button
                          type="submit"
                          disableElevation
                          variant="contained"
                          endIcon={
                            showLoaderForDepartement ? (
                              <CircularProgress sx={{ color: "#fff" }} size={20} />
                            ) : (
                              ""
                            )
                          }
                          disabled={showLoaderForDepartement}
                        >
                          {departmentId ? "Update" : "Create"}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box marginRight={1}>
                      <Button
                        type="submit"
                        disableElevation
                        variant="contained"
                        color='success'
                      >
                        Continue
                      </Button>
                    </Box>
                  )}
                  
                </Box>
              </form>
            );
          }}
        </Formik>
      </ModalComponent>

      <SureYouWantToDeletModal
        openDeleteModal={openDeleteModalOfDepartment}
        setOpenDeleteModal={() => {
          setDepartmentId("");
          setOpenDeleteModalOfDepartment(false);
        }}
        modalTitle="Delete Occupation"
        dialogBody={`Are you sure you want to delete " ${initialStateForDepartment.occupationNameEn} " Occupation`}
        onYesClick={() => {
          setShowLoaderForDepartement(true);
          handleDeleteDepartment();
        }}
        endIcon={
          showLoaderForDepartement ? (
            <CircularProgress sx={{ color: "#fff" }} size={20} />
          ) : (
            ""
          )
        }
        disabled={showLoaderForDepartement}
      />

      <SureYouWantToDeletModal
        openDeleteModal={openDeleteModalOfJobRole}
        setOpenDeleteModal={() => {
          setJobRoleId("");
          setOpenDeleteModalOfJobRole(false);
        }}
        modalTitle="Delete Job Role"
        dialogBody={`Are you sure you want to delete " ${initialStateForJobRole.jobRoleNameEn} " Job Role`}
        onYesClick={() => {
          setShowLoaderForJobRoles(true);
          handleDeleteJobRole();
        }}
        endIcon={
          showLoaderForJobRoles ? (
            <CircularProgress sx={{ color: "#fff" }} size={20} />
          ) : (
            ""
          )
        }
        disabled={showLoaderForJobRoles}
      />

      <ModalComponent
        openModal={openJobRoleModal}
        setOpenModal={setOpenJobRoleModal}
        modalTitle={jobRoleId ? "Update Job Role" : "Create Job Role"}
        modalWidth={modalWidth}
        handleOnCloseBtnClick={() => {
          handleCloseJobRoleModal();
        }}
      >  
        <Formik
          enableReinitialize
          initialValues={initialStateForJobRole}
          validationSchema={activeStep === 0 ? CreateJobRolesValidationSchema : (activeStep === 1 ? CreateJobRolesValidationSecondSchema : CreateJobRolesValidationThirdSchema)}
          onSubmit={(values, { setFieldError }) => {
            if(activeStep < JobRoleStepper.length - 1){
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              return;
            }
            if (
              values?.jobRolePageContent?.length > 0 &&
              values?.jobRolePageContent?.length < 250
            ) {
              setFieldError(
                "jobRolePageContent",
                "Content should be atleast 250 characters long"
              );
              return;
            }
            setShowLoaderForJobRoles(true);
            handleJobRoleSubmit(values);
          }}
        >
          {({
            errors,
            values,
            handleBlur,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Stepper
                  activeStep={activeStep}
                  sx={{ width: "100%", margin: "10px auto" }}
                >
                  {JobRoleStepper.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {activeStep === 0 && (
                  <Grid container paddingX={2} paddingY={2} spacing={1}>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameEn"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        English
                      </label>
                      <TextField
                        value={values.jobRoleNameEn}
                        size="small"
                        placeholder="English"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameEn)}
                        helperText={errors.jobRoleNameEn}
                        name="jobRoleNameEn"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameHn"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Hinglish
                      </label>
                      <TextField
                        value={values.jobRoleNameHn}
                        size="small"
                        placeholder="Hinglish"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameHn)}
                        helperText={errors.jobRoleNameHn}
                        name="jobRoleNameHn"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameHn"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Hindi
                      </label>
                      <TextField
                        value={values.jobRoleNameHi}
                        size="small"
                        placeholder="Hindi"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameHi)}
                        helperText={errors.jobRoleNameHi}
                        name="jobRoleNameHi"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameTa"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Tamil
                      </label>
                      <TextField
                        value={values.jobRoleNameTa}
                        size="small"
                        placeholder="Tamil"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameTa)}
                        helperText={errors.jobRoleNameTa}
                        name="jobRoleNameTa"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameTe"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Telugu
                      </label>
                      <TextField
                        value={values.jobRoleNameTe}
                        size="small"
                        placeholder="Telugu"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameTe)}
                        helperText={errors.jobRoleNameTe}
                        name="jobRoleNameTe"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameKn"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Kannada
                      </label>
                      <TextField
                        value={values.jobRoleNameKn}
                        size="small"
                        placeholder="Kannada"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameKn)}
                        helperText={errors.jobRoleNameKn}
                        name="jobRoleNameKn"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameBn"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Bengali
                      </label>
                      <TextField
                        value={values.jobRoleNameBn}
                        size="small"
                        placeholder="Bengali"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameBn)}
                        helperText={errors.jobRoleNameBn}
                        name="jobRoleNameBn"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameGu"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Gujrati
                      </label>
                      <TextField
                        value={values.jobRoleNameGu}
                        size="small"
                        placeholder="Gujrati"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameGu)}
                        helperText={errors.jobRoleNameGu}
                        name="jobRoleNameGu"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="jobRoleNameMr"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Marathi
                      </label>
                      <TextField
                        value={values.jobRoleNameMr}
                        size="small"
                        placeholder="Marathi"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.jobRoleNameMr)}
                        helperText={errors.jobRoleNameMr}
                        name="jobRoleNameMr"
                        disabled={pageName === "seo"}
                      />
                    </Grid>
                    {/* 
                  <Grid item xs={12} md={6} >
                  <TextField
                    value={values.jobRoleImageUrl}
                    size="small"
                    label="Job Role Image URL"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(errors.jobRoleImageUrl)}
                    helperText={errors.jobRoleImageUrl}
                    name="jobRoleImageUrl"
                    disabled={pageName === "seo"}

                  />
                </Grid>
                */}
                  </Grid>
                )}

                {activeStep === 1 && (
                  <Grid container paddingX={2} paddingY={2} spacing={1}>
                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="ncoCode"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        NCO Code
                      </label>
                      <TextField
                        value={values.ncoCode}
                        size="small"
                        placeholder="NCO Code"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.ncoCode)}
                        helperText={errors.ncoCode}
                        name="ncoCode"
                        disabled={pageName === "seo"}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label
                        className="form-label"
                        htmlFor="nsqfLevel"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        NSQF Level
                      </label>
                      <TextField
                        value={values.nsqfLevel}
                        type="number"
                        size="small"
                        placeholder="NSQF Level"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.nsqfLevel)}
                        helperText={errors.nsqfLevel}
                        name="nsqfLevel"
                        disabled={pageName === "seo"}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            type: "text",
                            style: { textAlign: "left" },
                          },
                        }}
                      />
                    </Grid>

                    <Grid justifyContent="center" item xs={12}>
                      <Box
                        display="flex"
                        className="cursor-pointer"
                        justifyContent="center"
                        width="100%"
                        height={200}
                      >
                        <DragAndDropComponent
                          dndTitle="Please Drag and Drop your svg , png File"
                          dndwidth={50}
                          dndHeight={100}
                          name="svgPngImg"
                          onChange={(file) => {
                            setFieldValue("svgPngImg", file);
                          }}
                          disabled={pageName === "seo"}
                        />
                      </Box>
                      {errors.svgPngImg && (
                        <Typography
                          fontSize="14px"
                          color="#d32f2f"
                          marginTop={2}
                          textAlign="center"
                        >
                          {errors.svgPngImg}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}

                {activeStep === 2 && (
                  <Grid container paddingX={2} paddingY={2} spacing={1}>
                    {/* <Grid item xs={12} md={12}>
                      <Box sx={{ fontWeight: 600 }}>
                        Hiring Credits Deductions Details
                      </Box>
                    </Grid> */}

                    <Grid item xs={12} md={12}>
                      <label
                        className="form-label"
                        htmlFor="hiringCreditPerVacancy"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Hiring Credit Per Vacancy
                      </label>
                      <TextField
                        value={values.hiringCreditPerVacancy}
                        type="number"
                        size="small"
                        placeholder="Hiring Credit Per Vacancy"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.hiringCreditPerVacancy)}
                        helperText={errors.hiringCreditPerVacancy}
                        name="hiringCreditPerVacancy"
                        disabled={pageName === "seo"}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            type: "text",
                            style: { textAlign: "left" },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                    <label
                        className="form-label"
                        htmlFor="mulHiringCreditAfterVacancy"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Hiring Credit Multiple After First Vacancy
                      </label>
                      <TextField
                        value={values.mulHiringCreditAfterVacancy}
                        type="number"
                        size="small"
                        placeholder="Hiring Credit Multiple After First Vacancy"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.mulHiringCreditAfterVacancy)}
                        helperText={errors.mulHiringCreditAfterVacancy}
                        name="mulHiringCreditAfterVacancy"
                        disabled={pageName === "seo"}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            type: "text",
                            style: { textAlign: "left" },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                    <label
                        className="form-label"
                        htmlFor="hiringCreditForContact"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Hiring Credit For Contact
                      </label>
                      <TextField
                        value={values.hiringCreditForContact}
                        type="number"
                        size="small"
                        placeholder="Hiring Credit For Contact"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.hiringCreditForContact)}
                        helperText={errors.hiringCreditForContact}
                        name="hiringCreditForContact"
                        disabled={pageName === "seo"}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            type: "text",
                            style: { textAlign: "left" },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                    <label
                        className="form-label"
                        htmlFor="hiringCreditForCVDownload"
                        style={{
                          color: "rgb(38 38 38)",
                          marginBottom: "5px",
                          display: "block",
                        }}
                      >
                        Hiring Credit For CV Download
                      </label>
                      <TextField
                        value={values.hiringCreditForCVDownload}
                        type="number"
                        size="small"
                        placeholder="Hiring Credit For CV Download"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.hiringCreditForCVDownload)}
                        helperText={errors.hiringCreditForCVDownload}
                        name="hiringCreditForCVDownload"
                        disabled={pageName === "seo"}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            type: "text",
                            style: { textAlign: "left" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {pageName === "seo" &&
                activeStep === JobRoleStepper.length - 1 ? (
                  <Grid container paddingX={2} paddingY={2} spacing={1}>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        padding: "0px 10px",
                        fontWeight: "600",
                      }}
                    >
                      SEO Section
                    </Typography>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.jobRoleSeoTitle}
                        size="small"
                        label="Title"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="jobRoleSeoTitle"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.jobRoleSeoDescription}
                        size="small"
                        label="Description"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        minRows={3}
                        // error={Boolean(errors.sectorSeoDescription)}
                        // helperText={errors.sectorSeoDescription}
                        name="jobRoleSeoDescription"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.jobRolePageTitle}
                        size="small"
                        label="Page Title"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // error={Boolean(errors.sectorPageTitle)}
                        // helperText={errors.sectorPageTitle}
                        name="jobRolePageTitle"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={values.jobRolePageContent}
                        size="small"
                        label="Page Description"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        minRows={6}
                        error={Boolean(errors.jobRolePageContent)}
                        helperText={errors.jobRolePageContent}
                        name="jobRolePageContent"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                {/* <Box paddingX={2} paddingY={2}>
                <Autocomplete
                  className='hello'
                  disablePortal
                  id="combo-box-demo2"
                  options={department}
                  getOptionLabel={(option) => option.occupationNameEn}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField className='hello2' {...params} label="Select Occupation" />}
                  onChange={(event, value) => { setSelectedOccupationData(value); }}
                />
              </Box> */}

                <Box
                  sx={{ borderTop: "1px solid #bdbdbd" }}
                  paddingY={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {activeStep === JobRoleStepper.length - 1 ? (
                    <>
                      <Box marginRight={1}>
                        <Button
                          onClick={() => {
                            handleCloseJobRoleModal();
                          }}
                          disableElevation
                          variant="contained"
                          color="error"
                          disabled={showLoaderForJobRoles}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box marginRight={1}>
                        <Button
                          type="submit"
                          disableElevation
                          variant="contained"
                          endIcon={
                            showLoaderForJobRoles ? (
                              <CircularProgress
                                sx={{ color: "#fff" }}
                                size={20}
                              />
                            ) : (
                              ""
                            )
                          }
                          disabled={showLoaderForJobRoles}
                        >
                          {jobRoleId ? "Update" : "Create"}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box marginRight={1}>
                      <Button
                        type="submit"
                        disableElevation
                        variant="contained"
                        color="success"
                      >
                        Continue
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>
            );
          }}
        </Formik>
      </ModalComponent>

      <ModalComponent
        openModal={openSectorViewModal}
        setOpenModal={setOpenSectorViewModal}
        modalTitle={"Job Sector Details"}
        modalWidth="50%"
        handleOnCloseBtnClick={() => {
          handleCloseJobSectorViewModal();
        }}
      >
        <Box className="p-4" style={{ padding: "20px" }}>
          <Grid container>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name English</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector.sectorNameEn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Hinglish</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector.sectorNameHn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Hindi</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector.sectorNameHi}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Tamil</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector?.sectorNameTa}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Telugu</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector?.sectorNameTe}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Kannada</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector?.sectorNameKn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Bengali</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector?.sectorNameBn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Gujrati</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector?.sectorNameGu}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sector Name Marathi</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobSector?.sectorNameMr}
              </Typography>
            </Grid>

            {pageName === "seo" && (
              <>
                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Meta Title</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForJobSector.sectorSeoTitle}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Meta Description</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForJobSector.sectorSeoDescription}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Page Title</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForJobSector.sectorPageTitle}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Page Content</Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: initialStateForJobSector.sectorPageContent,
                    }}
                    style={{ color: "#615F5F" }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </ModalComponent>

      <ModalComponent
        openModal={openDepartmentViewModal}
        setOpenModal={setOpenDepartmentViewModal}
        modalTitle={"Job Occupation Details"}
        modalWidth="70%"
        handleOnCloseBtnClick={() => {
          handleCloseJobOccupationViewModal();
        }}
      >
        <Box className="p-4" style={{ padding: "20px" }}>
          <Grid container>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name English
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameEn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Hinglish
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameHn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Hindi
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameHi}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Tamil
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameTa}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Telugu
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameTe}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Kannada
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameKn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Bengali
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameBn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Gujrati
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameGu}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Occuaption Name Marathi
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.occupationNameMr}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">NCO ID</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment.ncoId}
              </Typography>
            </Grid>

            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Sectors</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForDepartment?.sectorId}
              </Typography>
            </Grid>

            {pageName === "seo" && (
              <>
                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Meta Title</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForDepartment.occupationSeoTitle}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Meta Description</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForDepartment.occupationSeoDescription}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Page Title</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForDepartment.occupationPageTitle}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Page Content</Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: initialStateForDepartment.occupationPageContent,
                    }}
                    style={{ color: "#615F5F" }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </ModalComponent>

      <ModalComponent
        openModal={openJobRoleViewModal}
        setOpenModal={setOpenJobRoleViewModal}
        modalTitle={"Job Role Details"}
        modalWidth="50%"
        handleOnCloseBtnClick={() => {
          handleCloseJobRoleViewModal();
        }}
      >
        <Box className="p-4" style={{ padding: "20px" }}>
          <Grid container>
            {" "}
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Job Role Name English
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameEn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Job Role Name Hinglish
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameHn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Name Hindi</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameHi}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Name Tamil</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameTa}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Name Telugu</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameTe}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Name Kannada</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameKn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Name Bengali</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameBn}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Name Gujrati</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameGu}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Name Marathi</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.jobRoleNameMr}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">NCO Code</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.ncoCode}
              </Typography>
            </Grid>
            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">NSQF Level</Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.nsqfLevel}
              </Typography>
            </Grid>

            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Hiring Credit per vacancy
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.hiringCreditPerVacancy}
              </Typography>
            </Grid>

            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Multiple Hiring Credit After First Vacancy
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.mulHiringCreditAfterVacancy}
              </Typography>
            </Grid>

            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Hiring Credit For Contact
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.hiringCreditForContact}
              </Typography>
            </Grid>

            <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">
                Hiring Credit For CV Downlaod
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", textTransform: "capitalize" }}
              >
                {initialStateForJobRole.hiringCreditForCVDownload}
              </Typography>
            </Grid>


            {/* <Grid item xs={6} className="marginBottom10px">
              <Typography className="fontBold">Job Role Image</Typography>
              <img src={initialStateForJobRole.jobRoleImageUrl} width={50} alt="" />
            </Grid> */}
            {pageName === "seo" && (
              <>
                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Meta Title</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForJobRole.jobRoleSeoTitle}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Meta Description</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForJobRole.jobRoleSeoDescription}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Page Title</Typography>
                  <Typography
                    style={{ paddingLeft: "10px", textTransform: "capitalize" }}
                  >
                    {initialStateForJobRole.jobRolePageTitle}
                  </Typography>
                </Grid>

                <Grid item xs={6} className="marginBottom10px">
                  <Typography className="fontBold">Page Content</Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: initialStateForJobRole.jobRolePageContent,
                    }}
                    style={{ color: "#615F5F" }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </ModalComponent>
    </Box>
  ) : (
    <Unauthorized />
  );
};

export default JobSecOccuRole;
