import './allemployer.scss'
import { useState, useEffect } from 'react'
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Sidebar from '../sidebar/Sidebar'
import Navbar from '../navbar/Navbar'
import Paper from "@mui/material/Paper";


const token = localStorage.getItem('token');

const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json'
};

const Allemployers = () => {
  const [employers, setEmployers] = useState([]);

  const getallemployer = async () => {
    let data = await axios.get('https://13.235.183.204:3001/api/admin/getallemployer', { headers })
    setEmployers(data.data.data)
  }

  useEffect(() => {
    getallemployer()

  }, []);

  return (
    <>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 700 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">Id</TableCell>
                  <TableCell className="tableCell">Name</TableCell>
                  <TableCell className="tableCell">Email</TableCell>
                  <TableCell className="tableCell">Phone</TableCell>
                  <TableCell className="tableCell">Company website</TableCell>
                  <TableCell className="tableCell" >Company Email</TableCell>
                  <TableCell className="tableCell" >Company Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employers.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell className="tableContent">{row._id}</TableCell>
                    <TableCell className="tableContent">{row.employer_name}</TableCell>
                    <TableCell className="tableContent">{row.employer_email}</TableCell>
                    <TableCell className="tableContent">{row.company_lanNumber}</TableCell>
                    <TableCell className="tableContent">{row.company_website}</TableCell>
                    <TableCell className="tableContent">{row.company_email}</TableCell>
                    <TableCell className="tableContent">{row.company_type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  )
}


export default Allemployers;