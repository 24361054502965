import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  getRequestWithToken,
  getRequestWithTokenV3,
} from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import "./candidates.scss";
import BackBtn from "../../components/backButton/BackBtn";
// import { TimeDisplay } from "../../utils/functions";
const CandidateDetails = () => {
  const { candidateId } = useParams();
  const [candidateData, setCandidateData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);
  const [languages, setLanguages] = useState([]);
  const getCandidateDetails = async () => {
    try {
      let response = await getRequestWithToken(
        `admin/userbyid?usertype=job_seeker&userid=${candidateId}`
      );
      setCandidateData(response.data.data.candidate);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getCandidateDetails();
  }, [candidateId]);

  // useEffect(() => {
  //   console.log("data response: ", JSON.stringify(candidateData));
  // }, [candidateData]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const regex = /^(\d{2})[-/](\d{2})[-/](\d{4})$/;
    const match = dateString.match(regex);
    if (match) {
      return dateString?.replaceAll("-", "/");
    }

    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the format as needed
  };
  const getCandidateExperience = (experience) => {
    if (!experience) return "";
    let [exp_y, exp_m] = experience.split(".");
    exp_y = !exp_y ? "" : exp_y + " years";
    exp_m = !exp_m ? "" : exp_m + " months";
    return exp_y + " " + exp_m;
  };

  const getGender = (data) => {
    if (data === 1) return "Female";
    else if (data === 2) return "Male";
    else if (data === 3) return "Other";
    else return "-";
  };

  const getSocialCategory = (data) => {
    if (data === 0) return "-";
    else if (data === 1) return "Open to All";
    else if (data === 2) return "General";
    else if (data === 3) return "SC";
    else if (data === 4) return "ST";
    else if (data === 5) return "OBC";
    else return "-";
  };

  const getAssetsOwned = (data) => {
    const assets_ownedRequired = [
      { id: 1, label: "Two Wheeler", value: 1 },
      { id: 2, label: "Four Wheeler", value: 2 },
      { id: 3, label: "Basic Phone", value: 3 },
      { id: 4, label: "Smartphone", value: 4 },
      { id: 5, label: "Own Laptop", value: 5 },
      { id: 6, label: "Internet Connection", value: 6 },
      { id: 7, label: "Tools and Requirement", value: 7 },
    ];
    return data
      ?.map(
        (item) =>
          assets_ownedRequired.find((asset) => asset.value === item)?.label ||
          "-"
      )
      .join(",");
  };

  const getDocument = (data) => {
    const governmentDocumentsRequired = [
      { id: 1, label: "Aadhaar Card", value: 1 },
      { id: 2, label: "PAN Card", value: 2 },
      { id: 3, label: "E-Shram Card", value: 3 },
      {
        id: 4,
        label: "Bank Account with passbook",
        value: 4,
      },
      { id: 5, label: "Education Certificate", value: 5 },
      { id: 6, label: "Driving License", value: 6 },
    ];
    return data
      ?.map(
        (item) =>
          governmentDocumentsRequired.find((asset) => asset.value === item)
            ?.label || "-"
      )
      .join(",");
  };

  const getQualification = (data) => {
    const educationQualificationData = [
      { id: 1, label: "10th", value: 2 },
      { id: 2, label: "12th", value: 3 },
      { id: 3, label: "Diploma after 10th", value: 4 },
      {
        id: 4,
        label: "Diploma after 12th",
        value: 5,
      },
      { id: 5, label: "Graduate", value: 6 },
      { id: 6, label: "Post Graduate", value: 7 },
    ];

    return (
      educationQualificationData.find((asset) => asset.value === data)?.label ||
      "-"
    );
  };
  const getCertificate = (data) => {
    const certificateSpeciliazationData = [
      { id: 1, label: "None", value: 0 },
      { id: 2, label: "ITI", value: 1 },
      { id: 3, label: "DDUGKY", value: 2 },
      { id: 4, label: "Skill India", value: 3 },
    ];

    return (
      certificateSpeciliazationData.find((asset) => asset.value === data)
        ?.label || "-"
    );
  };

  const getLanguageData = async () => {
    try {
      let languageData = await getRequestWithTokenV3(`candidate/languages`);
      if (languageData.status === "Success") {
        languageData = languageData?.data;
        languageData.shift();
        setLanguages(languageData);
      } else {
        setLanguages([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLanguageData();
  }, []);

  const getLanguage = (data) => {
    return languages?.find((asset) => asset.id === data)?.label || "-";
  };

  const getEnglishProficiency = (data) => {
    if (data === 0) return "No English";
    else if (data === 1) return "Not required";
    else if (data === 2) return "Thoda English";
    else if (data === 3) return "Good English";
    else if (data === 4) return "Fluent";
    else return "-";
  };

  const getEmployementStatus = (data) => {
    const employementStatus = [
      { id: 0, label: "-", value: "-" },
      { id: 1, label: "Fresher", value: "fresher" },
      {
        id: 2,
        label: "unemployed & Experienced",
        value: "unemployed & experienced",
      },
      { id: 3, label: "Working Full Time", value: "working full time" },
      { id: 4, label: "Working Part Time", value: "working part time" },
    ];
    return employementStatus.find((asset) => asset.id === data)?.label || "-";
  };

  const getWorkMode = (data) => {
    const workMode = [
      {
        id: 1,
        label: "Field Job",
      },
      {
        id: 2,
        label: "Work from Office",
      },
      {
        id: 3,
        label: "Work from Home",
      },
      {
        id: 4,
        label: "Hybrid",
      },
    ];
    return data
      ?.map((item) => workMode.find((asset) => asset.id === item)?.label || "-")
      .join(",");
  };

  const getPWDCategory = (data) => {
    const pwdCategoryData = [
      { id: 2, value: "acid attack victim", label: "Acid Attack Victim" },
      { id: 3, value: "dwarfism", label: "Dwarfism" },
      { id: 4, value: "locomotor disability", label: "Locomotor Disability" },
      { id: 5, value: "visual impairment", label: "Visual Impairment" },
      {
        id: 6,
        value: "intellectual disability",
        label: "Intellectual Disability",
      },
      { id: 7, value: "mental disorder", label: "Mental Disorder" },
      { id: 8, value: "neurological disorder", label: "Neurological Disorder" },
    ];

    return data
      ?.map(
        (item) =>
          pwdCategoryData.find((asset) => asset.id === item)?.label || "-"
      )
      .join(",");
  };
  const getPhyscialFitness = (data) => {
    const physicalFitnessData = [
      {
        id: 1,
        value: 1,
        label: "Open to Physical Activity",
      },
      {
        id: 2,
        value: 2,
        label: "6/6 Vision with Spectacles",
      },
      {
        id: 3,
        value: 3,
        label: "No Hearing Impairment",
      },
    ];
    return data
      ?.map(
        (item) =>
          physicalFitnessData.find((asset) => asset.id === item)?.label || "-"
      )
      .join(",");
  };

  const getPastworkExperience = (months) => {
    const years = Math.floor(months / 12);
    const remainingMonths = Math.round((months % 12) * 10) / 10; // To handle rounding errors
    return `${years} year${years !== 1 ? "s" : ""} ${remainingMonths} month${
      remainingMonths !== 1 ? "s" : ""
    }`;
  };

  const getMaritalStatus = (data) => {
    if (data === 1) return "Married";
    if (data === 2) return "Unmarried";
    if (data === 3) return "Other";
  };

  const formatDuration = (item) => {
    const months = item?.duration?.months ?? 0;
    const years = item?.duration?.year ?? 0;
    return ` ${years} year${years !== 1 ? "s" : ""} ${months} month${
      months !== 1 ? "s" : ""
    }`;
  };

  const getLearningMode = (data) => {
    let LearningMode = [
      { id: 1, label: "Classroom" },
      {
        id: 2,
        label: "E-learning/correspondance",
      },
      {
        id: 3,
        label: "Hybrid",
      },
    ];
    return LearningMode.find((asset) => asset.id === data)?.label || "-";
  };

  const getEngagementType = (data) => {
    if (!data) return "";
    else if (data === 1) return "Full Time";
    else if (data === 2) return "Part Time";
    else return "";
  };

  const getWorkLocationPreference = (data) => {
    if (data === 1) return "Pass";
    else if (data === 2) return "Thoda Dur";
    else if (data === 3) return "Bahut Dur";
    else return "-";
  };

  const TimeDisplayYear = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    // const day = date.getUTCDate();
    // const month = date.getUTCMonth() + 1; // Months are 0-based, so add 1
    const year = date.getUTCFullYear();
    const formattedDate = `${year}`;
    return formattedDate;
  };

  return showLoader ? (
    <ReusableLoader />
  ) : (
    <Box className="create-candidates-section">
      <Container>
        <Box className="create-candidates">
          <Box className="headingPlusBackBtn">
            <BackBtn />
            <h1 className="formHeadings" id="topEle">
              {" "}
              Candidate Details
            </h1>
          </Box>
          <Box className="create-candidates-form-wrapper">
            <Box className="backgroundStyle">
              <Typography
                variant="h7"
                component="h3"
                gutterBottom
                className="formSubSectionHeadng "
              >
                Personal Details
              </Typography>
              <Grid container spacing={2} className="cstmFormGridEmployer">
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1"> Full Name</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textDecoration: "capitalize",
                      }}
                    >
                      {[
                        candidateData.first_name,
                        candidateData.middle_name,
                        candidateData.last_name,
                      ]
                        .filter(Boolean)
                        .join(" ")}
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> Middle Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{candidateData.middle_name}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Last Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{candidateData.last_name}</Typography>
                    </Box>
                  </Grid> */}

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Mobile Number</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {candidateData.mobile}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Email ID</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {candidateData.email}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Date of Birth</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {formatDate(candidateData.dob)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Gender </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {getGender(candidateData.gender)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Pincode </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {candidateData?.pincode || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">State </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {candidateData?.state || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">District / City</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {candidateData?.district || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">City </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {candidateData?.city || "-"}
                    </Typography>
                  </Box>
                </Grid> */}

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Complete Address{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {candidateData?.address || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Level of physical fitness{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {getPhyscialFitness(
                        candidateData?.physical_fitness_level
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Social Category</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {getSocialCategory(candidateData?.social_category)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">PWD Category</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {candidateData?.accessibility_info?.is_pwd_category
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Applicable Category
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {getPWDCategory(
                        candidateData?.accessibility_info?.applied_category
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Migrate to other state
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {candidateData?.migrate_concerns ? "Yes" : "No"}
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Open to police verification</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.police_verification_concerns ? "Yes" : "No"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Open to medical or drug test</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.drug_test_concerns  ? "Yes" :"No"}</Typography>
                    </Box>
                  </Grid> */}

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Assets Owned</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {getAssetsOwned(candidateData?.assets_owned)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Document Posses</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {getDocument(candidateData?.documents_possess)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1"> Marital Status</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {getMaritalStatus(candidateData?.marital_status)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      {" "}
                      Number of dependent Members
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {candidateData?.dependent_family_members}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className="backgroundStyle">
              <Typography
                variant="h7"
                component="h3"
                gutterBottom
                className="formSubSectionHeading"
              >
                Skill Details
              </Typography>
              <Grid container spacing={2} className="cstmFormGridEmployer">
                <Grid item xs={12} sm={12} md={12}>
                  Highest Education
                </Grid>
                {candidateData?.education_information?.length > 0 ? (
                  <>
                    {candidateData?.education_information?.map((item) => {
                      return (
                        <Grid
                          container
                          style={{
                            margin: "10px 20px",
                            background: "white",
                            padding: "10px",
                          }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Qualification
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  paddingLeft: "5px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {getQualification(item?.qualification)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Stream/Specialisation
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {item?.qualification_stream}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Year of Completion
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {!item?.completion_year
                                  ? "-"
                                  : TimeDisplayYear(item?.completion_year)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">Score</Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {`${
                                  !item?.score?.value
                                    ? "-"
                                    : ` ${item?.score?.value} ${
                                        item?.score?.type === 1 ? "%" : "CGPA"
                                      }`
                                } `}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Medium of Education
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {getLanguage(item.medium_of_education)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                School /College Name
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {item?.school?.name}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                School /College address
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {item?.school?.address}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ margin: "0px 15px" }}> No Data Found</div>
                )}

                <Grid item xs={12} sm={12} md={12}>
                  Certification
                </Grid>
                {candidateData?.certification?.length > 0 ? (
                  <>
                    {candidateData?.certification?.map((item) => {
                      return (
                        <Grid
                          container
                          style={{
                            margin: "10px 20px",
                            background: "white",
                            padding: "10px",
                          }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Certificate of Vocation or Skill
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {getCertificate(item.name)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Stream/Specialisation
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  paddingLeft: "5px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item?.stream}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Year of Completion
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {!item?.completion_year
                                  ? "-"
                                  : TimeDisplayYear(item?.completion_year)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Medium of Education
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {getLanguage(item.medium_of_education)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                School /College Name
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {item?.school?.name}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                School /College address
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {item?.school?.address}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Course Duration
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {formatDuration(item)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Learning Mode
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {getLearningMode(item?.school?.learning_mode)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ margin: "0px 15px" }}> No Data Found</div>
                )}

                {/* <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography variant="subtitle1">
                      Commercial License
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {candidateData?.commercial_license}
                    </Typography>
                  </Box>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12}>
                  Known Language
                </Grid>
                {candidateData?.known_languages?.length > 0 ? (
                  <>
                    {candidateData?.known_languages?.map((item) => {
                      return (
                        <Grid
                          container
                          style={{
                            margin: "10px 20px",
                            background: "white",
                            padding: "10px",
                          }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Language
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: "5px" }}
                              >
                                {getLanguage(item.value)}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Can Speak
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  paddingLeft: "5px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item?.speak ? "Yes" : "No"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Can Write
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  paddingLeft: "5px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item?.write ? "Yes" : "No"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="subtitle1">
                                Can Read
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  paddingLeft: "5px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item?.read ? "Yes" : "No"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ margin: "0px 15px" }}> No Data Found</div>
                )}

                <Grid item xs={12} sm={12} md={12}>
                  <Box className="form-control">
                    <Typography variant="subtitle1">
                      English Proficiency
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {getEnglishProficiency(
                        candidateData?.english_proficiency
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className="backgroundStyle">
              <Typography
                variant="h7"
                component="h3"
                gutterBottom
                className="formSubSectionHeading"
              >
                Work Details
              </Typography>
              <Grid container spacing={2} className="cstmFormGridEmployer">
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Employment Status
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {getEmployementStatus(
                        candidateData?.current_employment_status
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Total work experience
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingLeft: "5px" }}
                    >
                      {/* {getCandidateExperience(
                        candidateData?.past_work_experience
                      )} */}
                      {!isNaN(candidateData?.past_work_experience)
                        ? getPastworkExperience(
                            candidateData?.past_work_experience
                          )
                        : candidateData?.past_work_experience || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Work Mode</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {getWorkMode(candidateData?.work_mode_choice)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Engagement Type Preference
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {getEngagementType(
                        candidateData?.engagement_type_preference
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                      Work Location Preference
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {getWorkLocationPreference(
                        candidateData?.location_preference
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                     Number of Jobs Done
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {candidateData?.past_job_count}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">
                     Job Expertise
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingLeft: "5px",
                        textTransform: "capitalize",
                      }}
                    >
                      {candidateData?.job_expertise?.toString()}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    Work Experience
                  </Grid>
                  {candidateData?.experience_details?.length > 0 ? (
                    <>
                      {candidateData?.experience_details?.map((item, index) => {
                        return (
                          <Box
                            style={{
                              margin: "10px 20px",
                              background: "white",
                              padding: "10px",
                            }}
                          >
                            {" "}
                            <h3>{`Experience ${index + 1}`}</h3>
                            <Grid container>
                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <Typography variant="subtitle1">
                                    Job Role
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{ paddingLeft: "5px" }}
                                  >
                                    {item.job_role}
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <Typography variant="subtitle1">
                                    Company Name
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{ paddingLeft: "5px" }}
                                  >
                                    {item.company_name}
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <Typography variant="subtitle1">
                                    Address
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{ paddingLeft: "5px" }}
                                  >
                                    {item.address}
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <Typography variant="subtitle1">
                                    Salary (per month)
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      paddingLeft: "5px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item?.Salary_per_month}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <Typography variant="subtitle1">
                                    Currently Working
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      paddingLeft: "5px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item?.employment_period?.currently_working
                                      ? "Yes"
                                      : "No"}
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <Typography variant="subtitle1">
                                    Employment Starting time
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      paddingLeft: "5px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {formatDate(item?.employment_period?.from)}
                                  </Typography>
                                </Box>
                              </Grid>

                              {!item?.employment_period?.currently_working && (
                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <Typography variant="subtitle1">
                                      Employment Ending time
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        paddingLeft: "5px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {formatDate(item?.employment_period?.to)}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        );
                      })}
                    </>
                  ) : (
                    "No Data found"
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CandidateDetails;
