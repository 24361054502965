import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Button, Container, Grid, Typography, Switch } from '@mui/material';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import "./recruiter.scss";
import BackBtn from '../../components/backButton/BackBtn';
import { validationSchemaForDepartment } from '../../utils/Validations';


const ViewClientDetails = () => {
  const { candidateId } = useParams();
  const [candidateData, setCandidateData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);

  const getCandidateDetails = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid?usertype=client&userid=${candidateId}`);
      setCandidateData(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
    }
  };

  const fullTimEmp = (val) => {
    if (val === 0) {
      return "NA";
    } else if (val === 1) {
      return "0-10";
    } else if (val === 2) {
      return "11-100";
    } else if (val === 3) {
      return "101-1000";
    } else if (val === 4) {
      return "More Than 1000";
    } else {
      return "Not Mentioned";
    }

  }

  const officePresence = (val) => {
    if (val === 0) {
      return "NA";
    } else if (val === 1) {
      return "1 City";
    } else if (val === 2) {
      return "1-5 Cities";
    } else if (val === 3) {
      return "Multiple Cities";
    } else {
      return "Not Mentioned";
    }

  }

  useEffect(() => {
    getCandidateDetails();
  }, [candidateId]);

  useEffect(() => {
    console.log("data response: ", JSON.stringify(candidateData));
  }, [candidateData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the format as needed
  };
  const getCandidateExperience = (experience) => {
    if (!experience) return "";
    let [exp_y, exp_m] = experience.split(".");
    exp_y = !exp_y ? "" : exp_y + " years";
    exp_m = !exp_m ? "" : exp_m + " months";
    return exp_y + " " + exp_m;
  }

  return (
    (showLoader) ? <ReusableLoader /> :
      <Box className="create-candidates-section">

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >  Client Details</h1>
            </Box>
            <Box className="create-candidates-form-wrapper">

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeadng '>Personal Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" > Recruiter Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.employerId?.firstName} {candidateData?.employerId?.middleName} {candidateData?.employerId?.lastName}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> Recruiter ID</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{candidateData?.employerId?.employerId}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Organisation Name of Client</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{candidateData?.organizationName}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Trade Brand Name of Client</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.tradeOrBrandName}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Show Company's public name when posting jobs
                      </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >
                        <Switch checked={candidateData?.showBrandName} />
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">No. of Full Time Employees</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{fullTimEmp(candidateData?.numberOfFullTimeEmployees)}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Office/Work Location Presence </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{officePresence(candidateData?.officeLocationPresence)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Address </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.headQuartersAddress}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Pincode </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.pincode}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">State </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.state}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">City </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{candidateData?.state}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Brand Logo </Typography>
                      <img src={candidateData?.brandLogo} width={250} style={{ borderRadius: "10px" }} alt="" />
                    </Box>
                  </Grid>

                </Grid>
              </Box>

            </Box>
          </Box>
        </Container>

      </Box >

  );
};

export default ViewClientDetails;
