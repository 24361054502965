import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from "@mui/material";

const token = localStorage.getItem('token');
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json'
};
const List = () => {
  const [allJob, setAllJob] = useState([]);
  const getAllJob = async () => {
    let data = await axios.get('https://13.235.183.204:3001/api/admin/getalljobs')
    // let data = await axios.get('http:s//13.235.183.204:3001/api/admin/getalljobs')
    setAllJob(data.data.data)
  }

  useEffect(() => {
    getAllJob()
  }, []);


  const approveJob = async (e) => {
    await axios.post(`http:s//13.235.183.204:3001/api/admin/approvejobs`, { jobapprovalid: e, status: "Approved" }, { headers })
      // let data = await axios.get('http:s//13.235.183.204:3001/api/admin/getalljobs')
      .then((response) => {
        toast.success(response.data.data, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        getAllJob()
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };
  const rejectJob = async (e) => {
    await axios.post(`http:s//13.235.183.204:3001/api/admin/approvejobs`, { jobapprovalid: e, status: "Reject" }, { headers })
      // let data = await axios.get('http:s//13.235.183.204:3001/api/admin/getalljobs')
      .then((response) => {
        toast.success(response.data.data, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        getAllJob()
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  return (
    <>
      <div className="allcomponent">
        <TableContainer component={Paper} className="table">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">Job Id</TableCell>
                <TableCell className="tableCell">Job Type</TableCell>
                <TableCell className="tableCell">Hr Name</TableCell>
                <TableCell className="tableCell">Job Post Date</TableCell>
                <TableCell className="tableCell" >Status</TableCell>
                <TableCell className="tableCell" >Approve / Reject</TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {allJob.map((row) => (
                <TableRow key={row._id}>
                  <Link to={`/jobapprove/${row._id}`} id={row._id} >
                    <TableCell className="tableContent">{row._id}</TableCell>
                  </Link>
                  <TableCell className="tableContent">
                    <div className="cellWrapper">
                      {/* <img src={row.img} alt="" className="image" /> */}
                      {row.job_title}
                    </div>
                  </TableCell>
                  <TableCell className="tableContent">{row.company_name}</TableCell>
                  <TableCell className="tableContent">{row.jobposttime}</TableCell>

                  <TableCell className="tableContent">
                    <span className={`status ${row.approve_jobs}`}>{row.approve_jobs}</span>
                  </TableCell>
                  <Button variant="contained" disabled={row.approve_jobs === "Approved"} color="primary" onClick={() => { approveJob(row._id) }}>
                    Approved
                  </Button>
                  <Button variant="contained" disabled={row.approve_jobs === "Approved"} color="secondary" onClick={() => { rejectJob(row._id) }}>
                    Reject
                  </Button>


                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ToastContainer />
        </TableContainer>
      </div>
    </>
  );
};

export default List;
