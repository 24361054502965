import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Box, Button, DialogActions, Divider, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'




const ModalComponent = ({
  openModal = false,
  setOpenModal = () => { },
  modalTitle = "Modal Title",
  children,
  submitBtnText = "Submit",
  cancelBtnText = "Cancel",
  onSubmitBtnClick = () => { },
  onCancelBtnClick = () => { },
  handleOnCloseBtnClick = () => { },
  maxWidth = "sm",
  submitBtnType = "submit",
  modalWidth = "50%"
}) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    background: "#fff",
    boxShadow: 24,
    borderRadius: 5,
    outline: 'none',
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        // setOpenModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box style={{
        ...style,
      }}>
        <Box sx={{ background: "#fff", padding: 1.5, borderTopLeftRadius: 5, borderTopRightRadius: 5 }} display="flex" justifyContent="space-between" alignItems="center" >
          <Typography fontSize={20} >
            {modalTitle}
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer", paddingRight: 2, width: "35px" }}
            onClick={handleOnCloseBtnClick}
          />
        </Box>



        <Box sx={{ background: "#fff", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTop: "1px solid #bdbdbd", paddingTop: 1, maxHeight: "600px", overflow: "scroll" }}>
          {children}
        </Box>

        {/* <Button
                    disableElevation
                    onClick={onSubmitBtnClick}
                    color="error"
                    variant="contained"
                >
                    {cancelBtnText}
                </Button>
                <Button type={submitBtnType} disableElevation onClick={onCancelBtnClick} variant="contained">{submitBtnText}
                </Button> */}
      </Box>
    </Modal>
  )
}

export default ModalComponent