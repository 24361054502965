
import "./candidates.scss";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect, useMemo } from "react";
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const Testing = () => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'S.No.',
        size: 100,
        enableColumnActions: false,
        Cell: ({ row, table }) => {
          const pageIndex = table.getState().pagination.pageIndex;
          const pageSize = table.getState().pagination.pageSize;
          return <span>{row.index + 1 + (pageIndex * pageSize)}</span>;
        },
      },
      {
        accessorKey: 'buid',
        header: 'BUID',
        size: 200,
      },
      {
        accessorKey: 'full_name',
        header: 'Full Name',
        size: 220,
        accessorFn: (row) => {
          const { first_name = "", middle_name = "", last_name = "" } = row;
          return `${first_name} ${middle_name} ${last_name}`.trim();
        },
      },
      {
        accessorKey: 'district',
        header: 'District / City',
        size: 200,
      },
      {
        accessorKey: 'state',
        header: 'State',
        size: 200,
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const filterParams = {
          global: globalFilter,
          ...Object.fromEntries(
            columnFilters.map(filter => [filter.id, filter.value])
          )
        };

        const filterQueryString = new URLSearchParams(filterParams).toString();

        const response = await getRequestWithToken(
          `admin?usertype=job_seeker&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&${filterQueryString}`
        );
        setData(response?.data?.data);
        setRowCount(response?.data?.totalcount);
        setIsError(false);
      } catch (error) {
        setIsError(true);
        handleSnackBar(
          true,
          error?.response?.data?.message || error?.message,
          "error"
        );
      }
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'No Data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return isIncludesValue ? (
    <Box className="candidates-section">
      <Box className="candidates-section-data">
        {isLoading ? (
          <ReusableLoader />
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>
    </Box>
  ) : (
    <Unauthorized />
  );
};

export default Testing;