import "./home.scss";
import Widget from "../../components/widget/Widget";
import React, { useState, useEffect, useRef } from "react";
import { Box, Grid } from "@mui/material";
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import JobSeekerStats from "./JobSeekerStats";
import EmployerStats from "./EmployerStats";
import PostedJobStats from "./PostedJobStats";

const Home = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);

  const fetchDashboardData = async () => {
    try {
      let response = await getRequestWithToken(`admin/dashboard-stats`);
      setDashboardData(response.data);
      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowLoader(false);
    }
  };

  const getSDKChart = () => {
    // let auth_token = localStorage.getItem("token");
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-jobsyahan-staging-amvyena',
      // authentication: {
      //   type: 'jwt',
      //   token: auth_token,
      // },
    });
    const dashboard = sdk.createDashboard({
      dashboardId: '673ff36e-58be-4b42-8f4b-0e95db2c67f4',
      theme: 'light',
      autoRefresh: true,
      maxDataAge: 3600,
      showTitleAndDesc: false,
      scalingWidth: 'fixed',
      scalingHeight: 'fixed'
    });
    dashboard.render(document.getElementById('dashboard'));
  }

  const environment = import.meta.env.VITE_ENV;


  useEffect(() => {
    fetchDashboardData();
  }, [setDashboardData]);

  useEffect(() => {
    if (!showLoader && (environment === "staging" || environment === "development")) {
      getSDKChart();
    }
  }, [showLoader]);


  return (
    <>
      {showLoader ? (
        <ReusableLoader />
      ) : (
        <Box className="home_section" style={{ minHeight: "93vh", maxWidth: "100%" }}>

          {environment === "production" && (<><div className="widgets">

            <Grid container spacing={2} className="cstmFormGridEmployer">
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="candidate"
                  amount={dashboardData.data.candidatesCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="employer"
                  amount={dashboardData.data.employersCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="sahayak"
                  amount={dashboardData.data.sahayaksCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="partner"
                  amount={dashboardData.data.partnersCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="activeJob"
                  amount={dashboardData.data.activeJobPostsCount.count}
                />
              </Grid>
            </Grid>
          </div>

            <div className="statistics_section" style={{ display: "flex", flexDirection: "column", gap: "10px", boxSizing: "border-box", overflow: "hidden" }}>
              <JobSeekerStats />
              <EmployerStats />
              <PostedJobStats />
            </div></>)}

          {(environment === "staging" || environment === "development") && <div id="dashboard" style={{ width: "100%", height: "93vh" }}></div>}

        </Box>
      )}
    </>
  );
};

export default Home;
