import {
  Box, Button, Snackbar, Alert, Container, Grid, FormControl,
  RadioGroup, Radio, MenuItem, Select, InputLabel, Autocomplete, TextField
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { CreatePartnerValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { useContext, useEffect, useRef, useState } from 'react';
import "./partner.scss";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import SureYouWantToDeletModal from '../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal';
import FocusError from '../../components/FocusError/FocusError';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { CoPresentOutlined } from '@mui/icons-material';


const CreatePartner = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [message, setMessage] = useState('');
  const [editForm, setEditForm] = useState(false);
  const [dataById, setDataById] = useState([])
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [isExStudentsDisabel, setIsExStudentsDisabel] = useState(false);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);
  const [jobCertificates, setJobCertificates] = useState([]);
  const months = [
    "january", "february", "march", "april",
    "may", "june", "july", "august",
    "september", "october", "november", "december"
  ];

  const students_count_per_year = [
    "<100", "100-200", "201-300", "301-400",
    "401-500", "501-1000", ">1000"
  ];


  const ex_students_counts = [
    "<100", "100-200", "201-300", "301-400",
    "401-500", ">500", "NA"
  ];

  const { handleSnackBar } = useContext(GlobalContext);

  const [initialValues, setInitialValues] = useState({
    name_of_institute: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    website_of_institute: "",
    contact_person_name: "",
    contact_number: "",
    contact_email_address: "",
    alternate_contact_number: "",
    alternate_email_address: "",
    institute_type: " ",
    trade: [],
    count_of_students_per_year: "",
    month_for_jobs: "",
    ex_students_help: "",
    ex_students_count: "",
    contact_person_first_name: "",
    contact_person_middle_name: "",
    contact_person_last_name: "",
  });

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true);
    let formData = new FormData();
    formData = editForm ? {
      usertype: "partner",
      name_of_institute: values.name_of_institute,
      address: values.address,
      pincode: values.pincode,
      city: values.city,
      state: values.state,
      website_of_institute: values.website_of_institute,
      alternate_contact_number: values.alternate_contact_number,
      alternate_email_id: values.alternate_email_address,
      institute_type: values.institute_type,
      courses: values.trade.map(item => item.label).join(' ,'),
      number_of_students: values.count_of_students_per_year,
      month_for_jobs: values.month_for_jobs,
      help_ex_students: values.ex_students_help,
      number_of_ex_students: values.ex_students_count,
      contact_person_first_name: values.contact_person_first_name,
      contact_person_middle_name: values.contact_person_middle_name,
      contact_person_last_name: values.contact_person_last_name,
    } : {
      usertype: "partner",
      name_of_institute: values.name_of_institute,
      address: values.address,
      pincode: values.pincode,
      city: values.city,
      state: values.state,
      website_of_institute: values.website_of_institute,
      contact_number: values.contact_number,
      contact_email_id: values.contact_email_address,
      alternate_contact_number: values.alternate_contact_number,
      alternate_email_id: values.alternate_email_address,
      institute_type: values.institute_type,
      courses: values.trade.map(item => item.label).join(' ,'),
      number_of_students: values.count_of_students_per_year,
      month_for_jobs: values.month_for_jobs,
      help_ex_students: values.ex_students_help,
      number_of_ex_students: values.ex_students_count === "NA" ? "na" : values.ex_students_count,
      contact_person_first_name: values.contact_person_first_name,
      contact_person_middle_name: values.contact_person_middle_name,
      contact_person_last_name: values.contact_person_last_name,
    }
    try {
      let res = editForm ?
        await patchRequestWithToken('admin', { userid: location.state._id, ...formData }) : await postRequest('admin', formData);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!editForm) {
          resetForm();
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/partner');
          }, 1000);
        }
      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {

      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }
  }


  const getPartnerById = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid/?usertype=partner&userid=${location.state?._id}`);
      setDataById(response.data.data)
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Partner";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Partner"))
  }

  const getStateAndCityByPincode = async (pincode, setFieldValue) => {
    try {
      let response = await getRequest(`/pincode/${pincode}`);
      response = response.data;
      if (response.status === "Success") {
        setFieldValue("state", response?.data?.statename);
        setFieldValue("city", response?.data?.Taluk);
      }
    } catch (err) {
      console.log(err)
    }
  }
  const getJobCertificates = async () => {
    try {
      let jobCertificate = await getRequestWithToken('/jobs/all-certificate');
      jobCertificate = jobCertificate?.data;
      if (jobCertificate.status === "Success") {
        let convertedData = jobCertificate?.data.map(item => ({
          label: item.full_form,
          id: item._id
        }));
        convertedData = convertedData.filter(item => item.label !== "no certificates required" && item.label !== "Any certification")
        setJobCertificates(convertedData);
      }
      else
        throw new Error(`Something went wrong`);
    } catch (err) {
      console.log(err);
      setJobCertificates([]);
    }

  }

  useEffect(() => {

    checkPermissionFunc();
    againCheckPermissions();
    getJobCertificates();

    if (location.state?._id) {
      getPartnerById()
    }
    else {
      setShowLoader(false);
    }


    if (location.state?._id) {

      const { name_of_institute,
        institute_type, address, city, state, website_of_institute, contact_number, contact_email_id, alternate_contact_number,
        alternate_email_id, month_for_jobs, number_of_students, number_of_ex_students, help_ex_students, contact_person_first_name = "",
        contact_person_middle_name = "", contact_person_last_name = "", pincode = "" } = dataById;
      setInitialValues({
        name_of_institute: name_of_institute,
        address,
        pincode,
        city,
        state,
        website_of_institute,
        contact_number,
        contact_email_address: contact_email_id,
        alternate_contact_number: alternate_contact_number,
        alternate_email_address: alternate_email_id,
        institute_type: institute_type,
        trade: [],
        count_of_students_per_year: number_of_students,
        month_for_jobs: month_for_jobs,
        ex_students_help: help_ex_students,
        ex_students_count: number_of_ex_students === "na" ? "NA" : number_of_ex_students,
        contact_person_first_name,
        contact_person_middle_name,
        contact_person_last_name,

      })
      setEditForm(true);
    } else {
      setInitialValues(
        {
          name_of_institute: "",
          address: "",
          pincode: "",
          city: "",
          state: "",
          website_of_institute: "",
          contact_number: "",
          contact_email_address: "",
          alternate_contact_number: "",
          alternate_email_address: "",
          institute_type: " ",
          trade: [],
          count_of_students_per_year: "",
          month_for_jobs: [],
          ex_students_help: "",
          ex_students_count: "",
          contact_person_first_name: "",
          contact_person_middle_name: "",
          contact_person_last_name: "",
        }
      );
      setEditForm(false);
    }
  }, [location.state, dataById._id, isIncludesValue])

  useEffect(() => {
    if (location.state?._id) {
      const { courses } = dataById;
      let trade = courses?.split(' ,');
      if (!trade) return;
      let tradeValue = jobCertificates.filter(item => trade.includes(item.label));
      setInitialValues({
        ...initialValues,
        trade: tradeValue,
      })
    }
  }, [jobCertificates])
  return (
    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-partner-section" ref={scrollTargetRef}>

        <Container>
          <Box className="create-partner">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' >{editForm ? "Update Partner" : "Create Partner"}</h1>
            </Box>
            <Box className="create-partner-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CreatePartnerValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (
                  <Form className="create-partner-form">

                    <Box className="backgroundStyle">
                      <p className='formSubSectionHeading'>Institute Details</p>
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="name_of_institute"> Name of Institute <span className='must-fill-fields'>*</span></label>
                            <Field id="name_of_institute"
                              type="text" placeholder="Enter name of institute"
                              name="name_of_institute"
                              className="form-input" />
                            {errors.name_of_institute && <Box className="form-error">{errors.name_of_institute}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="address">Address <span className='must-fill-fields'>*</span></label>
                            <Field id="address"
                              type="text" placeholder="Enter address"
                              name="address"
                              className="form-input" />
                            {errors.address && <Box className="form-error">{errors.address}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="pincode">Pincode <span className='must-fill-fields'>*</span></label>
                            <Field id="pincode"
                              type="text" placeholder="Enter pincode"
                              name="pincode"
                              className="form-input"
                              onChange={(event) => {
                                setFieldValue("pincode", event.target.value)
                              }}
                              onBlur={() => {
                                getStateAndCityByPincode(values.pincode, setFieldValue);
                              }}
                              value={values.pincode} />
                            {errors.pincode && <Box className="form-error">{errors.pincode}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="city">City <span className='must-fill-fields'>*</span></label>
                            <Field id="city"
                              type="text" placeholder="Enter city"
                              name="city"
                              className="form-input"
                              value={values.city} />
                            {errors.city && <Box className="form-error">{errors.city}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="state">State <span className='must-fill-fields'>*</span></label>
                            <Field id="state"
                              type="text" placeholder="Enter state"
                              name="state"
                              className="form-input"
                              value={values.state} />
                            {errors.state && <Box className="form-error">{errors.state}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="website_of_institute">Website of Institute</label>
                            <Field id="website_of_institute"
                              type="text" placeholder="Enter website of institute"
                              name="website_of_institute"
                              className="form-input" />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="institute_type">Institute Type <span className='must-fill-fields'>*</span></label>
                            <FormControl name="gender" fullWidth size="small">
                              <Select
                                placeholder="Select institute type"
                                name="institute_type"
                                id="institute_type"
                                value={values.institute_type}
                                onChange={(event) => {
                                  const selectedInstituteType = event.target.value;
                                  setFieldValue("institute_type", selectedInstituteType);

                                }}
                              >
                                <MenuItem value=" ">Select institute type</MenuItem>
                                <MenuItem value="Govt. ITI">Govt. ITI</MenuItem>
                                <MenuItem value="Private ITI">Private ITI</MenuItem>
                                <MenuItem value="Kaushal Bharat">Kaushal Bharat</MenuItem>

                              </Select>
                            </FormControl>
                            {errors.institute_type && <Box className="form-error">{errors.institute_type}</Box>}
                          </Box>

                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="trade">Training Trade <span className='must-fill-fields'>*</span></label>

                            <Autocomplete

                              multiple
                              id="tags-outlined"
                              options={jobCertificates || []}
                              getOptionLabel={(option) => option.label}
                              filterSelectedOptions
                              value={values.trade}
                              onChange={(event, newValue) => {
                                setFieldValue('trade', newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select training trade"
                                />
                              )}
                            />
                            {errors.trade && <Box className="form-error">{errors.trade}</Box>}
                          </Box>

                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="backgroundStyle">
                      <p className='formSubSectionHeading'>Contact Person Details</p>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="contact_person_first_name">First Name <span className='must-fill-fields'>*</span></label>
                            <Field id="contact_person_first_name"
                              type="text" placeholder="Enter first name"
                              name="contact_person_first_name"
                              className="form-input" />
                            {errors.contact_person_first_name && <Box className="form-error">{errors.contact_person_first_name}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="contact_person_middle_name">Middle Name </label>
                            <Field id="contact_person_middle_name"
                              type="text" placeholder="Enter middle name"
                              name="contact_person_middle_name"
                              className="form-input" />
                            {errors.contact_person_middle_name && <Box className="form-error">{errors.contact_person_middle_name}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="contact_person_last_name">Last Name <span className='must-fill-fields'>*</span></label>
                            <Field id="contact_person_last_name"
                              type="text" placeholder="Enter last name"
                              name="contact_person_last_name"
                              className="form-input" />
                            {errors.contact_person_last_name && <Box className="form-error">{errors.contact_person_last_name}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="contact_number">Contact Number <span className='must-fill-fields'>*</span></label>
                            <Field disabled={editForm} id="contact_number"
                              type="text" placeholder="Enter contact number"
                              name="contact_number"
                              className="form-input" />
                            {errors.contact_number && <Box className="form-error">{errors.contact_number}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="alternate_contact_number">Alternate Contact Number</label>
                            <Field id="alternate_contact_number"
                              type="text" placeholder="Enter alternate contact number"
                              name="alternate_contact_number"
                              className="form-input"
                              maxLength="10" />
                          </Box>
                          {errors.alternate_contact_number && <Box className="form-error">{errors.alternate_contact_number}</Box>}

                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="contact_email_address">Email Address <span className='must-fill-fields'>*</span></label>
                            <Field disabled={editForm} id="contact_email_address"
                              type="text" placeholder="Enter email address"
                              name="contact_email_address"
                              className="form-input" />
                            {errors.contact_email_address && <Box className="form-error">{errors.contact_email_address}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="alternate_email_address">Alternate Email Address</label>
                            <Field id="alternate_email_address"
                              type="text" placeholder="Enter alternate email address"
                              name="alternate_email_address"
                              className="form-input" />
                            {errors.alternate_email_address && <Box className="form-error">{errors.alternate_email_address}</Box>}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer' style={{ marinTop: "10px" }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="month_for_jobs">Availability Month for Jobs (Select Multiple, if batches end in different months)</label>

                            <div role="group" aria-labelledby="checkbox-group">
                              <Grid container spacing={2}>
                                {months.map((month, index) => (
                                  <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Box className="form-control">
                                      <label key={index} className='form-label months_label' htmlFor="month_for_jobs">
                                        <Field type="checkbox" name="month_for_jobs" value={month} />
                                        {month?.charAt(0).toUpperCase() + month?.slice(1)}
                                      </label>
                                    </Box>
                                  </Grid>

                                ))}
                              </Grid>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer' style={{ marginTop: "10px" }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="count_of_students_per_year">Count of Students Per Year</label>

                            <div role="group" aria-labelledby="my-radio-group">
                              <Grid container spacing={2}>
                                {students_count_per_year.map((studentCount, index) => (
                                  <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Box className="form-control">
                                      <label>
                                        <Field key={index} className='form-label months_label' type="radio" name="count_of_students_per_year"
                                          value={studentCount}
                                        />
                                        {studentCount}

                                      </label>
                                    </Box>
                                  </Grid>

                                ))}
                              </Grid>
                              {errors.count_of_students_per_year && <Box className="form-error">{errors.count_of_students_per_year}</Box>}

                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ marginTop: "10px" }}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="ex_students_help">Would you like to help your Ex-students too?</label>
                            <label>
                              <Field className='form-label months_label' type="radio" name="ex_students_help" value="yes" onChange={(e) => {
                                setFieldValue('ex_students_help', e.target.value);
                                setIsExStudentsDisabel(false);
                              }} />
                              Yes
                            </label>

                            <label>
                              <Field className='form-label months_label' type="radio" name="ex_students_help" value="no"
                                onChange={(e) => {
                                  setFieldValue('ex_students_help', e.target.value);
                                  setFieldValue('ex_students_count', "NA");
                                  setIsExStudentsDisabel(true);
                                }} />
                              No
                            </label>

                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="ex_students_count">What is the count of such Ex-students?</label>

                            <div role="group" aria-labelledby="my-radio-group">
                              <Grid container spacing={2}>
                                {ex_students_counts.map((exStudentCount, index) => (
                                  <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Box className="form-control">
                                      <label>
                                        <Field key={index} className='form-label months_label' type="radio" name="ex_students_count" value={exStudentCount}
                                          disabled={isExStudentsDisabel}
                                        />
                                        {exStudentCount}
                                      </label>
                                    </Box>
                                  </Grid>

                                ))}
                              </Grid>
                              {errors.ex_students_count && <Box className="form-error">{errors.ex_students_count}</Box>}

                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="form-button-section">

                      <Link to='/partner'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}
                      >{editForm ? "Update" : "Create"}</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>

      </Box >}
    </> : (<Unauthorized />)
  )

}

export default CreatePartner; 